import {useState, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {Popover, Modal} from 'antd';
import axios from 'axios';

import {BASE_URL} from '../../../../../api/base-url';
import AuthContext from '../../../../../context/auth-provider';

function LessonPopover({children, selectedCourseLessonId}) {
    const {state} = useLocation();
    const token = localStorage.getItem('token')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const [isResultModalOpen, setIsResultModalOpen] = useState(false);
    const [resultModalMessage, setResultModalMessage] = useState('');

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const showModal = () => {
        setIsModalOpen(true);
        setOpen(false);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        completeLesson();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const closeResultModal = () => {
        setIsResultModalOpen(false);
    };

    const completeLesson = async () => {
        const {data} = await axios.post(
            `${BASE_URL}/api/v1/client-course-lesson/support/finish`,
            {
                clientId: state?.clientId,
                courseLessonId: selectedCourseLessonId,
            },
            {headers: {Authorization: `Bearer ${token}`}}
        );
        setResultModalMessage(data.data)
        setIsResultModalOpen(true);
        // showModal();
    };
    return (
        <>
            <Popover
                open={open}
                onOpenChange={handleOpenChange}
                placement='right'
                content={
                    <p className='item__popover-content pointer_on_hover' onClick={showModal}>
                        Darsni tugatish
                    </p>
                }
                trigger='click'
            >
                {children}
            </Popover>
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Mark the lesson finished for this student?</p>
            </Modal>
            <Modal open={isResultModalOpen} onOk={closeResultModal} onCancel={closeResultModal}>
                <p> {resultModalMessage} </p>
            </Modal>
        </>
    );
}

export default LessonPopover;
