import React from "react";
import "./searchBar.scss";

const SearchBar = ({ handleFilter, setInputValue, value }) => {
  return (
    <div className="d-flex">
      <div className="search-input">
        <i
          style={{ color: "#989898" }}
          className="fa-solid fa-magnifying-glass fa-1x"></i>
        <input
          placeholder="search..."
          type="search"
          onChange={(e) =>
            setInputValue({ ...value, searchInputValue: e.target.value })
          }
        />
      </div>
      <button onClick={handleFilter} className="search-btn">
        Filter
      </button>
    </div>
  );
};

export default SearchBar;
