import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../api/base-url';
import {
  Header,
  Footer,
  SearchBar,
  Filter,
  CatalogItem,
} from '../../components';
import './catalog.scss';

const Catalog = () => {
  const [checkState, setCheckState] = useState({ ids: [], rating: 0 });
  const [showFilter, setShowFilter] = useState(true);
  const [data, setData] = useState([]);
  const [value, setValue] = useState({
    searchInputValue: '',
    checkboxTopicValue: [],
  });

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const getCourses = async () => {
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/course/public/filter`,
      {
        name: value.searchInputValue,
        rating: checkState.rating,
        topicList: checkState.ids,
      }
    );
    setData(data.data.content);
  };

  useEffect(() => {
    getCourses();
  }, [value, checkState]);

  return (
    <div className='catalog-wrapper'>
      <Header />
      <div className='input-wrap'>
        <SearchBar
          setShowFilter={setShowFilter}
          setInputValue={setValue}
          handleFilter={handleFilter}
          value={value}
        />
      </div>
      <div className='catalog-items-wrapper'>
        <div className='filter'>
          {showFilter && (
            <Filter setCheckState={setCheckState} checkState={checkState} />
          )}
        </div>
        <div className='all-items-wrapper'>
          {data.length === 0 ? <p style={{ color: 'white' }}>No data</p> : null}
          {data &&
            data.map((item) => (
              <CatalogItem itemCourse={item} key={item?.id} />
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Catalog;
