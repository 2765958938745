import React, { useContext, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../../context/auth-provider';
import { BASE_URL } from '../../api/base-url';
import { Header } from '../../components';
import { getUserDetails } from '../../utils/getUserDetail';

const UpdatePhoneVerification = ({ setIsSubmit}) => {
  const [value, setValue] = useState(null);
  const { loginPhone, setLogin,  setToken, setLoginPhone } = useContext(AuthContext);
  const token = localStorage.getItem('token')
  const navigate = useNavigate();
  const [error, setError] = useState('');
  
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${BASE_URL}/api/v1/client/phone/verification`,
        {
          code: value,
          phone: loginPhone,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      if (res.data.isError) {
          throw  new Error(res.data.message)
      }
        // if (!item?.data.isError) {
        //   navigate('/');
        //   //window.location.reload();
        //   const fullName = {
        //     name: item?.data?.data?.name,
        //     surname: item?.data?.data?.surname,
        //     photo: item?.data?.data?.photo
        //   };
        //   localStorage.setItem('fullName', JSON.stringify(fullName));

        //   localStorage.setItem('loginUser', JSON.stringify(item));
        // //  setLogin(JSON.stringify(item))


        //   localStorage.setItem('token', item?.data?.data?.jwt);
        //   setToken(item?.data?.data?.jwt)
          
        //   localStorage.setItem('roleList', item?.data?.data?.roleList);
      
        // } else {
        //   setError(item.data.message);
        //   localStorage.removeItem('loginPhoneNumber');
        //   setLoginPhone(null)
        //   localStorage.removeItem('token');
        //   localStorage.removeItem('roleList');
        // }
    } catch (error) {
      setError(error.message)
    }
  
  };

  return (
      <div className='update-phone__verif'>
        <div className='details-wrap text-center'>
          <h3>Sms kodni Tasdiqlash</h3>
          <p className='mb-3'>{loginPhone} raqamiga sms kod yuborildi</p>
          <form onSubmit={onSubmit} className='verification-input'>
            <ReactInputVerificationCode
              autoFocus
              length={5}
              placeholder=''
              onChange={(e) => setValue(e)}
          />
          <div className='mt-5 mb-4 d-flex gap-3 align-items-center ms-auto'>
            <button type='submit' className='button'>
              Tasdiqlash
            </button>
            <button type='button' className="btn btn-light btn-reset" onClick={()=> setIsSubmit(false)}>Orqaga</button>
          </div>
          
            <p className='text-danger mt-3 fs-5'>{error}</p>
          </form>
        </div>
      </div>

  );
};

export default UpdatePhoneVerification;
