import './UserProfile.scss'
import { Tabs } from 'antd';
import { PublicPageDashboard } from '../dashboard/pages/mentor-pages';
import UpdatePhone from './UpdatePhone';
import { Header } from '../../components';
const profileTabs = [
  {
    key: '1',
    label: 'Batafsil',
    children: <PublicPageDashboard />,
  },
  {
    key: '2',
    label: 'Raqam',
    children: <UpdatePhone />,
  },
]

function UserProfile() {
  return (
    <div className='user-profile'>
      <Header />
      <main className="user-profile__main container-xxl">
        <h5 className='mb-5 pt-4 pt-xl-0'>User Profile</h5>
        <div>
          <Tabs defaultActiveKey="1" items={profileTabs} value="large" type="card" />
        </div>
      </main>
    </div>
    
  )
}

export default UserProfile