import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../api/base-url';
import { Header } from '../../components';
import AuthContext from '../../context/auth-provider';
import './payment.scss';
import Summary from './summary/summary';
import { Button } from 'antd';

const Payment = () => {
    const  token  = localStorage.getItem('token')
  const [courseData, setCourseData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location?.state;
  const purchaseCourse = async () => {
    navigate('/course-detail', { state: { checkId: id, id } });
  };
  const getCourseData = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/course/public/${id}/simple`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setCourseData(data.data);
  };
  useEffect(() => {
    getCourseData();
  }, []);
  return (
    <>
      <Header />
      <div className='payment-wrapper'>
        <div className='payment-items'>
          <div className='course-info'>
            <ul className='p-0 fs-5'>
              <li>Kurs o'quvchilar soni: </li>
              <li>Rating: </li>
              <li>Kurs Narxi: </li>
            </ul>
          </div>
          <div className='payment-info'>
            <p className='h5'>{`Kursni sotib olish uchun biz bilan bog‘laning.`}</p>
            <p className='h6'>
              <a href='tel:+998905721213'>Tel: +998-(91)-572-12-13</a>
            </p>
            <p className='h6'>
              <a href='https://t.me/Jumaniyazov_Bobur'>
                Telegram: @Jumaniyazov_Bobur
              </a>
            </p>
            <br />
            <br />
            <h5>
              Hozirda to‘lov sistemalari bilan integratsiya jarayonlari hali
              tugamadi,
              <br />
              bu jarayonlar tugaganidan keyin kurslarni platformadan to‘g‘ridan
              to‘g‘ri sotib olish imkoni bo‘ladi. Hozircha biz bilan aloqaga chiqish orqali kursni sotib olishingiz mumkun.
            </h5>
          </div>
          <br />
          <Button type='primary' onClick={purchaseCourse}>
            Kursga qaytish
          </Button>
        </div>

        <Summary data={courseData} />
      </div>
    </>
  );
};

export default Payment;
