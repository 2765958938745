import {useState} from "react";
import {Navigate, useLocation, Outlet} from "react-router-dom";
import {Navbar, Sidebar} from "../dashboard/components";
import AuthContext from "../../context/auth-provider";
import {useContext} from "react";

const AdminRequiredAuth = () => {
    const [openSidebar, setOpenSidebar] = useState(true);
    // const {userData} = useContext(AuthContext);
    const location = useLocation();
    // const roleList = userData?.roleList;

    const roleListJson = localStorage.getItem('roleList');
    const roles = roleListJson !== null ? JSON.parse( roleListJson) : [];

    return (
        <div style={{backgroundColor: "#F7F8FC"}} className="d-flex">
            <Sidebar openSidebar={openSidebar}/>
            <div className="d-flex flex-column w-100 p-1 gap-1 z-2">
                <Navbar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}/>
                {roles?.includes("ROLE_ADMIN") ? (
                    <Outlet/>
                ) : (
                    <Navigate to="/admin-login" state={{from: location}} replace/>
                )}
            </div>
        </div>
    );
};

export default AdminRequiredAuth;
