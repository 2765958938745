import React, {useEffect, useState} from 'react';
import {Input, Select} from 'antd';
import './AdminClientCourse.scss';
import axios from 'axios';
import {BASE_URL} from '../../../../../api/base-url';
import {getDateFormat} from "../../../../../utils/Utils";
import Pagination from "../../../../../utils/Pagination";
import {useNavigate} from "react-router-dom";

const AdminClientCourse = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    const [clientList, setClientList] = useState([]);
    const [inputValues, setInputValues] = useState({
        courseName: null,
        courseId: null,
        phone: null,
        purchaseType: null,
        status: null,
        progressStatus: null,
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [totalElements, setTotalElements] = useState(0);

    // GET CLIENTS
    const getClientCourse = async () => {
        if (inputValues.status === 'ALL') {
            inputValues.status = null;
        }
        if (inputValues.purchaseType === 'ALL') {
            inputValues.purchaseType = null;
        }
        if (inputValues.purchaseType === 'ALL') {
            inputValues.purchaseType = null;
        }

        const {data} = await axios.post(
            `${BASE_URL}/api/v1/client-curse/admin/filter?page=${currentPage}&size=${itemsPerPage}`,
            inputValues,
            {
                headers: {Authorization: `Bearer ${token}`}
            }
        );
        setClientList(data?.data?.content);

        const totalElements = data?.data?.totalElements;
        setTotalElements(totalElements);
    };

    useEffect(() => {
        getClientCourse();
    }, [currentPage]);

    useEffect(() => {
        getClientCourse();
    }, []);

    useEffect(() => {
        getClientCourse();
    }, [inputValues]);


    const clearInputs = () => {
        setInputValues({
            courseName: null,
            courseId: null,
            phone: null,
            purchaseType: null,
            status: null,
            progressStatus: null,
        });
        getClientCourse();
    }

    return (
        <div className='admin-client-wrapper'>
            <div className='admin-search-panel'>
                <div className='inputs d-flex align-items-center gap-2 pb-1'>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='query'>
                            Course
                        </label>
                        <Input id='query' style={{width: 'max-content'}}
                               onChange={(e) =>
                                   setInputValues({...inputValues, courseName: e.target.value})
                               }/>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='phone'>
                            ClientId
                        </label>
                        <Input id='clientId' style={{width: 'max-content'}}
                               onChange={(e) =>
                                   setInputValues({...inputValues, clientId: e.target.value})
                               }/>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='phone'>
                            Phone
                        </label>
                        <Input id='phone' style={{width: 'max-content'}}
                               onChange={(e) =>
                                   setInputValues({...inputValues, phone: e.target.value})
                               }/>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='status'>
                            Status
                        </label>
                        <Select
                            id='status'
                            defaultValue='ALL'
                            style={{
                                width: 100,
                            }}
                            options={[
                                {
                                    value: 'ALL',
                                    label: 'ALL',
                                },
                                {
                                    value: 'CREATED',
                                    label: 'CREATED',
                                },
                                {
                                    value: 'JOINED',
                                    label: 'JOINED',
                                }
                            ]}
                            onChange={(e) => {
                                setInputValues({...inputValues, status: e})
                            }
                            }
                        />
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='status'>
                            Purchase
                        </label>
                        <Select
                            id='status'
                            defaultValue='ALL'
                            style={{
                                width: 100,
                            }}
                            options={[
                                {
                                    value: 'ALL',
                                    label: 'ALL',
                                },
                                {
                                    value: 'FREE',
                                    label: 'FREE',
                                },
                                {
                                    value: 'BOUGHT',
                                    label: 'BOUGHT',
                                },
                                {
                                    value: 'BOUGHT_IN_DISCOUNT',
                                    label: 'BOUGHT_IN_DISCOUNT',
                                }
                            ]}
                            onChange={(e) => {
                                setInputValues({...inputValues, purchaseType: e})
                            }
                            }
                        />
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='status'>
                            Progress Status
                        </label>
                        <Select
                            id='status'
                            defaultValue='ALL'
                            style={{
                                width: 100,
                            }}
                            options={[
                                {
                                    value: 'ALL',
                                    label: 'ALL',
                                },
                                {
                                    value: 'DEF',
                                    label: 'DEF',
                                },
                                {
                                    value: 'STARTED',
                                    label: 'STARTED',
                                },
                                {
                                    value: 'END',
                                    label: 'END',
                                },
                                {
                                    value: 'FINISHED',
                                    label: 'FINISHED',
                                }
                            ]}
                            onChange={(e) => {
                                setInputValues({...inputValues, progressStatus: e})
                            }
                            }
                        />
                    </div>
                    <button className='btn btn-primary btn-sm' style={{marginTop: 30}}
                            onClick={() => clearInputs()}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <button className='btn btn-primary btn-sm' style={{marginTop: 30}}
                            onClick={() => {
                                // setCurrentPage(1);
                                getClientCourse();
                            }}>
                        <i className='fa-solid fa-magnifying-glass'></i>
                    </button>
                </div>
                <div><b>Total</b>: {totalElements}</div>
            </div>

            <div className='clients'>
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr className='font-size-13'>
                        <th scope="col">#</th>
                        <th scope="col">Course</th>
                        <th scope="col">Client</th>
                        <th scope="col">CCStatus</th>
                        <th scope="col">Purchase</th>
                        <th scope="col">Progress</th>
                        <th scope="col">CreatedDate</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clientList.map((item, index) => {
                        return (<tr key={item.id}>
                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                            <td>{item.course.name}</td>
                            <td>{item.client.name} - {item.client.surname}
                                {item.client.phoneNumber}
                            </td>
                            <td>{item.status}</td>
                            <td>{item.purchaseType}</td>
                            <td>{item.progressStatus}</td>
                            <td>{getDateFormat(item.createdDate)}</td>
                            <td>
                                <button className='btn btn-primary btn-sm'
                                        onClick={() => {
                                            navigate(`/admin/client-course-detail/${item.client.id}/${item.course.id}`);
                                        }}>
                                    <i className='fa-solid fa-info'></i>
                                </button>
                            </td>
                        </tr>)
                    })}
                    </tbody>
                </table>
                <Pagination
                    totalItems={totalElements}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    );
};

export default AdminClientCourse;
