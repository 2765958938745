import React from "react";
import { Courses } from "../../pages";

const Layout = () => {
  return (
    <main>
      <Courses />
    </main>
  );
};

export default Layout;
