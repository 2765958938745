import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../api/base-url';
import AuthContext from '../../context/auth-provider';
import { toast } from 'react-toastify';

const CourseLessonMaterials = ({
  courseLessonId,
  courseId,
  currentLessonFinishedTrigger,
  setCourseFinished,
  clientCourse,
  allLessonsFinished, content
}) => {
  const  token  = localStorage.getItem('token')
  const notify = (message) => toast(message);

  const finishCourseLesson = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/client-course-lesson/finish/${courseLessonId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (!data.isError) {
      notify( data.data? data.data:'Lesson Finished. Malades.');
      setTimeout(() => {
        currentLessonFinishedTrigger(courseLessonId); // currentLessonFinished trigger
      }, 1000);
    }
  };

  const finishCourse = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/client-curse/finish/${courseId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!data.isError) {
      notify('Kurs tugatildi. Malades.');
      setTimeout(() => {
        setCourseFinished(courseId); // course finishedTrigger trigger
      }, 1000);
    }
  };

  useEffect(() => {}, [courseLessonId]);

  function getButton() {
    if (clientCourse?.progressStatus === 'FINISHED') {
      return <div>
        <h3>Kusr tugatilgan.</h3>
        <br/>
        <br/>
        <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: content}}></div>
      </div>;
    } else if (
      clientCourse?.progressStatus === 'STARTED' &&
      allLessonsFinished === true
    ) {
      return (
          <div>
            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: content}}></div>
            <div className='next-button'>
              <button onClick={finishCourse} style={{ color: '#fff' }}>
                Kursni tugatish.
                <i className='fa-solid fa-chevron-right'></i>
              </button>
            </div>
          </div>
      );
    } else {
      return (
          <div>
            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: content}}></div>
            <div className='next-button'>
              <button onClick={finishCourseLesson} style={{ color: '#fff' }}>
                Darsni tugatib keyingi darsga o'tish
                <i className='fa-solid fa-chevron-right'></i>
              </button>
            </div>
          </div>
      );
    }
  }

  return getButton();
};

export default CourseLessonMaterials;
