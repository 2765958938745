import React, { useEffect } from "react";
import "./student-active-course-component.scss";
import { BASE_URL } from "../../api/base-url";

import { useState } from "react";
import { Link } from "react-router-dom";
import { handleUnAuthorized, isTokenExists } from "../../utils/Utils";

function countCourseProgress(totalLessons, finishedLessons) {
  return Math.round((finishedLessons * 100) / totalLessons);
}

function StudentActiveCourseComponent() {
  const [activeCourses, setActiveCourses] = useState([]);
  const token = localStorage.getItem("token");
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(
          `${BASE_URL}/api/v1/client-curse/progress/short`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((res) => {
          if (res.status === 401) {
            handleUnAuthorized();
          }
          return res;
        });
        const data = await res.json();
        if (data.data.length <= 2) {
          setActiveCourses(data.data);
        } else {
          setActiveCourses(data.data.slice(0, 2));
        }
      } catch (err) {
        if (err.message === 401) {
          setIsLoggedIn(false);
        }
      }
    }

    if (isTokenExists()) {
      fetchData();
    }
  }, []);
  return (
    <>
      {activeCourses && activeCourses.length === 0 ? (
        ""
      ) : (
        <div className="col-md-5 col-12 flex-shrink-1">
          <div className="d-flex justify-content-between align-items-center">
            <p className="fs-5">Mening kurslarim</p>
            <p className="fs-6 see-all">
              <Link to="/my-courses">See all</Link>
            </p>
          </div>
          <div className="student-active-courses d-flex flex-column">
            {activeCourses &&
              activeCourses.map((course) => {
                const courseProfress = countCourseProgress(
                  course.courseLessonCount,
                  course.clientCourseFinishedLessonCount
                );
                return (
                  <Link
                    to={`/course-detail/${course.courseId}`}
                    state={{ id: course.courseId }}
                    className="active-course"
                    key={course.courseId}
                  >
                    <div
                      className="active-course__bg"
                      style={{
                        width: `${courseProfress}%`,
                      }}
                    ></div>
                    <p className="active-course__name">{course.courseName}</p>
                    <p className="active-course__progress">
                      {courseProfress}% completed
                    </p>
                  </Link>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export default StudentActiveCourseComponent;
