import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/auth-provider';
import { Input, Spin } from 'antd';
import axios from 'axios';
import { BASE_URL } from '../../api/base-url';
import UpdatePhoneVerification from './UpdatePhoneVerification';

function UpdatePhone() {
  const {setLoginPhone} = useContext(AuthContext);
  const [error, setError] = useState('');
  const [inputVal, setInputVal] = useState('+998');
  const [isSubmit, setIsSubmit] = useState(false);
  const token = localStorage.getItem('token')
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    setIsSubmit(false)
    e.preventDefault();
    try {
      const res =  await axios.post(
        `${BASE_URL}/api/v1/client/phone`,
        { 'phone': inputVal},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      if (res.data.isError) {
        throw  new Error(res.data.message)
      }
      setIsSubmit(true);
      setLoginPhone(inputVal)
      setInputVal('+998');
      console.log(res)
    } catch (error) {
      setError(error)
    }
  };

  return (
    <div className='update-phone'>
      {isSubmit ? (<UpdatePhoneVerification  setIsSubmit={ setIsSubmit} />) : (<>
        <p className='fs-5 mb-3 text-center'>Raqamni o'zgartirish</p>
        <form onSubmit={onSubmit}>
          <div className='mb-3 text-start'>
            <label htmlFor='phoneNumber' className='form-label'>
              Telefon raqam
            </label>
            <Input
              id='phoneNumber'
              type='text'
              value={inputVal}
            onChange={(e) => {
                setError('')
                const val = e.target.value.trim();
                setInputVal(val);
              }}
              style={{
                padding: 13,
                fontSize: 18,
              }}
            />
          </div>
          <button
            disabled={isSubmit}
            type='submit'
            className='button'
          >
            Saqlash
          </button>
          <div className='text-center'>
            <p className='text-danger mt-3 fs-5'>{error.message}</p>
          </div>
        </form>
      </>)}

    </div>
  );
}

export default UpdatePhone;
