import axios from 'axios';
import React from 'react';
import {BASE_URL} from '../../api/base-url';
import {Link} from 'react-router-dom';
import {CourseCard, Footer, Header, Hero} from '../../components';
import {useEffect} from 'react';
import {useState} from 'react';
import {handleUnAuthorized} from '../../utils/Utils';
import axiosInstance from "../../api/intercepter";

const CourseListPage = () => {
    const [data, setData] = useState([]);

    const getHomeCourses = async () => {
        const {data} = await axiosInstance.get(`${BASE_URL}/api/v1/course/public/home`)
            .then(res => {
                if (res.status === 401) {
                    handleUnAuthorized()
                }
                return res;
            });
        setData(data?.data);
    };

    useEffect(() => {
        getHomeCourses();
    }, []);

    return (
        <>
            <Header/>
            <Hero/>
            <div style={{minHeight: '59vh'}} className='courses'>
                {data.map((item, idx) => {
                    return (
                        <section key={idx} className='course-list-section'>
                            <div className='container '>
                                {item?.courseList.length !== 0 && (
                                    <h2 className='section-title text-start h3'>
                                        {item?.topicName}
                                    </h2>
                                )}
                                <div className='course-items'>
                                    {item?.courseList?.map((childItem, idx) => {
                                        return (
                                            <Link
                                                key={idx}
                                                to={`/course-detail/${childItem.id}`}
                                                state={{id: childItem?.id}}
                                            >
                                                <CourseCard {...childItem} />
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    );
                })}
            </div>
            <Footer/>
        </>
    );
};

export default CourseListPage;
