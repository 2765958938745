import React, {useState, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import AuthContext from '../../context/auth-provider';
import {Checkbox, Input, Spin} from 'antd';
import './login.scss';
import axios from 'axios';
import {BASE_URL} from '../../api/base-url';

function ClientLoginComponent() {
    const {setLoginPhone} = useContext(AuthContext);
    const [error, setError] = useState('');
    const [inputVal, setInputVal] = useState('+998');
    const [usingTelegramBotVal, setUsingTelegramBotVal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        const header = {
            headers: {'Content-Type': 'application/json'},
        };
        axios
            .post(
                `${BASE_URL}/api/v1/auth/client/login`,
                {
                    phone: inputVal,
                    userTelegramBot: usingTelegramBotVal
                },
                header
            )
            .then((item) => {
                if (!item?.data.isError) {
                    setIsSubmitting(true);
                    setLoginPhone(inputVal);
                    // console.log(inputVal)
                    localStorage.setItem('loginPhoneNumber', inputVal);
                    navigate('/login-verification');
                    //navigate(0);
                    //window.location.reload();

                    setInputVal('+998');
                } else {
                    setError(item.data.message);
                }
            })
            .catch((err) => {
            });
    };

    return (
        <div className='login-form d-flex gap-2 flex-column'>
            <>
                <p className='login-title mb-0'>Log in / Kirish</p>
                <form onSubmit={onSubmit}>
                    <div className='mb-3 text-start'>
                        <label htmlFor='phoneNumber' className='form-label'>
                            Telefon raqam
                        </label>
                        <Input
                            id='phoneNumber'
                            type='text'
                            value={inputVal}
                            onChange={(e) => {
                                const val = e.target.value.trim();
                                setInputVal(val);
                            }}
                            style={{
                                padding: 13,
                                fontSize: 18,
                            }}
                        />
                    </div>
                    <div className='login-check-container'>
                        <Checkbox
                            value={usingTelegramBotVal}
                            onChange={(e) => {
                                setUsingTelegramBotVal(!usingTelegramBotVal);
                            }}><span className='login-check-container-text'>Telegram bot orqali SMS-ni qabul qilish.</span></Checkbox>
                    </div>
                    <button
                        disabled={isSubmitting}
                        type='submit'
                        className='button login-button'
                    >
                        {isSubmitting ? <Spin/> : 'Kirish'}
                    </button>
                    <div className='login-telegram-link'>
                        <a href="https://t.me/videohubsms_bot" target="_blank">Videohub SMS
                            botga o'tish</a></div>
                    <div className='text-center'>
                        <p className='text-danger mt-3'>{error}</p>
                    </div>
                </form>
                <span className='login-line'></span>
                <Link
                    to='/register'
                    className='registration-text text-center'
                    style={{cursor: 'pointer'}}
                >
                    Ro'yhatdan o'tish
                </Link>
            </>
        </div>
    );
}

export default ClientLoginComponent;
