import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Input } from "antd";
import { BASE_URL } from "../../../../../api/base-url";
import "./MentorSimpleMessageChat.scss";
import Pagination from "../../../../../utils/Pagination";
import { getDateFormat } from "../../../../../utils/Utils";

const MentorSimpleMessageChat = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [messageList, setMessageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(0);

  const getMessages = async () => {
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/simple_message/filter/support?page=${currentPage}&size=${itemsPerPage}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setMessageList(data?.data?.content);
    setTotalElements(data?.data?.totalElements);
  };

  useEffect(() => {
    getMessages();
  }, [currentPage]);

  return (
    <div className="dashboard-chat-wrappe">
      <div className="course-top-wrap d-flex align-items-center justify-content-between mb-3">
        <div className="inputs d-flex align-items-center gap-4">
          <div className="d-flex flex-column">
            <label className="mb-2" htmlFor="course">
              Course
            </label>
            <Input id="course" style={{ width: "max-content" }} />
          </div>
          <div className="d-flex flex-column">
            <label className="mb-2" htmlFor="courseLesson">
              Course Lesson
            </label>
            <Input id="courseLesson" style={{ width: "max-content" }} />
          </div>
          <div className="d-flex flex-column">
            <label className="mb-2" htmlFor="clientName">
              Client Name
            </label>
            <Input id="clientName" style={{ width: "max-content" }} />
          </div>

          <button className="btn btn-primary" style={{ marginTop: 50 }}>
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      <div className="client-assigned-course-wrapper">
        <div className="admin-client-wrapper">
          <div>
            <b>Total</b>: {totalElements}
          </div>
          <div className="clients">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="font-size-13">
                  <th scope="col">#</th>
                  <th scope="col">Course Name</th>
                  <th scope="col">Course Lesson</th>
                  <th scope="col">Profile</th>
                  <th scope="col">Message Count</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {messageList?.map((item, index) => {
                  return (
                    <tr key={item.createdDate}>
                      <td>{(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                      <td>{item.courseName}</td>
                      <td>
                        {item.courseSectionName} -> {item.courseLessonName}
                      </td>
                      <td>
                        <span>{item.clientName}</span> {"  "}
                        <span>{item.clientSurname}</span>
                      </td>
                      <td>{item.messageCount}</td>
                      <td>{getDateFormat(item.createdDate)}</td>
                      <td>
                        <i
                          onClick={() =>
                            navigate("/mentor/simple-chat", {
                              state: {
                                courseId: item.courseId,
                                lessonId: item.courseLessonId,
                                clientId: item.clientId,
                              },
                            })
                          }
                          className="fa-solid fa-eye pointer_on_hover"
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              totalItems={totalElements}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorSimpleMessageChat;
