import React, { useContext, useState, useEffect } from "react";
import { Tree, Popover, Modal, Input, Row, Col } from "antd";
import { MinusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Link, useLocation, useParams } from "react-router-dom";
import "./course-section.scss";
import axios from "axios";
import { BASE_URL } from "../../../../../api/base-url";
import AuthContext from "../../../../../context/auth-provider";
import CourseLesson from "./course-lesson/course-lesson";

const CourseSection = () => {
  const { courseId } = useParams();

  const token = localStorage.getItem("token");

  // MODALS STATES
  const [modal, setModal] = useState({
    createModal: false,
    updateModal: false,
    deleteSectionModal: false,
    deleteLessonModal: false,
  });
  const [sectionId, setSectionId] = useState(null);
  const [deleteLessonId, setDeleteLessonId] = useState(null);

  const [courseLessonId, setCourseLessonId] = useState(null);

  const [isShowUpdate, setIsShowUpdate] = useState(false);
  //courseId, courseSectionId, courseLessonId

  const [isReloadPage, setIsReloadPage] = useState(false);

  // INPUT
  const [inputValues, setInputValues] = useState({
    name: "",
    orderNumber: "",
    id: "",
  });

  // VALUE
  const [data, setData] = useState(null);

  // APIS
  const createCourseSections = async () => {
    const { name, orderNumber } = inputValues;
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/course-section`,
      {
        name: name,
        orderNumber: orderNumber,
        courseId: courseId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    window.location.reload();
  };

  const getCourseSections = async () => {
    if (courseId === null || courseId === "") {
      return;
    }
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/course/mentor/tree/${courseId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setData(data.data);
  };

  const updateSection = async () => {
    const { name, orderNumber, id } = inputValues;
    const { data } = await axios.put(
      `${BASE_URL}/api/v1/course-section/${id}`,
      { name: name, orderNumber: orderNumber },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    window.location.reload();
  };

  const updateStatus = async (id) => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/course-section/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    window.location.reload();
  };

  // DELETE SECTION
  const deleteSection = async () => {
    const { data } = await axios.delete(
      `${BASE_URL}/api/v1/course-section/${sectionId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    window.location.reload();
  };

  // DELETE LESSON
  const deleteLesson = async () => {
    const { data } = await axios.delete(
      `${BASE_URL}/api/v1/course-lesson/mentor/${deleteLessonId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    window.location.reload();
  };

  useEffect(() => {
    getCourseSections();
  }, [isReloadPage]);

  // MODALS
  // Create Modals
  const showModal = (item) => {
    setModal({ ...modal, createModal: true });
    if (item) {
      setInputValues({
        ...inputValues,
        name: item.name,
        orderNumber: item.orderNumber,
        id: item.id,
      });
    } else {
      setInputValues({
        ...inputValues,
        name: "",
        orderNumber: "",
        id: "",
      });
    }
  };
  const handleCreateOk = () => {
    setModal({ ...modal, createModal: false });
    if (inputValues.id) {
      return updateSection();
    } else {
      return createCourseSections();
    }
  };
  const handleCreateCancel = () => {
    setModal({ ...modal, createModal: false });
  };

  // Delete Section Modals
  const showDeleteModal = (id) => {
    setModal({ ...modal, deleteSectionModal: true });
    setSectionId(id);
  };
  const handleDeleteOk = () => {
    setModal({ ...modal, deleteSectionModal: false });
    deleteSection();
  };
  const handleDeleteCancel = () => {
    setModal({ ...modal, deleteSectionModal: false });
  };

  const getCourseColor = (status, id) => {
    if (status === "PUBLISHED") {
      if (isShowUpdate === id) {
        return "#dc3545";
      } else {
        return "#2CBE89";
      }
    } else {
      return "#B6C2E2";
    }
  };
  return (
    <div className="course-section-wrapper">
      <div className="course-section-top d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column">
          <Link to="/mentor/course">
            <button className="btn btn-sm btn-secondary">&lt; Back</button>
          </Link>
          <h5 className="mt-1">{data?.name}</h5>
        </div>

        <button
          onClick={() => showModal()}
          className="btn btn-sm btn-success d-flex align-items-center gap-2"
        >
          {" "}
          Add Section <PlusCircleOutlined />
        </button>
      </div>
      <Row>
        <Col span={12}>
          <div className="course-section-list-tree mt-3 course-section-part">
            {data?.courseSectionList?.map((courseSectionItem, index) => {
              const lessonJson = JSON.parse(
                courseSectionItem?.lessonJson
                  ? courseSectionItem?.lessonJson
                  : null
              );
              let childName = [];
              lessonJson?.map((courseLessonItem, index) => {
                const obj = {
                  title: (
                    <div
                      style={{
                        backgroundColor: getCourseColor(
                          courseLessonItem.status,
                          courseLessonItem.id
                        ),
                        color:
                          courseLessonItem.id === isShowUpdate
                            ? "#fff"
                            : "inherit",
                      }}
                      className={`title child gap-2 m-1 d-flex align-items-start`}
                    >
                      <p
                        className="header-tree"
                        onClick={() => {
                          setCourseLessonId(courseLessonItem.id);
                          setIsShowUpdate(courseLessonItem.id);
                          setSectionId(courseSectionItem.id);
                        }}
                      >
                        {courseLessonItem.order_number} -{" "}
                        {courseLessonItem?.name}
                      </p>
                    </div>
                  ),
                };
                childName.push(obj);
              });
              return (
                <Tree
                  key={index}
                  showLine
                  multiple={false}
                  expandedKeys={[courseSectionItem.id]}
                  switcherIcon={<MinusCircleFilled className="circle-icon" />}
                  treeData={[
                    {
                      title: () => (
                        <div
                          style={{
                            backgroundColor:
                              courseSectionItem.status === "PUBLISHED"
                                ? "#2CBE89"
                                : "#B6C2E2",
                          }}
                          className="title d-flex align-items-start"
                        >
                          <p className="header-tree">
                            {courseSectionItem.orderNumber} -{" "}
                            {courseSectionItem?.name}
                          </p>
                          <Modal
                            title="Bo'limni o'chirish"
                            open={modal.deleteSectionModal}
                            onOk={handleDeleteOk}
                            okText="Delete"
                            cancelButtonProps={{ className: "cancel-btn" }}
                            okButtonProps={{ className: "delete-btn" }}
                            onCancel={handleDeleteCancel}
                          >
                            <div
                              style={{ borderTop: "1px solid #747474" }}
                              className="text-center p-4"
                            >
                              <h5>Do you want delete Section ?</h5>
                              <h3>{courseSectionItem?.name}</h3>
                            </div>
                          </Modal>
                          <Popover
                            placement="rightTop"
                            style={{ marginTop: 10 }}
                            content={
                              <div>
                                <p
                                  style={{ cursor: "pointer", color: "#000" }}
                                  onClick={() => {
                                    setCourseLessonId("0");
                                    setIsShowUpdate("0");
                                    setSectionId(courseSectionItem.id);
                                  }}
                                >
                                  {" "}
                                  Add Lesson
                                </p>
                                <p
                                  onClick={() => showModal(courseSectionItem)}
                                  style={{ cursor: "pointer" }}
                                >
                                  Update
                                </p>
                                <p
                                  onClick={() =>
                                    updateStatus(courseSectionItem.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  Update Status
                                </p>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    showDeleteModal(courseSectionItem?.id)
                                  }
                                >
                                  Delete
                                </p>
                              </div>
                            }
                            trigger="click"
                          >
                            <i>&gt;</i>
                          </Popover>
                        </div>
                      ),
                      key: courseSectionItem?.id,
                      children: childName,
                    },
                  ]}
                />
              );
            })}
            <Modal
              title="Yangi bo’lim qo’shish"
              open={modal.createModal}
              onOk={handleCreateOk}
              okText={inputValues.id ? "Update" : "Create"}
              cancelButtonProps={{ className: "cancel-btn" }}
              okButtonProps={{
                className: "create-btn",
                style: {
                  backgroundColor: inputValues.id ? "#FFC107" : "#146C43",
                },
              }}
              onCancel={handleCreateCancel}
            >
              <div
                style={{ borderTop: "1px solid #747474" }}
                className="inputs d-flex flex-column gap-3 mb-5"
              >
                <div className="input">
                  <label htmlFor="name" className="p-1">
                    Bo'lim nomi
                  </label>
                  <Input
                    id="name"
                    value={inputValues.name}
                    onChange={(e) =>
                      setInputValues({ ...inputValues, name: e.target.value })
                    }
                  />
                </div>
                <div className="input">
                  <label htmlFor="order-number" className="p-1">
                    Tartib raqami
                  </label>
                  <Input
                    value={inputValues.orderNumber}
                    id="order-number"
                    onChange={(e) =>
                      (e.target.value >= 1 || e.target.value === "") &&
                      setInputValues({
                        ...inputValues,
                        orderNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </Modal>
          </div>
        </Col>
        <Col span={12}>
          <div className="course-section-part">
            {isShowUpdate === courseLessonId && (
              <CourseLesson
                courseId={courseId}
                courseSectionId={sectionId}
                courseLessonId={courseLessonId}
                setIsShowUpdate={setIsShowUpdate}
                setIsReloadPage={setIsReloadPage}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CourseSection;
