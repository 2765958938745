import './SimpleChat.scss';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import axios from 'axios';

import {BASE_URL} from '../../api/base-url';


import SimpleChatInput from './SimpleChatInput';
import ChatMessageContainer from "./ChatMessageContainer";

const SimpleChat = ({profileType, courseLessonId, clientId}) => {
    // profileType = CLIENT,SUPPORT
    // If profileType='CLIENT'  -> clientId will be null
    const {state} = useLocation();
    const token = localStorage.getItem('token')
    const [messageList, setMessageList] = useState([]);
    const [sendMessageDTO, setSendMessageDTO] = useState({})

    /** message list */
    const getMessageListAsClient = async () => {
        const {data} = await axios.get(`${BASE_URL}/api/v1/simple_message/pagination/client/${courseLessonId}?page=0&size=2000`, {
            headers: {Authorization: `Bearer ${token}`},
        });
        setMessageList(data?.data?.content)
        if (data?.data?.content?.length > 0) {
            const lastMessage = data?.data?.content[data?.data?.content?.length - 1];
            if (!lastMessage?.isClientRead) {
                markMessageAsClientRead(courseLessonId)
            }
        }
    };

    const getMessageListAsSupport = async () => {
        if (!courseLessonId || !clientId) {
            return;
        }
        const {data} = await axios.get(`${BASE_URL}/api/v1/simple_message/pagination/support/${courseLessonId}/${clientId}?page=0&size=2000`, {
            headers: {Authorization: `Bearer ${token}`},
        });
        setMessageList(data?.data?.content)
        if (data?.data?.content?.length > 0) {
            const lastMessage = data?.data?.content[data?.data?.content?.length - 1];
            if (!lastMessage?.isSupportRead) {
                markSimpleMessageAsSupportRead(courseLessonId, state?.clientId)
            }
        }
    };

    /** mark message */
    const markMessageAsClientRead = async () => {
        const {data} = await axios.get(`${BASE_URL}/api/v1/simple_message/mark/client/${courseLessonId}`, {
            headers: {Authorization: `Bearer ${token}`},
        });
    };
    const markSimpleMessageAsSupportRead = async () => {
        const {data} = await axios.get(`${BASE_URL}/api/v1/simple_message/mark/support/${courseLessonId}/${clientId}`, {
            headers: {Authorization: `Bearer ${token}`},
        });
    };

    useEffect(() => {
        if (courseLessonId) {
            if (profileType === 'CLIENT') {
                getMessageListAsClient()
            } else if (profileType === 'SUPPORT') {
                getMessageListAsSupport()
            }
        }
    }, [courseLessonId, sendMessageDTO]);

    return (<div className='simple-chat-main-wrapper'>
        <div className='simple-chat-wrapper'>
            <ChatMessageContainer messageList={messageList} profileType={profileType}/>
            <SimpleChatInput
                profileType={profileType}
                selectedCourseLessonId={courseLessonId}
                clientId={state?.clientId}
                setSendMessageDTO={setSendMessageDTO}
            />
        </div>
    </div>);
};

export default SimpleChat;
