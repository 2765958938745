import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/auth-provider";
import "./summary.scss";
import { currencyFormat } from "../../../utils/Utils";

const Summary = ({ data }) => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  return (
    <div style={{ width: 400 }} className="summary-wrapper">
      <div className="summary-title summary-block-title d-flex align-items-center justify-content-between">
        <h5 className="summary-block-title-text">Kurs Haqida ma'lumotlar</h5>
        {/*<div className='summary-icons d-flex gap-4 summary-block-title-text'>*/}
        {/*    <i*/}
        {/*        style={{fontSize: 18, cursor: 'pointer'}}*/}
        {/*        className='fa-solid fa-share-nodes'*/}
        {/*    ></i>*/}
        {/*    <i*/}
        {/*        style={{fontSize: 18, cursor: 'pointer'}}*/}
        {/*        className='fa-regular fa-bookmark'*/}
        {/*    ></i>*/}
        {/*</div>*/}
      </div>
      <div className="languages-wrapper d-flex align-items-end  bg-light">
        <ul className="w-100">
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>Kurs nomi</p>
                <h6>{data?.name}</h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>Sertifikat</p>
                <h6>
                  {data?.hasCertificate
                    ? "Kurs tugaganidan keyin Sertificat bilan beriladi."
                    : "Sertifikat berilmaydi"}
                </h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                  Qo'llab quvvatlash bo'limi
                </p>
                <h6>{data?.hasCertificate ? "Bor" : "Yo'q"}</h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>Kurs narxi</p>
                <h6>
                  {data?.price ? `${currencyFormat(data?.price)} UZS` : "Bepul"}
                </h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                  Kurs muddati
                </p>
                <h6>{data?.availableDay} kun</h6>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Summary;
