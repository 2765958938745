import React from 'react'
// https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
// https://www.educative.io/answers/how-to-implement-pagination-in-reactjs
const Pagination = ({totalItems, currentPage, itemsPerPage, setCurrentPage}) => {
    // default to first page
    currentPage = currentPage || 1;
    // default page size is 10
    itemsPerPage = itemsPerPage || 30;
    // calculate total pages
    var totalPages = Math.ceil(totalItems / itemsPerPage);
    var startPage, endPage;
    if (totalPages <= 10) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }
    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * itemsPerPage;
    var endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
    // const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    // console.log(pages[pages.length-1]);
    const goToNextPage = () => {
        if (currentPage !== pages[pages.length-1]) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <nav>
            <ul className='pagination pagination-sm justify-content-center'>
                <li className="page-item">
                    <a className="page-link"
                       onClick={goToPrevPage}
                       href='#'>

                        Previous
                    </a>
                </li>
                {pages.map(pgNumber => (
                    <li key={pgNumber}
                        className={`page-item ${currentPage === pgNumber ? 'active' : ''} `}>

                        <a onClick={() => setCurrentPage(pgNumber)}
                           className='page-link'
                           href='#'>

                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link"
                       onClick={goToNextPage}
                       href='#'>

                        Next
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default Pagination