import React, {useEffect, useState} from 'react';
import './AdminClientDetail.scss';
import axios from 'axios';
import {useParams} from "react-router-dom";
import {BASE_URL} from "../../../../../api/base-url";
import {Input, Select} from "antd";
import {getDateFormat, getDateInSimpleFormat, splitAndReturnAsArray} from "../../../../../utils/Utils";
import Pagination from "../../../../../utils/Pagination";
// import {useState} from "@types/react";
import {useNavigate} from "react-router-dom";


const AdminClientDetail = () => {
    const {clientId} = useParams();

    const navigate = useNavigate();
    const token = localStorage.getItem('token')

    const [clientData, setClientData] = useState({});

    const [clientCourseList, setClientCourseList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalElements, setTotalElements] = useState(0);

    const [loginHistoryList, setLoginHistoryList] = useState([]);
    const [logHistoryCurrentPage, setLogHistoryCurrentPage] = useState(1);
    const [logHistoryItemsPerPage, setLogHistoryItemsPerPage] = useState(10);
    const [logHistoryTotalElements, setLogHistoryTotalElements] = useState(0);

    const getClientData = async () => {
        if (clientId === null) {
            return;
        }

        const {data} = await axios.get(
            `${BASE_URL}/api/v1/client/${clientId}`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        setClientData(data.data);
    };

    const getClientAssignedCourseList = async () => {
        if (clientId === null) {
            return;
        }
        const {data} = await axios.get(
            `${BASE_URL}/api/v1/client-curse/client/${clientId}?page=${currentPage}&size=${itemsPerPage}`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        setClientCourseList(data?.data?.content);
        setTotalElements(data?.data?.totalElements);
    };

    const getClientLoginHistoryList = async () => {
        if (clientId === null) {
            return;
        }
        const {data} = await axios.get(
            `${BASE_URL}/api/v1/person-request-log/${clientId}?page=${logHistoryCurrentPage}&size=${logHistoryItemsPerPage}`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        setLoginHistoryList(data?.data?.content);
        setLogHistoryTotalElements(data?.data?.totalElements);
    };

    useEffect(() => {
        getClientData();
        getClientAssignedCourseList();
        getClientLoginHistoryList();
    }, []);

    useEffect(() => {
        getClientAssignedCourseList();
    }, [currentPage]);

    useEffect(() => {
        getClientLoginHistoryList();
    }, [logHistoryCurrentPage]);

    return (
        <div className='admin-client-detail-wrapper'>
            <div className='profile-detail-wrapper'>
                <div className='admin-client-detail-img-wrapper'>
                    <img className='admin-client-detail-img'
                         src={
                             clientData.attachUrl ? clientData.attachUrl : 'https://www.w3schools.com/howto/img_avatar.png'
                         }
                         alt=''
                    />
                </div>
                <div>
                    <h3>Client Detail</h3>
                    <table className="admin-client-detail-table  table table-bordered table-striped">
                        <tr>
                            <th>Name</th>
                            <td>{clientData.name}</td>
                        </tr>
                        <tr>
                            <th>Surname</th>
                            <td>{clientData.surname}</td>
                        </tr>
                        <tr>
                            <th>Phone</th>
                            <td>{clientData.phoneNumber}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>{clientData.status}</td>
                        </tr>
                        <tr>
                            <th>CreatedDate</th>
                            <td>{getDateFormat(clientData.createdDate)}</td>
                        </tr>
                        <tr>
                            <th>Roles</th>
                            <td>{splitAndReturnAsArray(clientData.roleList).map(value => {
                                return (<span>{value + '   '}</span>)
                            })}</td>
                        </tr>
                        <tr>
                            <th>AuthIp</th>
                            <td>{clientData.authIp}</td>
                        </tr>
                        <tr>
                            <th>Last Login Date</th>
                            <td> {getDateFormat(clientData.lastLoginDate)}</td>
                        </tr>
                        <tr>
                            <th>Telegram user id</th>
                            <td> {clientData.telegramUserId}</td>
                        </tr>
                    </table>


                </div>
            </div>
            <br/>
            <hr/>
            <h3>Client course table</h3>
            <div className='client-assigned-course-wrapper'>
                <div className='admin-client-wrapper'>
                    <div><b>Total</b>: {totalElements}</div>
                    <div className='clients'>
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr className='font-size-13'>
                                <th scope="col">#</th>
                                <th scope="col">Course</th>
                                <th scope="col">CCStatus</th>
                                <th scope="col">Purchase</th>
                                <th scope="col">Start</th>
                                <th scope="col">Finish</th>
                                <th scope="col">EndDate</th>
                                <th scope="col">CreatedDate</th>
                            </tr>
                            </thead>
                            <tbody>
                            {clientCourseList?.map((item, index) => {
                                return (<tr key={item.id}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{item.courseName}</td>
                                    <td>{item.clientCourseStatus}</td>
                                    <td>{item.purchaseType}</td>
                                    <td>{item.courseStartedDate}</td>
                                    <td>{item.courseFinishedDate}</td>
                                    <td>{item.clientEndDate}</td>
                                    <td>{item.clientCourseCreatedDate}</td>
                                    <td>
                                        <button className='btn btn-primary btn-sm'
                                                onClick={() => {
                                                    navigate(`/admin/client-course-detail/${clientId}/${item.courseId}`);
                                                }}>
                                            <i className='fa-solid fa-info'></i>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                        <Pagination
                            totalItems={totalElements}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            nPages={5}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </div>
            <br/>
            <hr/>
            <h3>Client request log table</h3>
            <div className='client-assigned-course-wrapper'>
                <div className='admin-client-wrapper'>
                    <div><b>Total</b>: {logHistoryTotalElements}</div>
                    <div className='clients'>
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr className='font-size-13'>
                                <th scope="col">#</th>
                                <th scope="col">Ip</th>
                                <th scope="col">Type</th>
                                <th scope="col">Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loginHistoryList?.map((item, index) => {
                                return (<tr key={item.createdDate}>
                                    <td>{(logHistoryCurrentPage - 1) * logHistoryItemsPerPage + index + 1}</td>
                                    <td>{item.requestIp}</td>
                                    <td>{item.requestType}</td>
                                    <td>{item.createdDate}</td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                        <Pagination
                            totalItems={logHistoryTotalElements}
                            currentPage={logHistoryCurrentPage}
                            itemsPerPage={logHistoryItemsPerPage}
                            nPages={5}
                            setCurrentPage={setLogHistoryCurrentPage}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}


export default AdminClientDetail;
