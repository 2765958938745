import React, {useEffect, useState} from "react";
import axios from "axios";
import {Input, Modal} from "antd";
import {BASE_URL} from "../../../../../api/base-url";
import "./topic.scss";

const AdminTopic = () => {
    // STATES
    const token = localStorage.getItem('token')
    const [topicData, setTopicData] = useState([]);
    const [inputValues, setInputValues] = useState({
        query: null
    });
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] = useState(false);
    const [newTopicName, setNewTopicName] = useState("");
    const [updateTopicName, setUpdateTopicName] = useState("");
    const [updateId, setUpdateId] = useState("");

    const getData = async () => {
        const {data} = await axios.get(`${BASE_URL}/api/v1/topic`, {
            headers: {Authorization: `Bearer ${token}`},
        });
        setTopicData(data);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getData();
    }, []);

    // API
    const createTopic = async () => {
        const {data} = await axios.post(
            `${BASE_URL}/api/v1/topic`,
            {name: newTopicName},
            {headers: {Authorization: `Bearer ${token}`}}
        );
        window.location.reload();
    };

    const updateTopic = async () => {
        const {} = await axios.put(
            `${BASE_URL}/api/v1/topic/${updateId}`,
            {
                name: updateTopicName,
            },
            {headers: {Authorization: `Bearer ${token}`}}
        );
        window.location.reload();
    };

    const deleteTopic = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Delete topic")) {
            const {} = await axios.delete(`${BASE_URL}/api/v1/topic/${id}`, {
                headers: {Authorization: `Bearer ${token}`},
            });
            window.location.reload();
        }
    };

    const clearInputs = () => {

    }

    const openCreateTopicModal = () => {
        setIsCreateTopicModalOpen(true);
    }
    // HANDLE BUTTONS
    const handleCreateOk = () => {
        setIsCreateTopicModalOpen(false);
        createTopic();
    };
    const handleUpdateOk = () => {
        setIsModalOpen(false);
        updateTopic();
    };


    return (
        <div className='admin-client-wrapper'>
            <div className='admin-search-panel'>
                <div className='inputs d-flex align-items-center gap-2 pb-1'>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='query'>
                            Name or surname
                        </label>
                        <Input id='query' style={{width: 'max-content'}}
                               onChange={(e) =>
                                   setInputValues({...inputValues, query: e.target.value})
                               }/>
                    </div>
                    <button className='btn btn-primary btn-sm' style={{marginTop: 30}}
                            onClick={() => clearInputs()}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <button className='btn btn-primary btn-sm' style={{marginTop: 30}}
                            onClick={() => {
                                // setCurrentPage(1);
                                getData();
                            }}>
                        <i className='fa-solid fa-magnifying-glass'></i>
                    </button>
                </div>
                <div>
                    <b>Total</b>: {topicData.length}
                    <button className="btn btn-sm btn-primary ms-2" onClick={openCreateTopicModal}>
                        <i className='fa-solid fa-plus'></i> Add
                    </button>
                </div>
                <Modal
                    title="Create New Topic"
                    okButtonProps={{style: {backgroundColor: "#198754"}}}
                    okText="Create"
                    open={isCreateTopicModalOpen}
                    onOk={handleCreateOk}
                    onCancel={() => setIsCreateTopicModalOpen(false)}>
                    <Input onChange={(e) => setNewTopicName(e.target.value)}/>
                </Modal>
            </div>

            <div className='clients'>
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr className='font-size-13'>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">CreatedDate</th>
                        <th scope="col">Status</th>
                        <th scope="col">Visible</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {topicData ? topicData.map((item, index) => {
                        return (<tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.localDateTime}</td>
                            <td>{item.status}</td>
                            <td>{item.visible}</td>
                            <td>
                                <div className="d-flex gap-3">
                                    <button
                                        onClick={() => {
                                            setIsModalOpen(true);
                                            setUpdateTopicName(item.name);
                                            setUpdateId(item.id);
                                        }}
                                        className="btn btn-warning btn-sm">
                                        <i
                                            style={{cursor: "pointer"}}
                                            className="fa-solid fa-pen"></i>
                                    </button>
                                    <button
                                        onClick={() => deleteTopic(item.id)}
                                        className="btn btn-danger btn-sm">
                                        <i
                                            style={{cursor: "pointer"}}
                                            className="fa-solid fa-trash"></i>
                                    </button>
                                    <Modal
                                        title="Update Topic"
                                        open={isModalOpen}
                                        okText="Update"
                                        okButtonProps={{style: {backgroundColor: "#FFC107"}}}
                                        onOk={() => handleUpdateOk()}
                                        onCancel={() => setIsModalOpen(false)}>
                                        <Input
                                            value={updateTopicName}
                                            onChange={(e) => setUpdateTopicName(e.target.value)}
                                        />
                                    </Modal>
                                </div>
                            </td>
                        </tr>)
                    }) : ''}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminTopic;