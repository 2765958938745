import {useState} from 'react';

import {DownloadOutlined} from '@ant-design/icons';

import {Modal} from 'antd';
import {BASE_URL} from "../../api/base-url";

function ChatAttachedMessageView({fileData, isRead, showAttachReadIndicator = false}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleImgClick = () => {
        setImgUrl(webContentLink);
        setIsModalOpen(true);
    };

    async function downloadFile(link, originalName, extension) {
        const file = await fetch(link);
        const cvFileBlob = await file.blob();

        const aElement = document.createElement('a');
        aElement.setAttribute(
            'download',
            `${originalName.substring(0, originalName.lastIndexOf('.'))}.${extension}`
        );
        const href = URL.createObjectURL(cvFileBlob);
        aElement.href = href;
        aElement.click();
        URL.revokeObjectURL(href);
    }

    async function downloadImage(link, originalName) {
        const file = await fetch(link);
        const cvFileBlob = await file.blob();

        const aElement = document.createElement('a');
        aElement.setAttribute(
            'download',
            `${originalName}`
        );
        const href = URL.createObjectURL(cvFileBlob);
        aElement.href = href;
        aElement.click();
        URL.revokeObjectURL(href);
    }

    const {extension, webContentLink, originalName} = fileData || {};
    const [imgUrl, setImgUrl] = useState('');
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        return (
            <div>
                <img
                    onClick={handleImgClick}
                    src={webContentLink}
                    alt=''
                    className='message-item__img'/>
                <p className='d-flex justify-content-end'>
                    <a className='on-click-hand m-1' target='_blank'
                       href={`${BASE_URL}/api/v1/attach/open-all/${fileData.id}`}><i className='fa fa-search'/>
                    </a>
                    <i className='fa-solid fa-download on-click-hand m-1'
                       onClick={() => downloadImage(webContentLink, originalName)}/>
                </p>
                {showAttachReadIndicator ?
                    (<div className={'message-item__img_indicator'}>{isRead ? (
                        <i className='fa-solid fa-check-double'/>
                    ) : (
                        <i className='fa-sharp fa-solid fa-check'/>
                    )}</div>) : (<div></div>)
                }
                <Modal
                    open={isModalOpen}
                    width={850}
                    centered
                    style={{top: 5}}
                    cancelButtonProps={{
                        style: {
                            backgroundColor: '#565E64',
                            color: '#fff',
                        },
                    }}
                    okButtonProps={{style: {display: 'none'}}}
                    onCancel={handleCancel}>
                    <img
                        style={{
                            height: '450px',
                            width: '100%',
                            objectFit: 'contain',
                        }}
                        src={imgUrl}
                        alt=''/>
                </Modal>
            </div>
        );
    }
    if (extension === 'mp3' || extension === 'mp4') {
        return (
            <video
                src={webContentLink}
                className='message-item__audio'
                controls
            ></video>
        );
    }
    if (extension === 'pdf' || extension === 'txt' || extension === 'zip') {
        return (
            <span
                onClick={() => downloadFile(webContentLink, originalName, extension)}
                className='message-item message-item__file'
            >
        <DownloadOutlined className='download-icon'/>
                {originalName}
                {showAttachReadIndicator ? (<> {isRead ? (
                    <i className='fa-solid fa-check-double'/>
                ) : (
                    <i className='fa-sharp fa-solid fa-check'/>
                )}</>) : (<div></div>)}

      </span>
        );
    }
}

export default ChatAttachedMessageView;
