import React from 'react'
import './footer.scss'
function Footer() {
  return (
  <footer className="footer">
    <div className="container-lg">
      <div className="d-flex flex-row align-items-center  justify-content-between">
        <p className='mb-0'>All Rigths Reserved</p>
        <ul className="navbar-nav d-flex flex-row gap-4">
            <li className="nav-item">
              <a className="nav-link" href="#">Privacy Policy</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Privacy Notice</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Cookies Policy</a>
            </li>
        </ul>
      </div>
    </div>
    {/* <ul className="footer-menu">
      <li className="footer-meni__item">Privacy Policy</li>
      <li className="footer-meni__item">Privacy Notice</li>
      <li className="footer-meni__item">Cookies Policy</li>
    </ul> */}
  </footer>
  )
}

export default Footer