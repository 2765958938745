import ChatAttachedMessageView from './ChatAttachedMessageView';
import React, {useEffect} from 'react';

function ChatMessageContainer({messageList, profileType}) {

    const clientMessageContainer = () => {
        return messageList.map((message, idx) => {
            if (!message.attach) {
                return (
                    <div className={message.type === 'CLIENT' ? 'right' : 'left'} key={idx}>
                          <span className='message-item'>
                            {message.message}
                              {message.type === 'CLIENT' &&
                                  (message.isSupportRead ? (
                                      <i className='fa-solid fa-check-double'/>
                                  ) : (
                                      <i className='fa-sharp fa-solid fa-check'/>
                                  ))}
                          </span>
                    </div>
                );
            }
            return (
                <div className={message.type === 'CLIENT' ? 'right' : 'left'} key={idx}>
                    <ChatAttachedMessageView
                        fileData={message.attach}
                        isRead={message.isSupportRead}
                        showAttachReadIndicator={message.type === 'CLIENT'}
                    />
                </div>
            );
        })
    }
    const supportMessageContainer = () => {
        return messageList.map((message, idx) => {
            if (!message.attach) {
                return (
                    <div className={message.type === 'SUPPORT' ? 'right' : 'left'} key={idx}>
              <span className='message-item'>
                {message.message}
                  {message.type !== 'CLIENT' &&
                      (message.isClientRead ? (
                          <i className='fa-solid fa-check-double'/>
                      ) : (
                          <i className='fa-sharp fa-solid fa-check'/>
                      ))}
              </span>
                    </div>
                );
            }
            return (
                <div
                    className={message.type === 'SUPPORT' ? 'right' : 'left'}
                    key={idx}
                >
                    <ChatAttachedMessageView
                        fileData={message.attach}
                        isRead={message.isClientRead}
                        showAttachReadIndicator={message.type === 'SUPPORT'}
                    />
                </div>
            );
        })
    }

    return (
        <div className='chat-container'>
            {profileType === 'SUPPORT' ? supportMessageContainer() : clientMessageContainer()}
        </div>
    );
}

export default ChatMessageContainer;
