import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import LessonPopover from './LessonPopover';

function SupportSimpleChatCourseTree({
                                         selectedCourseLessonId,
                                         courseSectionList,
                                         setSelectedCourseLesson
                                     }) {
    const {state} = useLocation();

    useEffect(() => {
        const selectTedCourseLesson = document.getElementById(selectedCourseLessonId);
        if (selectTedCourseLesson != null) {
            selectTedCourseLesson.scrollIntoView();
        }
    }, [selectedCourseLessonId, courseSectionList]);

    function getLessonStatusColor(item) {
        if (!item.clientCourseLesson) {
            return 'grey';
        }
        if (selectedCourseLessonId && item.id === selectedCourseLessonId) {
            return 'red';
        }
        const clientLessonStatus = JSON.parse(item.clientCourseLesson);
        switch (clientLessonStatus.status) {
            case 'START':
                return '#00a1ce';
            case 'FINISH':
                return '#2CBE89';
            default:
                return 'grey';
        }
    }


    function getLessonItemBG(item) {
        if (item.type === 'EXERCISE') {
            return '#f1efef';
        }
    }

    const handleLessonClick = (lesson) => {
        setSelectedCourseLesson(lesson);
    };
    return (
        <div className='messages-tree'>
            <ul>
                {courseSectionList &&
                    courseSectionList.map((section) => (
                        <li key={section.id}>
                            {section.name}
                            <ul>
                                {JSON.parse(section?.lessonListJson).map((lesson) => (
                                    <li
                                        key={lesson.id} id={lesson.id}
                                        onClick={() => handleLessonClick(lesson)}
                                        style={{backgroundColor: getLessonItemBG(lesson)}}
                                    >
                    <span className='item__status'
                          style={{
                              backgroundColor: getLessonStatusColor(lesson),
                          }}></span>
                                        <span> {lesson.name}</span>
                                        <LessonPopover selectedCourseLessonId={lesson.id}>
                      <span className='item__popover'>
                        <i className='fa-solid fa-ellipsis-vertical'></i>
                      </span>
                                        </LessonPopover>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
            </ul>
        </div>
    );
}

export default SupportSimpleChatCourseTree;
