import React from 'react';
import './MusePlayerTest.scss'
const MusePlayerTest = () => {

    return (
        <divn className='muse-test_player_wrapper'>
            <iframe
                src={`https://muse.ai/embed/3Ak8H1s?search=0&links=0&logo=0&title=0&cover_play_position=center`}
                allowFullScreen>
            </iframe>
        </divn>
    );
}


export default MusePlayerTest;