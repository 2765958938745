import React from "react";
import { Line } from "@ant-design/charts";
import { AddTask, Card, TaskCard } from "../../../components";
import "./main.scss";

const data = [
  { year: "1991", value: 3 },
  { year: "1992", value: 4 },
  { year: "1993", value: 3.5 },
  { year: "1994", value: 5 },
  { year: "1995", value: 4.9 },
  { year: "1996", value: 6 },
  { year: "1997", value: 7 },
  { year: "1998", value: 9 },
  { year: "1999", value: 13 },
];

const config = {
  data,
  height: 400,
  xField: "year",
  yField: "value",
  point: {
    size: 5,
    shape: "diamond",
  },
};

const Main = () => {
  return (
    <>
      <div className="main-wrapper">
        <div className="main-cards">
          <Card title="Unresolved" counts={40} />
          <Card title="Unresolved" counts={20} />
          <Card title="Unresolved" counts={76} />
          <Card title="Unresolved" counts={98} />
        </div>
        <div className="trend-wrap mt-3">
          <div className="trend">
            <Line {...config} />
          </div>
          <div className="trend-results">
            <Card title="Resolved" counts="32%" />
            <Card title="Recevied" counts="23%" />
            <Card title="Recevied" counts="54%" />
          </div>
        </div>
        <div className="tasks">
          <TaskCard />
          <AddTask />
        </div>
      </div>
    </>
  );
};

export default Main;
