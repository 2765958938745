import './support-simple-chat.scss';
import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import axios from 'axios';
import {BASE_URL} from '../../../../../api/base-url';
import SupportSimpleChatCourseTree from './SupportSimpleChatCourseTree';
import SimpleChat from "../../../../../components/simple-chat/SimpleChat";
import {PublicPageDashboard} from "../index";
import UpdatePhone from "../../../../user-profile/UpdatePhone";
import {Tabs} from "antd";

const SupportSimpleChatComponent = () => {
    const {state} = useLocation();
    const token = localStorage.getItem('token')

    const [clientCourseData, setClientCourseData] = useState([]);
    const [selectedCourseLessonId, setSelectedCourseLessonId] = useState('');
    const [selectedCourseLesson, setSelectedCourseLesson] = useState({});

    const getClientCourseDetail = async () => { // TODO it works every time when lessons selects
        const {data} = await axios.get(
            `${BASE_URL}/api/v1/course/support/tree/${state?.courseId}/${state?.clientId}`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        setClientCourseData(data?.data);
    };

    useEffect(() => {
        if(selectedCourseLesson.id){
            setSelectedCourseLessonId(selectedCourseLesson.id);
        }else{
            setSelectedCourseLessonId(state.lessonId);
        }
    }, [ ,selectedCourseLesson]);

    useEffect(() => {
        if (state.lessonId) {
            getClientCourseDetail()
        }
    }, [state]);

    const tabItemList = [
        {
            key: '1',
            label: 'Chat',
            children:<SimpleChat
                profileType={'SUPPORT'}
                courseLessonId={selectedCourseLessonId}
                clientId={state?.clientId}></SimpleChat>,
        },
        {
            key: '2',
            label: 'Exercise',
            children:    <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: selectedCourseLesson.content}}></div>,
        },
    ]

    return (
        <div className='support-simple-chat-wrapper'>
            <div className='support-simple-chat-header'>
                <Link to='/mentor/messages'>
                    <button className='btn btn-secondary btn-sm'>
                        <i className='fa-solid fa-arrow-left'></i> Back
                    </button>
                </Link>
                <div>
                    <b>{clientCourseData?.name}</b> - {clientCourseData?.client?.name} {clientCourseData?.client?.surname}
                </div>
            </div>
            <div className='support-simple-chat-inner-wrapper'>
                <SupportSimpleChatCourseTree
                    selectedCourseLessonId={state?.lessonId}
                    courseSectionList={clientCourseData?.courseSectionList}
                    setSelectedCourseLesson={setSelectedCourseLesson}
                />
                <div className='support-simple-chat-container'>
                    <Tabs defaultActiveKey="1" items={tabItemList} value="small" type="card" />
                </div>
            </div>
        </div>
    );
};

export default SupportSimpleChatComponent;
