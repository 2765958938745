import React, {useEffect, useState} from 'react';
import {Input, Select} from 'antd';
import './AdminClient.scss';
import axios from 'axios';
import {BASE_URL} from '../../../../../api/base-url';
import {getDateInSimpleFormat, splitAndReturnAsArray} from "../../../../../utils/Utils";
import Pagination from "../../../../../utils/Pagination";
import {useNavigate} from "react-router-dom";

const AdminClient = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    const [data, setData] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [modal, setModal] = useState({
        updateModal: false,
        deleteModal: false,
    });
    const [inputValues, setInputValues] = useState({
        query: null,
        phone: null,
        status: 'ALL',
        orderBy: "created_date",
        direction: "DESC",
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [totalElements, setTotalElements] = useState(0);

    // GET CLIENTS
    const getClients = async () => {
        if (inputValues.status === 'ALL') {
            inputValues.status = null;
        }
        const {data} = await axios.post(
            `${BASE_URL}/api/v1/client/filter?page=${currentPage}&size=${itemsPerPage}`,
            inputValues,
            {headers: {Authorization: `Bearer ${token}`}}
        );
        setData(data?.data?.content);
        setClientList(data?.data?.content); // TODO remove

        const totalElements = data?.data?.totalElements;
        setTotalElements(totalElements);
    };

    useEffect(() => {
        getClients();
    }, [currentPage]);

    useEffect(() => {
        getClients();
    }, []);

    const clearInputs = () => {
        // setInputValues({
        //     nameOrSurname: null,
        //     phoneNumber: null,
        //     status: 'ALL',
        //     orderBy: 'created_date',
        //     direction: 'DESC',
        // });
    }
    /*  useEffect(() => {
          getClients();
      }, [inputValues]);*/

    return (
        <div className='admin-client-wrapper'>
            <div className='admin-search-panel'>
                <div className='inputs d-flex align-items-center gap-2 pb-1'>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='query'>
                            Name or surname
                        </label>
                        <Input id='query' style={{width: 'max-content'}}
                               onChange={(e) =>
                                   setInputValues({...inputValues, query: e.target.value})
                               }/>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='phone'>
                            Phone
                        </label>
                        <Input id='phone' style={{width: 'max-content'}}
                               onChange={(e) =>
                                   setInputValues({...inputValues, phone: e.target.value})
                               }/>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='status'>
                            Status
                        </label>
                        <Select
                            id='status'
                            defaultValue='ALL'
                            style={{
                                width: 100,
                            }}
                            options={[
                                {
                                    value: 'ALL',
                                    label: 'ALL',
                                },
                                {
                                    value: 'ACTIVE',
                                    label: 'ACTIVE',
                                },
                                {
                                    value: 'NOT_ACTIVE',
                                    label: 'NOT_ACTIVE',
                                }
                            ]}
                            onChange={(e) => {
                                setInputValues({...inputValues, status: e})
                            }
                            }
                        />
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='order_by'>
                            Order By
                        </label>
                        <Select
                            id='order_by'
                            defaultValue='created_date'
                            style={{
                                width: 150,
                            }}
                            options={[
                                {
                                    value: 'created_date',
                                    label: 'CreatedDate',
                                },
                                {
                                    value: 'last_login_date',
                                    label: 'AuthDate',
                                },
                                {
                                    value: 'auth_ip',
                                    label: 'AuthIP',
                                },
                                {
                                    value: 'joinedCourseCount',
                                    label: 'Joined',
                                },
                                {
                                    value: 'requestedCourseCount',
                                    label: 'Request',
                                },
                            ]}
                            onChange={(e) =>
                                setInputValues({...inputValues, orderBy: e})
                            }
                        />
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='mb-1' htmlFor='direction'>
                            Direction
                        </label>
                        <Select
                            id='direction'
                            defaultValue='DESC'
                            style={{
                                width: 80,
                            }}
                            options={[
                                {
                                    value: 'ASC',
                                    label: 'ASC',
                                },
                                {
                                    value: 'DESC',
                                    label: 'DESC',
                                }
                            ]}
                            onChange={(e) =>
                                setInputValues({...inputValues, direction: e})
                            }
                        />
                    </div>
                    <button className='btn btn-primary btn-sm' style={{marginTop: 30}}
                            onClick={() => clearInputs()}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <button className='btn btn-primary btn-sm' style={{marginTop: 30}}
                            onClick={() => {
                                // setCurrentPage(1);
                                getClients();
                            }}>
                        <i className='fa-solid fa-magnifying-glass'></i>
                    </button>
                </div>
                <div><b>Total</b>: {totalElements}</div>
            </div>

            <div className='clients'>
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr className='font-size-13'>
                        <th scope="col">#</th>
                        <th scope="col">Detail</th>
                        <th scope="col">Phone</th>
                        <th scope="col">CreatedDate</th>
                        <th scope="col">AuthDate</th>
                        <th scope="col">Joined</th>
                        <th scope="col">Requested</th>
                        <th scope="col">AuthIP</th>
                        <th scope="col">Roles</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clientList.map((item, index) => {
                        return (<tr key={item.id}
                                    className={`font-size-15 ${item.status == 'NOT_ACTIVE' ? 'red-row' : ''}`}>
                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                            <td>{item.name} {item.surname}</td>
                            <td>{item.phoneNumber}</td>
                            <td>{getDateInSimpleFormat(item.createdDate)}</td>
                            <td>{getDateInSimpleFormat(item.lastLoginDate)}</td>
                            <td>{item.joinedCourseCount}</td>
                            <td>{item.requestedCourseCount}</td>
                            <td>{item.authIp}</td>
                            <td>
                                {splitAndReturnAsArray(item.roleStr).map(value => {
                                    return (<p>{value}</p>)
                                })}
                            </td>
                            <td>
                                <button className='btn btn-primary btn-sm'
                                        onClick={() => {
                                            navigate(`/admin/client-detail/${item.id}`);
                                        }}>
                                    <i className='fa-solid fa-info'></i>
                                </button>
                            </td>
                        </tr>)
                    })}
                    </tbody>
                </table>
                <Pagination
                    totalItems={totalElements}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    nPages={10}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    );
};

export default AdminClient;
