import React, { useState } from "react";
import { Checkbox, Rate } from "antd";
import { BASE_URL } from "../../api/base-url";
import "./filter.scss";
import axios from "axios";
import { useEffect } from "react";

const Filter = (props) => {
  const { setCheckState, checkState } = props;
  const [data, setData] = useState([]);

  const desc = ["1", "2", "3", "4", "5"];

  const getTopics = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/topic/public/activeStatus`
    );
    setData(data);
  };

  useEffect(() => {
    getTopics();
  }, []);

  const handleRating = (e) => {
    setCheckState((prevState) => ({
      ...prevState,
      rating: e,
    }));
  };

  const handleSelect = (e) => {
    const { name, checked } = e.target;
    let ids = [...checkState.ids];
    if (checked) {
      ids = [...ids, name];
    } else {
      ids = ids.filter((item) => item !== name);
    }
    setCheckState({ ...checkState, ids });
  };

  return (
    <div className="filter-wrap">
      <div className="wrap">
        <p style={{ fontWeight: "bold" }}>Filter</p>
        <div className="languages mt-4">
          <p>Tillar</p>
          <div className="language-checkboxes">
            {data.map((item, idx) => (
              <div key={idx}>
                <Checkbox
                  name={item.id}
                  className="checkbox"
                  valuePropName="checked"
                  style={{ color: "#fff" }}
                  onChange={handleSelect}
                >
                  {item.name}
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
        {/* <div className='rating mt-4'>
          <p>Rate</p>
          <span>
            <Rate
              tooltips={desc}
              onChange={(e) => handleRating(e)}
              value={checkState?.rating}
            />
            <span> and above</span>
          </span>
        </div> */}
        {/* <div className="pricing mt-4">
          <p style={{ fontWeight: "bold" }}>Price</p>
          <Checkbox
            className="checkbox"
            style={{ color: "#fff" }}
            onChange={onChange}>
            Free
          </Checkbox>
        </div> */}
      </div>
      <div className="show-result">
        <p>Lorem ipsum dolor sit.</p>
        <div className="buttons">
          <button className="clear-btn">Clear Filter</button>
          <button className="show-btn">Show Result</button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
