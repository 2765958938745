import React from 'react';
import './MusePlayerTest.scss'

const OtherVideoPlayerTest = () => {

    return (
        <div>
            <div className='other-test_player_wrapper'>
                <iframe
                    src="https://player.vimeo.com/video/948682401?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    className='other-test_player_iframe'
                    title="hb 130 - 4K"></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
    );
}


export default OtherVideoPlayerTest;