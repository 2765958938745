import React from 'react';
import './inDetail.scss';

const InDetail = ({ data }) => {
  return (
    <div className='table-of-content-block-wrapper p-3'>
      <div className='description mt-3'>
        <div
          style={{ textAlign: 'justify' }}
          dangerouslySetInnerHTML={{ __html: data.fullDescription }}
        ></div>
      </div>
    </div>
  );
};

export default InDetail;
