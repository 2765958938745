import React, { useEffect, useState } from "react";
import "./hero-component.scss";
import StudentActiveCourseComponent from "../student-active-course-component/StudentActiveCourseComponent";
import axios from "axios";
import { handleUnAuthorized } from "../../utils/Utils";

const HeroComponent = () => {
  const [proverb, setProverb] = useState([]);
  const getRandomProverb = async () => {
    const { data } = await axios
      .get(`https://api.dasturjon.uz/api/v1/proverb/public/random`)
      .then((res) => {
        if (res.status === 401) {
          handleUnAuthorized();
        }
        return res;
      });
    setProverb(data.data.content);
  };

  useEffect(() => {
    getRandomProverb();
  }, []);

  return (
    <section className="hero">
      <div className="container-lg h-100">
        <div className="row h-100 align-items-center justify-content-between">
          <div className="col-md-5 col-12 text-start">
            <h1 className="fw-normal">Just keep going!</h1>
            <p dangerouslySetInnerHTML={{ __html: proverb }}></p>
          </div>
          <StudentActiveCourseComponent />
        </div>
      </div>
    </section>
  );
};

export default HeroComponent;
