import React from "react";
import "./card.scss";

const Card = ({ title, counts }) => {
  return (
    <div className="card-wrapper">
      <p>{title}</p>
      <h1>{counts}</h1>
    </div>
  );
};

export default Card;
