import React from "react";
import "./courseCard.scss";
import img from "../../assets/adras-material-pattern.jpg";
function CourseCard({
  banner,
  availableDay,
  id,
  name,
  price,
  rating,
  shortDescription,
}) {
  const type = "Online";
  const lang = "Uzbek";

  return (
    <div className="course-card">
      <div
        style={{
          backgroundImage: `url( ${banner?.url ? banner?.url : img})`,
        }}
        className="course-card__top d-flex align-items-center gap-4"
      >
        <span className="course-type">
          <i className="bi bi-globe2 me-2"></i>
          {type}
        </span>
        <span className="course-lang">
          <i className="bi bi-translate me-2"></i>
          {lang}
        </span>
        {/*<span className='course-saved ms-auto'>*/}
        {/*  <i className='bi bi-heart'></i>*/}
        {/*</span>*/}
      </div>
      <div className="course-card__bottom row d-column flex-column m-0 gap-3">
        <div className="col p-0 d-flex justify-content-between align-items-start">
          <span className="course-title">{name}</span>
          <span className="course-rating d-flex">
            <i className="bi bi-star-fill"></i>
            5.0
          </span>
        </div>
        <div className="col p-0 d-flex justify-content-between mb-4">
          <span className="course-short-description">{shortDescription}</span>
        </div>
        <div className="col p-0 d-flex justify-content-between">
          <span className="course-date d-flex  gap-2">
            <i className="bi bi-calendar"></i>
            {availableDay}
          </span>
          <span className="course-price">
            <i className="bi bi-cash me-2"></i>
            {price ? `${price.toLocaleString()} UZS` : "Bepul"}
          </span>
          <span className="course-members d-flex gap-2">
            <i className="bi bi-person-fill"></i>
            {}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
