import React, { useContext, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../context/auth-provider";
import { BASE_URL } from "../../api/base-url";
import "./LoginVerificationPage.scss";
import { Header } from "../../components";
import { getUserDetails } from "../../utils/getUserDetail";

const LoginVerificationPage = () => {
  const [value, setValue] = useState(null);
  const { setToken, setLoginPhone } = useContext(AuthContext);

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const loginPhone = localStorage.getItem("loginPhoneNumber");
  const onSubmit = async (value) => {
    //e.preventDefault();
    const header = {
      headers: { "Content-Type": "application/json" },
    };
    try {
      const item = await axios.post(
        `${BASE_URL}/api/v1/auth/client/login/verification`,
        {
          code: value,
          phone: loginPhone,
        },
        header
      );

      if (!item?.data.isError) {
        const userData = item?.data?.data;

        const fullName = {
          name: userData.name,
          surname: userData.surname,
          photo: userData.photo,
        };

        localStorage.setItem("fullName", JSON.stringify(fullName));
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("roleList", JSON.stringify(userData.roleList));
        localStorage.setItem("token", userData?.jwt);
        setToken(userData?.jwt);
        setLoginPhone(loginPhone);
        //window.location.href = "/";
        navigate("/");
        window.location.reload();

        // localStorage.setItem('loginUser', JSON.stringify(item));
        // //  setLogin(JSON.stringify(item))
        // localStorage.setItem('token', item?.data?.data?.jwt);
        // setToken(item?.data?.data?.jwt);
        // localStorage.setItem('roleList', item?.data?.data?.roleList);
      } else {
        setError(item.data.message);
        // localStorage.removeItem('loginPhoneNumber');
        localStorage.removeItem("token");
        localStorage.removeItem("roleList");
      }
    } catch (error) {}
  };

  return (
    <>
      <Header />
      <div className="verification-wrapper">
        <div className="details-wrap bg-light text-center">
          <h3>Sms kodni Tasdiqlash</h3>
          <p>{loginPhone} raqamiga sms kod yuborildi</p>
          <form onSubmit={onSubmit} className="verification-input">
            <ReactInputVerificationCode
              autoFocus
              length={5}
              placeholder=""
              onChange={(e) => {
                setValue(e);
                if (e.length === 5) {
                  onSubmit(e);
                }
              }}
            />
            <button type="submit" className="mt-3 confirm-btn">
              Tasdiqlash
            </button>
            <p className="mt-2 font-size-18">
              {" "}
              Sms kelmasa
              <a href="https://t.me/videohubsms_bot" target="_blank">
                {" "}
                Videohub SMS bot-ga
              </a>{" "}
              <br /> ulanib u orqali Sms larni qabul qiling.
              <br /> Bazan mazgi sms provider ishlamay qoladi.
            </p>
            <p></p>
            <p className="text-danger mt-3">{error}</p>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginVerificationPage;
