import React, { useContext, useState } from "react";
import axios from "axios";
import { Input } from "antd";
import { BASE_URL } from "../../../../../api/base-url";
import { useNavigate } from "react-router-dom";
import "./admin-update.scss";
import AuthContext from "../../../../../context/auth-provider";

const AdminUpdate = () => {
  const { admin } = useContext(AuthContext);
  const  token  = localStorage.getItem('token')
  const navigate = useNavigate();
  const userName = JSON.parse(localStorage.getItem("fullName"));

  const [inputValue, setInputValue] = useState({
    name: admin?.data?.data?.name || "",
    surname: admin?.data?.data?.surname || "",
    contact: admin?.data?.data?.contact || "",
    login: admin?.data?.data?.login || "",
  });

  const updateClientDetails = async () => {
    const { name, surname, contact, login } = inputValue;
    const { data } = await axios.put(
      `${BASE_URL}/api/v1/profile/detail`,
      {
        name: name,
        surname: surname,
        contact: contact,
        login: login,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="d-flex">
      <div className="public-page-wrapper w-100">
        <div className="d-flex align-items-center gap-5">
          <img
            src="https://www.w3schools.com/howto/img_avatar.png"
            className="client-image"
          />
          <div className="client-details">
            <div>
              <label htmlFor="name">Name</label>
              <Input
                onChange={(e) =>
                  setInputValue({ ...inputValue, name: e.target.value })
                }
                value={inputValue.name}
                id="name"
              />
            </div>
            <div>
              <label htmlFor="surname">Surname</label>
              <Input
                onChange={(e) =>
                  setInputValue({ ...inputValue, surname: e.target.value })
                }
                value={inputValue.surname}
                id="surname"
              />
            </div>
            <div>
              <label htmlFor="contact">Contact</label>
              <Input
                onChange={(e) =>
                  setInputValue({ ...inputValue, contact: e.target.value })
                }
                value={inputValue.contact}
                id="contact"
              />
            </div>
            <div>
              <label htmlFor="login">Login</label>
              <Input
                onChange={(e) =>
                  setInputValue({ ...inputValue, login: e.target.value })
                }
                value={inputValue.login}
                id="login"
              />
            </div>
          </div>
        </div>

        <div className="mt-5 d-flex justify-content-end w-50">
          <button onClick={updateClientDetails} className="btn btn-warning">
            Update Detail
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminUpdate;
