import React, { useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../api/base-url";
import { CatalogItem } from "../../../../components";
import "./myCourses.scss";
import AuthContext from "../../../../context/auth-provider";
import { useEffect } from "react";
import { useState } from "react";
const MyCourses = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const getClientCourses = async () => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/client-curse/client`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setData(data.data.content);
  };

  useEffect(() => {
    getClientCourses();
  }, []);

  return (
    <div className="my-courses-items-wrapper">
      {data.map((item, idx) => (
        <CatalogItem
          itemCourse={item.course}
          key={idx}
          isDisplayPrice={false}
        />
      ))}
    </div>
  );
};

export default MyCourses;
