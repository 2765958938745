import React from "react";
import {
    PieChartOutlined,
    UserOutlined,
    BookOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import {Menu} from "antd";
import "./sidebar.scss";
import {Link, useNavigate} from "react-router-dom";
import Icon from "../../../../assets/icon1.png";
import {useContext} from "react";
import AuthContext from "../../../../context/auth-provider";
import AdminClientCourse from "../../pages/admin-pages/client-course/AdminClientCourse";

const Sidebar = ({setTitle, openSidebar}) => {
    const {userData} = useContext(AuthContext);

    const navigate = useNavigate();

    function getItem(label, key, icon, tag, children, type) {
        return {
            key,
            type,
            icon,
            children,
            label,
            tag,
        };
    }

    const adminItems = [
        getItem("Main", "admin/main", <PieChartOutlined/>),
        getItem("Clients", "admin/client", <UserOutlined/>),
        getItem("ClientsCourse", "admin/client-course", <BookOutlined/>),
        getItem("Profile", "admin/profile", <VideoCameraOutlined/>),
        getItem("Topic", "admin/topic", <VideoCameraOutlined/>),
        getItem("Videos", "admin/videos", <VideoCameraOutlined/>),
        getItem("Course Banner", "admin/course-banner", <VideoCameraOutlined/>),
        getItem("Update Details", "admin/update-detail", <VideoCameraOutlined/>),
    ];

    const mentorItems = [
        getItem("Course", "mentor/course", <BookOutlined/>),
        // getItem("Videos", "mentor/videos", <VideoCameraOutlined/>),
        getItem("Messages", "mentor/messages", <VideoCameraOutlined/>),
        getItem("Update Detail", "mentor/profile", <VideoCameraOutlined/>),
    ];

    const checkRoles = () => {
        if (userData?.roleList?.includes("ROLE_ADMIN")) {
            return adminItems;
        }
        if (userData?.roleList?.includes("ROLE_MENTOR")) {
            return mentorItems;
        }
    };

    const onClick = (e) => {
        navigate(`/${e.key}`);
        localStorage.setItem("title", e.key);
    };
    return (
        <div
            style={{width: openSidebar ? "230px" : "60px"}}
            className="dashboard">
            <Link to="/" className=" d-flex gap-1 p-2 w-100 align-items-center">
                <img className="icon" src={Icon} alt=""/>
                <h3
                    style={{color: "#A4A6B3", display: openSidebar ? "block" : "none"}}>
                    Videohub
                </h3>
            </Link>
            <Menu
                onClick={(e) => onClick(e)}
                style={{
                    width: "98%",
                }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                mode="inline"
                items={checkRoles()}
            />
        </div>
    );
};

export default Sidebar;
