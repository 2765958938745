import { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userDetail, setUserDetail] = useState({});
  const value = { userDetail, setUserDetail};
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUserDetail() {
  return useContext(UserContext);
}
