import React, {useState, useEffect} from 'react';
import "./ShowMessageModal.scss";
import {useNavigate} from "react-router-dom";

const ShowMessageModal = () => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        // Define the event listener
        const handleShowModal = (event) => {
            setMessage(event.detail.message);
            setModalVisible(true);
        };
        // Attach the event listener to the window object
        window.addEventListener('showMessageModal', handleShowModal);
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('showMessageModal', handleShowModal);
        };
    }, [isModalVisible]);

    const closeModal = () => {
        setModalVisible(false);
        navigate('/login');
    }

    const closeMessageModal = (e) => {
        let modal = document.getElementById("messageModalId");
        if (modal && modal !== null && e.target === modal) {
            setModalVisible(false);
            navigate('/login');
        }
    };

    return (
        <div>
            {isModalVisible && (
                <div id="messageModalId" className="message-modal" onClick={(e) => closeMessageModal(e)}>
                    <div className="message-modal-content">
                        <div className="message-modal-header">
                            <span className="message-modal-close" onClick={closeModal}>&times;</span>
                            <h3>Message</h3>
                        </div>
                        <div className="message-modal-body">
                            <h2 className="text-center">{message}</h2>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowMessageModal;