// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Input, Select, SelectProps, InputNumber, Radio } from 'antd';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { BASE_URL } from '../../../../../api/base-url';
import './course-detail.scss';
import AuthContext from '../../../../../context/auth-provider';

const CourseDetail = () => {
  const { cousreId } = useParams();

  const  token  = localStorage.getItem('token')
  const { Option } = Select;

  const [data, setData] = useState({});

  const [isNewCourse, setNewCourse] = useState(true);

  const navigate = useNavigate();

  const [selectedVideoFormatList, setSelectedVideoFormatList] = useState([]);

  const getCourseData = async () => {
    if(cousreId === null){
      return;
    }
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/course/mentor/${cousreId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const courseData = data.data;
    if(courseData.formatTypes){
      setSelectedVideoFormatList(courseData.formatTypes)
    }

    setData({
      courseName: courseData.name ? courseData.name : '',
      shortDescription: courseData.shortDescription
        ? courseData.shortDescription
        : '',
      fullDescription: courseData.fullDescription
        ? courseData.fullDescription
        : '',
      price: courseData.price ? courseData.price : '',
      availableDay: courseData.availableDay ? courseData.availableDay : '',
      level: courseData.level ? courseData.level : '',
      status: courseData.status ? courseData.status : '',
      bannerId: courseData.banner ? courseData.banner.url : null,
      attach: courseData.attach ? courseData.attach : { id: '', url: '' },
      topicId:
        courseData.topicList &&
        courseData.topicList[0] &&
        courseData.topicList[0].id
          ? courseData.topicList[0].id
          : null,
      videoCount: courseData.videoCount,
      videoDuration: courseData.videoDuration,
      practiceCount: courseData.practiceCount,
      hasCertificate: courseData.hasCertificate,
      hasSupport: courseData.hasSupport,
      formatTypes: courseData.formatTypes,
      topicOrderNumber: courseData.topicOrderNumber ? courseData.topicOrderNumber : 0,
    });
  };

  useEffect(() => {
    if (cousreId !== null && cousreId !== 'null') {
      setNewCourse(false)
      getCourseData();
    }else{
      setNewCourse(true)
    }
    //  getCourseData();
  }, []);

  const [upload, setUpload] = useState({
    fileList: [
      {
        thumbUrl: data?.attach?.url,
      },
    ],
  });

  // TOPIC
  const [topics, setTopics] = useState([]);
  const [topicId, setTopicId] = useState('');

  const topicItems = [];
  topics?.map((item) => {
    topicItems.push(<Option key={item.id}>{item.name}</Option>);
  });

  const getTopics = async () => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/topic`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTopics(data);
  };

  // BANNERS
  const [banners, setBanners] = useState([]);
  const [bannerId, setBannerId] = useState('');
  const bannerItems = [];

  const getBanners = async () => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/course-banner`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setBanners(data.data);
  };
  banners?.map((item) => {
    bannerItems.push(
      <Option key={item.id}>
        <img
          style={{ height: 50 }}
          src={item.banner.url || data?.banner?.url}
          alt=''
        />
      </Option>
    );
    return '';
  });

  // UPDATE
  const updateItem = async () => {
    try {
      const obj = {
        name: data.courseName,
        shortDescription: data.shortDescription,
        fullDescription: data.fullDescription,
        price: data.price,
        availableDay: data.availableDay,
        rating: data?.rating,
        level: data.level,
        bannerId: data.bannerId?.substring(43),
        status: data.status,
        topicList: [
          {
            id: data.topicId,
          },
        ],
        videoCount: data.videoCount,
        videoDuration: data.videoDuration,
        practiceCount: data.practiceCount,
        hasCertificate: data.hasCertificate,
        hasSupport: data.hasSupport,
        formatTypes: selectedVideoFormatList,
        topicOrderNumber: data.topicOrderNumber,
      };

      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(obj),
      };
      const res = await fetch(
        `${BASE_URL}/api/v1/course/${cousreId}`,
        requestOptions
      );
      navigate('/mentor/course');
      // window.location.reload();
    } catch (err) {}
  };

  // CREATE
  const createItem = async () => {

    try {
      const obj = {
        name: data.courseName,
        shortDescription: data.shortDescription,
        fullDescription: data.fullDescription,
        price: data.price,
        availableDay: data.availableDay,
        rating: data?.rating,
        level: data.level,
        bannerId: bannerId.substring(43),
        status: data.status,
        topicList: [
          {
            id: data.topicId,
          },
        ],
        formatTypes: selectedVideoFormatList,
        topicOrderNumber: data.topicOrderNumber,
      };

      const {} = await axios.post(`${BASE_URL}/api/v1/course`, obj, {
        headers: { Authorization: `Bearer ${token}` },
      });
      navigate('/mentor/course');
      window.location.reload();
    } catch (err) {}
  };

  // DELETE
  const deleteItem = async (id) => {
    const { data } = await axios.delete(`${BASE_URL}/api/v1/course/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    navigate('/mentor/course');
    window.location.reload();
  };

  const videoFormatSelectProps = {
    mode: 'multiple',
    value:selectedVideoFormatList,
    style: { width: '100%' },
    options: [{label: 'SD',  value: 'SD'},{label: 'HD',  value: 'HD'},{label: 'FHD',  value: 'FHD'},{label: 'BD',  value: 'BD'},{label: '2K',  value: '2K'}],
    onChange: (newValue) => {
      setSelectedVideoFormatList(newValue);
    },
    placeholder: 'Select Item...',
    maxTagCount: 'responsive',
  };

  useEffect(() => {
    getTopics();
    getBanners();
  }, []);

  return (
    <>
      {data && (
        <div className='admin-course-detail-wrapper'>
          <Link to='/mentor/course'>
            <button className='btn btn-secondary'>
              <i className='fa-solid fa-arrow-left'></i> Back
            </button>
          </Link>
          <div className='name-input'>
            <label htmlFor='name'>Name</label>
            <Input
              id='name'
              value={data.courseName}
              onChange={(e) => setData({ ...data, courseName: e.target.value })}
            />
          </div>
          <div className='short-description'>
            <label htmlFor='shortDesc'>Short Description</label>
            <Input.TextArea
              id='shortDesc'
              value={data.shortDescription}
              onChange={(e) =>
                setData({ ...data, shortDescription: e.target.value })
              }
            />
          </div>
          <div className='large-description'>
            <label htmlFor='largeDesc'>Full Description Description</label>
            <Input.TextArea
              rows={8}
              id='largeDesc'
              value={data.fullDescription}
              onChange={(e) =>
                setData({ ...data, fullDescription: e.target.value })
              }
            />
          </div>
          <div className='prices-inputs d-flex gap-3 w-100'>
            <div className='w-100'>
              <label htmlFor='price'>Price</label>
              <Input
                id='price'
                type='number'
                value={data.price}
                onChange={(e) => setData({ ...data, price: e.target.value })}
              />
            </div>
            <div className='w-100'>
              <label htmlFor='available'>Available days</label>
              <Input
                type='number'
                value={data.availableDay}
                onChange={(e) =>
                  setData({ ...data, availableDay: e.target.value })
                }
                id='available'
              />
            </div>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='price'>Level</label>
              <Select
                id='level'
                value={data.level}
                style={{
                  width: '100%',
                }}
                onChange={(e) => setData({ ...data, level: e })}
                options={[
                  {
                    value: 'BEGINNER',
                    label: 'BEGINNER',
                  },
                  {
                    value: 'INTERMEDIATE',
                    label: 'INTERMEDIATE',
                  },
                  {
                    value: 'EXPORT',
                    label: 'EXPORT',
                  },
                ]}
              />
            </div>
          </div>
          <div className='d-flex gap-3'>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='banner'>Banner</label>
              <Select
                id='banner'
                value={data.bannerId}
                style={{
                  width: '100%',
                }}
                onChange={(e, o) => setBannerId(o.children.props.src)}
              >
                {bannerItems}
              </Select>
            </div>
            {/* <div className="w-100 d-flex flex-column">
      <label htmlFor="price">Upload Image</label>
      <Upload onChange={handleChange} multiple={false} listType="picture">
        <Button>Select Image</Button>
      </Upload>
    </div> */}
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='status'>Status</label>
              <Select
                id='status'
                value={data.status}
                style={{
                  width: '100%',
                }}
                onChange={(e) => setData({ ...data, status: e })}
                options={[
                  {
                    value: 'PUBLISHED',
                    label: 'PUBLISHED',
                  },
                  {
                    value: 'NOT_PUBLISHED',
                    label: 'NOT_PUBLISHED',
                  },
                ]}
              />
            </div>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='topic'>Topic</label>
              <Select
                id='topic'
                value={data.topicId}
                onChange={(e, v) => {
                  setData({ ...data, topicId: v.value })
                }}
                style={{
                  width: '100%',
                }}
              >
                {topicItems}
              </Select>
            </div>
          </div>
          <div className='d-flex justify-content-between gap-3'>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='practiceCount'>Practice Count</label>
              <InputNumber
                min={1}
                value={data.practiceCount}
                id='practiceCount'
                style={{
                  width: '100%',
                }}
                onChange={(e) => setData({ ...data, practiceCount: e })}
              />
            </div>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='videoCount'>Video Count</label>
              <InputNumber
                min={1}
                value={data.videoCount}
                id='videoCount'
                style={{
                  width: '100%',
                }}
                onChange={(e) => setData({ ...data, videoCount: e })}
              />
            </div>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='videoDuration'>Video duration</label>
              <InputNumber
                min={1}
                value={data.videoDuration}
                id='videoDuration'
                style={{
                  width: '100%',
                }}
                onChange={(e) => setData({ ...data, videoDuration: e })}
              />
            </div>
          </div>
          <div className='d-flex justify-content-between gap-3'>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='videoDuration'>Video Format</label>
               <Select {...videoFormatSelectProps} />
            </div>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='hasCertificate'> Has certificate</label>
              <Radio.Group
                value={data.hasCertificate}
                onChange={(e) =>
                  setData({ ...data, hasCertificate: e.target.value })
                }
                id='hasCertificate'
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
            <div className='w-100 d-flex flex-column'>
              <label htmlFor='hasSupport'>Has support</label>
              <Radio.Group
                value={data.hasSupport}
                onChange={(e) => {
                  setData({ ...data, hasSupport: e.target.value });
                }}
                id='hasSupport'
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
            <div className='w-100'>
              <label htmlFor='topicOrderNumber'>TopicOrderNumber</label>
              <Input id='topicOrderNumber' type='number' value={data.topicOrderNumber}
                     onChange={(e) => setData({ ...data, topicOrderNumber: e.target.value })}/>
            </div>
          </div>
          <div className='d-flex justify-content-between gap-3'>

          </div>
          <div className='buttons d-flex gap-3 justify-content-between w-100 mt-3'>
            {data ? (
              <div>
                <p>Mentor</p>
                {data?.mentorList?.map((mentor) => (
                  <div
                    key={mentor.name}
                    className='d-flex align-items-center gap-2'
                  >
                    <img
                      style={{ height: 40, width: 40, borderRadius: '50%' }}
                      src='https://www.w3schools.com/howto/img_avatar.png'
                      alt=''
                    />
                    <p className='mt-2'>
                      {mentor.name} {mentor.surname}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
            <div className='w-100'>
              {isNewCourse ? (
                <div
                  style={{ width: '100%' }}
                  className='d-flex align-items-center justify-content-end w-100 gap-2'
                >
                  <button
                    onClick={createItem}
                    className='btn btn-success d-flex align-items-center gap-2'
                  >
                    Create <PlusCircleOutlined />
                  </button>
                </div>
              ) : (
                  <div className='d-flex align-items-center justify-content-end w-100 gap-2'>
                    <button
                        onClick={() => deleteItem(data?.id)}
                        className='btn btn-danger d-flex align-items-center gap-2'
                    >
                      Delete <i className='fa-solid fa-trash'></i>
                    </button>
                    <button
                        onClick={() => {
                          updateItem(data?.id);
                        }}
                        className='btn btn-warning d-flex align-items-center gap-2'
                    >
                      Update <EditOutlined />
                    </button>
                  </div>
              ) }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetail;
