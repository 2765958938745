import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./index.scss";
import App from "./App";
import {AuthProvider} from "./context/auth-provider";
import {UserProvider} from "./context/user-provider";
import ShowMessageModal from "./components/modal/ShowMessageModal";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <AuthProvider>
            <UserProvider>
                <Routes>
                    <Route path="/*" element={<App/>}/>
                </Routes>
                <ShowMessageModal/>
            </UserProvider>
        </AuthProvider>
    </BrowserRouter>
);

// When the user clicks anywhere outside of the modal, close it
window.onclick = function (event) {
    // let modal = document.getElementById("messageModalId");
    // if (modal && modal !== null && event.target === modal) {
    //     modal.style.display = "none";
    // }
}