import React from 'react';
import {Footer, Header} from '../../components';
import './about-us.scss';

const AboutUs = () => {

    return (<div className='about-us'>
        <Header/>
        <div className="main container-xxl">
            <h5 className='h3 pt-4 pt-xl-0'>Biz haqimizda</h5>
            <div className="about-us-cards row">
                <div className="col-12 col-md-6 p-3 col-xl-4 p-3">
                    <div className="about-us-card p-3 h-100">
                        <p className='card__title h4 text-center'>Loyiha</p>
                        <p className='card__info'>Loyiha ancha yillar oldin reja qilingan bu proyekt hayolda ya'ni
                            birdan evrika holatida paydo bo'lib hozirda qadamma qadam dunyoni ko'rmoqda. Loyiha
                            bo'yicha kelajakda niyatlarimiz juda ko'p va bu loyihalar bo'yicha hozirda ish qizg'in
                            davom etmoqda.</p>
                        <p className='card__info'>Loyiha ancha yillar oldin reja qilingan bu proyekt hayolda ya'ni
                            birdan evrika holatida paydo bo'lib hozirda qadamma qadam dunyoni ko'rmoqda. Loyiha
                            bo'yicha kelajakda niyatlarimiz juda ko'p va bu loyihalar bo'yicha hozirda ish qizg'in
                            davom etmoqda.</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 p-3">
                    <div className="about-us-card p-3 h-100">
                        <p className='card__title h4 text-center'>Asosiy maqsadimiz</p>
                        <p className='card__info'>Loyihamizning asosiy maqsadi O'zbek tilida dasturlashga oid bepul
                            ma'lumotlarni va o'quv qo'llanmalarni ko'paytirish. Shu bilan birga taqdim etilgan
                            ma'lumotlar sifati va tushunishga oson bo'lishini ta'minlash. Bundan tashqari O'zbek
                            tilidagi bepul video darsliklar to'plamini bosqichma boshqich taqdim qilib borish.</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 p-3">
                    <div className="about-us-card p-3 h-100">
                        <p className='card__title h4 text-center'>Manbalar</p>
                        <p className='card__info'>Barcha videolarni tayyorlab bepul ulashayotgan
                            barcha qo'li ochiq insonlarga va dasturchilar shu bilan birgalikda O'qituvchilarga
                            kattadan katta rahmat. Alloh sizlardan rozi bo'lsin bunday qilinayotgan sovobli
                            ishlaringgiz Vatanimiz farzandlarini yetuk mutaxassis bo'lib yetishishida ko'makchi
                            vazifasini bajaradi degan umiddamiz.</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 p-3">
                    <div className="about-us-card p-3 h-100">
                        <p className='card__title h4 text-center'>Loyihani qo'llab quvvatlash(Donate)</p>
                        <p className='card__info'>Loyihani qo'llab quvvatlash uchun yig'ilgan mablag'ning barchasi
                            yangi darsliklar yozishga ishlatiladi va har bir sarf qilingan tiyin uchun javob
                            beramiz. UZCARD: 8600 3029 1087 3204</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 p-3">
                    <div className="about-us-card p-3 h-100">
                        <p className='card__title h4 text-center'>Xatoliklar</p>
                        <p className='card__info'> Jigarlar platformada hatoliklar juda tiqilib yotibdi.
                            Ularni barchasini fixe qilamiz deguncha yilni oxirib o'lib qolarkan. Shu sababdan ularni
                            bosqichma bosqish to'girlab asosiy vaqtni zo'r video darsliklar chiqarishga sarflaymiz.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4 p-3">
                    <div className="about-us-card p-3 h-100">
                        <p className='card__title h4 text-center'>Aloqa</p>
                        <p className='card__info'>Agar sizda takliflar bo'lsa <a
                            href="https://t.me/Jumaniyazov_Bobur" target={"_blank"}>Telegramda Menga yozing. @Jumaniyazov_Bobur</a></p>
                    </div>
                </div>

            </div>
        </div>
        <Footer/>
    </div>);
}

export default AboutUs;
