import React, { useContext } from "react";
import { Tree, Modal } from "antd";
import Summary from "../summary/summary";
import ReactPlayer from "react-player";
import { BASE_URL } from "../../../../api/base-url";
import {
  LockFilled,
  PlayCircleFilled,
  ThunderboltFilled,
  LaptopOutlined,
} from "@ant-design/icons";
import AboutTeacher from "../about-teacher/aboutTeacher";
import "./tableOfContent.scss";
import axios from "axios";
import { useState } from "react";

const TableOfContent = ({ data, checkData }) => {
  const token = localStorage.getItem("token");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [attentionModal, setAttentionModal] = useState(false);
  const [video, setVideo] = useState("");

  const showModal = () => {
    setIsOpenModal(true);
  };
  const handleOk = () => {
    setIsOpenModal(false);
  };
  const handleCancel = () => {
    setIsOpenModal(false);
  };

  const showAttentionModal = () => {
    setAttentionModal(true);
  };
  const handleAttentionModalOk = () => {
    setAttentionModal(false);
  };
  const handleAttentionModalCancel = () => {
    setAttentionModal(false);
  };

  const watchVideo = async (id) => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/course-lesson/public/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setVideo(data?.data);
  };

  const getItemIcon = (item) => {
    if (item?.access_type === "PUBLIC") {
      return (
        <PlayCircleFilled
          style={{
            padding: 4,
            borderRadius: "50%",
            backgroundColor: "#6c6f80",
            color: "#fff",
          }}
        />
      );
    } else if (item?.type === "EXERCISE") {
      return (
        <LaptopOutlined
          style={{
            padding: 4,
            borderRadius: "50%",
            backgroundColor: "#6c6f80",
            color: "#fff",
          }}
        />
      );
      //  <span style={{
      //             padding: 4,
      //             borderRadius: '50%',
      //             backgroundColor: '#6c6f80',
      //             color: '#fff'}}>
      //     <i className="bi bi-keyboard-fill"></i>
      // </span>
    } else {
      return (
        <LockFilled
          style={{
            padding: 4,
            borderRadius: "50%",
            backgroundColor: "#6c6f80",
            color: "#fff",
          }}
        />
      );
    }
  };

  return (
    <div className="table-wrapper">
      <Modal
        open={attentionModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleAttentionModalOk}
        onCancel={handleAttentionModalCancel}
      >
        <div
          style={{
            textAlign: "center",
            padding: "30px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>Iltimos videoni ko'rishdan oldin ro'yxatdan o'ting!</h2>
        </div>
      </Modal>

      <Modal
        open={isOpenModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        centered
        footer={null}
        destroyOnClose={true}
      >
        <ReactPlayer
          controls={true}
          width="100%"
          height={500}
          style={{ padding: "10px" }}
          url={
            video?.type === "VIDEO_LINK"
              ? `${video?.videoId}`
              : `https://api.videohab.uz/attach/stream/${video?.videoId}`
          }
        />
      </Modal>
      <div className="table-of-content">
        <div className="table-of-content-wrap">
          <div className="block-wrapper w-100 p-3">
            <h4 className="text-black">Kurs Mundarijasi</h4>
            <div className="course-section-list-tree mt-3">
              {data?.courseSectionList?.map((item, idx) => {
                const lessonJson = JSON.parse(
                  item?.lessonJson ? item?.lessonJson : null
                );
                let childName = [];
                lessonJson?.map((item, idx) => {
                  const obj = {
                    title: (
                      <div
                        style={{
                          pointerEvents:
                            item?.access_type === "PUBLIC" ? "all" : "none",
                        }}
                        onClick={() => {
                          if (token) {
                            watchVideo(item?.id);
                            showModal();
                          } else {
                            showAttentionModal(true);
                          }
                        }}
                        className=" title child gap-2 m-1 d-flex align-items-start"
                      >
                        {getItemIcon(item)}
                        <p className="header-tree">
                          {idx + 1}. {item?.name}
                        </p>
                      </div>
                    ),
                    id: item.id,
                  };

                  childName.push(obj);
                });

                return (
                  <Tree
                    showLine
                    key={item.orderNumber}
                    switcherIcon={
                      <LockFilled
                        style={{
                          padding: 5,
                          borderRadius: "50%",
                          backgroundColor: "#6c6f80",
                          color: "#fff",
                        }}
                      />
                    }
                    expandedKeys={[item.id]}
                    treeData={[
                      {
                        title: () => (
                          <div className=" title d-flex align-items-start">
                            <p className="header-tree ss">
                              {idx + 1}. {item?.name}
                            </p>
                          </div>
                        ),
                        key: item.id,
                        children: childName,
                      },
                    ]}
                  />
                );
              })}
            </div>
          </div>
          <div className="d-flex flex-column w-100">
            <Summary data={data} />
            <div className="mt-4">
              <AboutTeacher data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableOfContent;
