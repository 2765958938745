import React, {useContext, useState} from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../../context/auth-provider';
import {BASE_URL} from '../../api/base-url';
import './RegistrationVerificationPage.scss';
import {Header} from '../../components';


const RegistrationVerificationPage = () => {
    const [value, setValue] = useState(null);
    const {registerPhone} = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const { setToken, setLoginPhone } = useContext(AuthContext);


    const onSubmit = (e) => {
        e.preventDefault();
        const header = {
            headers: {'Content-Type': 'application/json'},
        };
        axios
            .post(
                `${BASE_URL}/api/v1/auth/client/registration/verification`,
                {
                    code: value,
                    phone: registerPhone,
                },
                header
            )
            .then((item) => {
                if (!item?.data.isError) {
                    const userData = item?.data?.data;

                    const fullName = {
                        name: userData.name,
                        surname: userData.surname,
                        photo: userData.photo,
                    };

                    localStorage.setItem("fullName", JSON.stringify(fullName));
                    localStorage.setItem("userData", JSON.stringify(userData));
                    localStorage.setItem("roleList", JSON.stringify(userData.roleList));
                    localStorage.setItem("token", userData?.jwt);
                    setToken(userData?.jwt);
                    setLoginPhone(registerPhone);

                    //window.location.href = "/";
                    navigate("/");
                    window.location.reload();

                   /* navigate('/');
                    localStorage.setItem('registerUserData', JSON.stringify(item));
                    window.location.reload();
                    const fullName = {
                        name: item?.data?.data?.name,
                        surname: item?.data?.data?.surname,
                    };

                    localStorage.setItem('fullName', JSON.stringify(fullName));
                    localStorage.setItem('token', item?.data?.data?.jwt);*/
                } else {
                    setError(item.data.message);
                    localStorage.removeItem('registerPhoneNumber');
                }
            })
            .catch((eror) => {
            });
    };

    return (
        <>
            <Header/>
            <div className='verification-wrapper'>
                <div className='details-wrap bg-light text-center'>
                    <h3>Sms kodni Tasdiqlash</h3>
                    <p>{registerPhone} raqamiga sms kod yuborildi</p>
                    <form onSubmit={onSubmit} className='verification-input'>
                        <ReactInputVerificationCode
                            autoFocus
                            length={5}
                            placeholder=''
                            onChange={(e) => setValue(e)}
                        />
                        <button type='submit' className='mt-5 confirm-btn'>
                            Tasdiqlash
                        </button>
                        <p className="mt-2 font-size-18">
                            {" "}
                            Sms kelmasa
                            <a href="https://t.me/videohubsms_bot" target="_blank">
                                {" "}
                                Videohub SMS bot-ga
                            </a>{" "}
                            <br/> ulanib u orqali Sms larni qabul qiling.
                            <br/> Bazan mazgi sms provider ishlamay qoladi.
                        </p>
                        <p className='text-danger mt-3'>{error}</p>
                    </form>
                </div>
            </div>
        </>
    );
};

export default RegistrationVerificationPage;
