import React, {useRef, useState} from 'react';

import axios from 'axios';

import {BASE_URL} from '../../api/base-url';
import ChatSendAttachModal from './ChatSendAttachModal';

function SimpleChatInput({selectedCourseLessonId, clientId, profileType, setSendMessageDTO}) {
    const inputRef = useRef();
    const token = localStorage.getItem('token')

    const [sendingFile, setSendingFile] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    /** Send message as client */
    const sendMessageAsClient = async (fileId) => {
        const {data} = await axios.post(
            `${BASE_URL}/api/v1/simple_message/client`,
            {
                message: inputRef.current.value || '',
                attachId: fileId,
                courseLessonId: selectedCourseLessonId,
            },
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        if (!fileId) {
            inputRef.current.value = '';
        }
        setSendMessageDTO(data);
    };
    /** Send message as support */
    const sendMessageAsSupport = async (fileId) => {
        const {data} = await axios.post(
            `${BASE_URL}/api/v1/simple_message/support/${clientId}`,
            {
                message: inputRef.current.value || '',
                attachId: fileId,
                courseLessonId: selectedCourseLessonId,
            },
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        if (!fileId) {
            inputRef.current.value = '';
        }
        setSendMessageDTO(data);
    };
    /** Handler */
    async function handleChatMessage(fileId = null) {
        if (!inputRef.current.value && !fileId) {
            return;
        }
        if (profileType === 'SUPPORT') {
            sendMessageAsSupport(fileId);
        } else if (profileType === 'CLIENT') {
            sendMessageAsClient(fileId)
        }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            handleChatMessage();
        }
    }

    const handleSendingFile = (e) => {
        setSendingFile({
            url: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        });
        console.log('handleSendingFile keldi')
        setIsModalOpen(true);
        e.target.value = '';
    };

    async function sendFile() {
        let formData = new FormData();
        formData.append('file', sendingFile.data);
        const {data} = await axios.post(
            `${BASE_URL}/api/v1/attach/upload`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        handleChatMessage(data?.data?.id);
    }

    return (
        <div className='chat-input'>
            <label className='custom-file-upload'>
                <input type='file' onChange={handleSendingFile}/>
                <i className='fa-solid fa-paperclip custom-file-upload__icon'></i>
            </label>
            <input
                ref={inputRef}
                placeholder='Write a message...'
                type='text'
                className='chat-input__input'
                onKeyDown={handleKeyDown}
            />
            <span className='send-icon' onClick={() => handleChatMessage()}>
        <i className='fa-solid fa-paper-plane'></i>
      </span>
            {isModalOpen && (
                <ChatSendAttachModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    file={sendingFile}
                    sendFile={sendFile}
                />
            )}
        </div>
    );
}

export default SimpleChatInput;
