/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../api/base-url";
// import ReactPlayer from 'react-player';
import { useLocation } from "react-router-dom";
import { Space, Spin, Tabs } from "antd";
import axios from "axios";
import { Header } from "../../components";
import ClientCourseNavigationTreeComponent from "../../components/client-course-navigation-tree-component/client-course-navigation-tree-component";
import "./client-course-page.scss";
import CourseLessonMaterials from "../course-lesson-materials/course-lesson-materilas";
import JolVideoComponent from "../../components/jolPlayerComponent/jolVideoComponent";
import { toast, ToastContainer } from "react-toastify";
import SimpleChat from "../../components/simple-chat/SimpleChat";
import axiosInstance from "../../api/intercepter";

const ClientCoursePage = () => {
  const { state } = useLocation();

  const token = localStorage.getItem("token");
  const [courseId, setCourseId] = useState(state.id);

  useState(null);
  const [courseData, setCourseData] = useState(null);
  const [clientCourse, setClientCourse] = useState(null);
  const [courseStartedTrigger, setCourseStartedTrigger] = useState(null);
  //
  const [courseLessonId, setCourseLessonId] = useState(null);
  const [currentCourseLessonFinished, currentLessonFinishedTrigger] =
    useState(null);

  const [showCourseFinishButton, setShowCourseFinishButton] = useState(null);
  const [courseFinished, setCourseFinished] = useState(null);
  const [allLessonsFinished, setAllLessonsFinished] = useState(null);
  //
  const [isUser, setIsUser] = useState(false);
  const [open, setOpen] = useState(true);
  const [nothing, setNothing] = useState(false);

  const [loading, setLoading] = useState(false);
  const [courseSectionList, setCourseSectionList] = useState([]);

  const [videoData, setVideoData] = useState({});

  const [showToasterMessage, setShowToasterMessage] = useState(null);

  // get course detail
  const getCourseAsJoinedClient = async (courseId) => {
    const { data } = await axiosInstance.get(
      `${BASE_URL}/api/v1/course/client/tree/joined/${courseId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setCourseData(data.data);
    setClientCourse(data.data.clientCourse);
    setCourseSectionList(data?.data?.courseSectionList);
  };

  useEffect(() => {
    setCourseId(state.id); // todo not working
    getCourseAsJoinedClient(state.id);
  }, []);

  useEffect(() => {
    // courseLesson finished trigger

    getCourseAsJoinedClient(courseId);
  }, [currentCourseLessonFinished]);

  useEffect(() => {
    // course finished trigger
    setClientCourse({ ...clientCourse, progressStatus: "FINISHED" });
  }, [courseFinished]);

  const onChange = (key) => {
    // tab change
  };

  const startCourse = async () => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/client-curse/start/${courseId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!data.isError) {
      // success  TODO
      setClientCourse({ ...clientCourse, progressStatus: "STARTED" });
      setCourseStartedTrigger(true); // for opening first lesson of first section
    }
    // setIsUser(true);
  };

  useEffect(() => {
    if (state?.courseDetail?.data?.progressStatus !== "DEF") {
      setIsUser(true);
    }
  }, []);

  const notify = (message) => toast(message);

  useEffect(() => {
    if (showToasterMessage) {
      notify(showToasterMessage);
      setShowToasterMessage(null);
    }
  }, [showToasterMessage]);

  const items = [
    {
      key: "1",
      label: (
        <div className="tab-item">
          <i className="fa-solid fa-desktop"></i>
          <p>Materiallar</p>
        </div>
      ),
      children: (
        <CourseLessonMaterials
          courseLessonId={courseLessonId}
          courseId={courseId}
          currentLessonFinishedTrigger={currentLessonFinishedTrigger}
          setCourseFinished={setCourseFinished}
          clientCourse={clientCourse}
          allLessonsFinished={allLessonsFinished}
          content={videoData?.content}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className="tab-item">
          <i className="fa-solid fa-paper-plane"></i>
          <p>Chat</p>
        </div>
      ),
      children: (
        <SimpleChat
          profileType={"CLIENT"}
          courseLessonId={courseLessonId}
          clientId={null}
        ></SimpleChat>
      ),
    },
  ];

  const getVideoPlayer = () => {
    // console.log("keldi playerga: ", videoData)
    if (videoData?.videoId === undefined) {
      return;
    }
    if (videoData.type === "VIDEO" || videoData.type === "VIDEO_LINK") {
      return (
        <JolVideoComponent
          videoId={videoData.videoId}
          videoType={videoData.type}
          videoFormatTypeList={courseData.formatTypes}
        ></JolVideoComponent>
      );
    } else if (videoData.type === "VIDEO_IFRAME") {
      return (
        <div className="app_player_wrapper">
          <iframe
            src={`https://muse.ai/embed/${videoData.videoId}?search=0&links=0&logo=0&title=0&cover_play_position=center`}
            allowFullScreen
            title={`video frame ${videoData.videoId}`}
          ></iframe>
        </div>
      );
    } else if (videoData.type === "VIDEO_VIMO") {
      return (
        <div className="vimo_player_wrapper">
          <iframe
            src={`https://player.vimeo.com/video/${videoData.videoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            className="vimo_iframe"
            title="Videohub.uz"
          ></iframe>
        </div>
      );
    }
  };

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="video-wrapper">
      <Header />
      <div className="video-page-wrap d-flex">
        <ClientCourseNavigationTreeComponent
          courseName={courseData?.name}
          open={open}
          setOpen={setOpen}
          courseId={state?.course?.id}
          courseLessonId={courseLessonId}
          setCourseLessonId={setCourseLessonId}
          courseSectionList={courseSectionList}
          setVideoData={setVideoData}
          clientCourse={clientCourse}
          setAllLessonsFinished={setAllLessonsFinished}
          setShowToasterMessage={setShowToasterMessage}
        />
        <div
          className={`video-player-wrapper ${
            open ? "video-player-wrapper__open" : ""
          }`}
        >
          {clientCourse?.progressStatus === "DEF" ? (
            <div className="course-introduction">
              <h1>{courseData?.name}</h1>
              <button
                onClick={startCourse}
                style={{ color: "#fff" }}
                className="caret"
              >
                Kursni boshlash
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          ) : (
            <div className="video-wrap">
              {!open && (
                <button className="chev-btn" onClick={() => setOpen(!open)}>
                  <i className="fa-solid fa-caret-right"></i>
                </button>
              )}
              <div className="video-section">
                {/* style={{ maxHeight: "80vh" }} */}
                {getVideoPlayer()}
              </div>
              <div className="video-description">
                <div className="description">
                  <div className="course-material-controller">
                    {loading ? (
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          width: "70vw",
                          height: "40vh",
                        }}
                      >
                        <Space>
                          <Spin size="large" />
                        </Space>
                      </div>
                    ) : (
                      <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={onChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ClientCoursePage;
