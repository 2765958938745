import React, { useContext, useEffect, useState } from "react";
import { Input, Select } from "antd";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import axios from "axios";
import { BASE_URL } from "../../../../../../api/base-url";
import draftToHtml from "draftjs-to-html";
// import {Editor} from "react-draft-wysiwyg";
import "./course-lesson.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../../../context/auth-provider";

const CourseLesson = ({
  courseId,
  courseSectionId,
  courseLessonId,
  setIsShowUpdate,
  setIsReloadPage,
}) => {
  // const navigate = useNavigate();
  // let location = useLocation();
  //   const { courseId, lessonId } = location.state;
  //   const { courseSectionId, courseLessonId } = useParams();

  const [courseLessonData, setCourseLessonData] = useState({});

  const [inputValues, setInputValues] = useState({});

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(
          courseLessonData?.content ? courseLessonData?.content : ""
        )
      )
    )
  );

  const [contentState, setContentState] = useState("");
  const token = localStorage.getItem("token");

  const handleContentStateChange = (contentState) => {
    setContentState(draftToHtml(contentState));
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const createLesson = async () => {
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/course-lesson/mentor`,
      {
        name: inputValues.name,
        courseSectionId: courseSectionId,
        content: inputValues.content ? inputValues.content : "",
        orderNumber: inputValues.orderNumber,
        type: inputValues.type,
        status: inputValues.status,
        videoId: inputValues.videoId,
        accessType: inputValues.accessType,
        videoDuration: inputValues.videoDuration,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setIsReloadPage((prev) => !prev);
    setIsShowUpdate(false);
  };

  const updateLesson = async () => {
    const { data } = await axios.put(
      `${BASE_URL}/api/v1/course-lesson/mentor/${courseLessonId}`,
      {
        name: inputValues.name,
        courseSectionId: courseSectionId,
        content: inputValues.content,
        orderNumber: inputValues.orderNumber,
        type: inputValues.type,
        status: inputValues.status,
        videoId: inputValues.videoId,
        accessType: inputValues.accessType,
        videoDuration: inputValues.videoDuration,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setIsReloadPage((prev) => !prev);
    setIsShowUpdate(false);
  };

  const deleteLesson = async () => {
    const { data } = await axios.delete(
      `${BASE_URL}/api/v1/course-lesson/mentor/${courseLessonId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setIsReloadPage((prev) => !prev);
    setIsShowUpdate(false);
  };

  const getCourseLessonById = async (courseLessonId) => {
    const { data } = await axios.get(
      `${BASE_URL}/api/v1/course-lesson/mentor/${courseLessonId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (!data.isError) {
      setCourseLessonData(data.data);
      const cld = data.data;
      setInputValues({
        name: cld?.name ? cld?.name : "",
        status: cld?.status ? cld?.status : "",
        type: cld?.type ? cld?.type : "",
        orderNumber: cld?.orderNumber ? cld?.orderNumber : "",
        videoId: cld?.videoId ? cld?.videoId : "",
        accessType: cld?.accessType ? cld?.accessType : "PRIVATE",
        videoDuration: cld?.videoDuration,
        content: cld?.content,
      });
    }
  };

  useEffect(() => {
    if (courseLessonId !== null && courseLessonId !== "0") {
      getCourseLessonById(courseLessonId);
    }else{
      setInputValues({})
    }
  }, [courseId, courseSectionId, courseLessonId]);

  return (
    <div className="course-lesson-wrapper">
      <div className="course-wrapper-inputs">
        <div>
          <span
            onClick={() =>
              setIsShowUpdate(false)
            }
          >
            <button className="btn btn-sm btn-secondary">
             Close <i className="fa-regular fa-circle-xmark"></i>
            </button>
          </span>
        </div>
        <div className="d-flex align-items-center gap-3 mt-3">
          <div className="w-100">
            <label htmlFor="name">Name</label>
            <Input
              value={inputValues.name}
              onChange={(e) =>
                setInputValues({ ...inputValues, name: e.target.value })
              }
              id="name"
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 mt-3">
          <div className="w-100">
            <label htmlFor="status">Status</label>
            <Select
                id="status"
                value={inputValues.status}
                style={{
                  width: "100%",
                }}
                onChange={(e) => setInputValues({ ...inputValues, status: e })}
                defaultValue={"PUBLISHED"}
                options={[
                  {
                    value: "PUBLISHED",
                    label: "PUBLISHED",
                  },
                  {
                    value: "NOT_PUBLISHED",
                    label: "NOT_PUBLISHED",
                  },
                ]}
            />
          </div>
          <div className="w-100">
            <label htmlFor="order">Order</label>
            <Input
                value={inputValues.orderNumber}
                onChange={(e) =>
                    (e.target.value >= 1 || e.target.value === "") &&
                    setInputValues({ ...inputValues, orderNumber: e.target.value })
                }
                id="order"
                type="number"
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 mt-3">
          <div className="w-100">
            <label htmlFor="type">Type</label>
            <Select
                id="type"
                value={inputValues.type}
                style={{
                  width: "100%",
                }}
                onChange={(e) => setInputValues({ ...inputValues, type: e })}
                defaultValue={"VIDEO_VIMO"}
                options={[
                  {
                    value: "VIDEO",
                    label: "VIDEO",
                  },
                  {
                    value: "VIDEO_LINK",
                    label: "VIDEO_LINK",
                  },
                  {
                    value: "VIDEO_IFRAME",
                    label: "VIDEO_IFRAME",
                  },
                  {
                    value: "VIDEO_VIMO",
                    label: "VIDEO_VIMO",
                  },
                  {
                    value: "TEST",
                    label: "TEST",
                  },
                  {
                    value: "EXERCISE",
                    label: "EXERCISE",
                  },
                  {
                    value: "RESOURCE",
                    label: "RESOURCE",
                  },
                ]}
            />
          </div>
          <div className="w-100">
            <label htmlFor="name">Video duration</label>
            <Input
                value={inputValues.videoDuration}
                onChange={(e) =>
                    setInputValues({
                      ...inputValues,
                      videoDuration: e.target.value,
                    })
                }
                id="videoDuration"
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 20 }}>
          <div style={{ width: 280, marginTop: 20 }}>
            <label htmlFor="order">Video Id</label>
            <Input
              value={inputValues.videoId}
              onChange={(e) =>
                setInputValues({ ...inputValues, videoId: e.target.value })
              }
              id="videoId"
              type="text"
            />
          </div>
          <div style={{ width: 280, marginTop: 20 }}>
            <label htmlFor="accessType">Access Type</label>
            <Select
              id="accessType"
              value={inputValues.accessType}
              style={{
                width: "100%",
              }}
              onChange={(e) =>
                setInputValues({ ...inputValues, accessType: e })
              }
              defaultValue={"PRIVATE"}
              options={[
                {
                  value: "PRIVATE",
                  label: "PRIVATE",
                },
                {
                  value: "PUBLIC",
                  label: "PUBLIC",
                },
              ]}
            />
          </div>
        </div>
        <div className="course-lesson-buttons">
          <div className="d-flex align-items-center justify-content-end w-100 gap-2">
            {courseLessonId === null || courseLessonId === "0" ? (
                <button
                    onClick={createLesson}
                    className="btn btn-sm btn-success d-flex align-items-center gap-2">Create <PlusCircleOutlined />
                </button>
            ) : (
                <>
                  <button
                      onClick={deleteLesson}
                      className="btn btn-sm btn-danger d-flex align-items-center gap-2">Delete <i className="fa-solid fa-trash"></i>
                  </button>
                  <button
                      onClick={updateLesson}
                      className="btn btn-sm btn-warning d-flex align-items-center gap-2">Update <EditOutlined />
                  </button>
                </>
            )}
          </div>
        </div>
        <div>
          <a href="https://www.tiny.cloud/docs/demo/full-featured/"
            target="_blank"> https://www.tiny.cloud/docs/demo/full-featured/
          </a>
        </div>
      </div>
      <div className="course-lesson-editor">
        <span>Content</span>
        <textarea className="simple-border"
          defaultValue={inputValues.content}
          rows={5}
          onChange={(e) =>
            setInputValues({ ...inputValues, content: e.target.value })}></textarea>
      </div>
    </div>
  );
};

export default CourseLesson;
