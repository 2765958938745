import {Routes, Route} from 'react-router-dom';
import AdminRequiredAuth from './pages/admin-required-auth/admin-required-auth';

import {
    Catalog,
    CourseDetail,
    Courses,
    LogIn,
    LoginVerification,
    RegisterVerification,
    Register,
    Video,
    AdminLogin,
    MyCourse,
    Payment,
} from './pages';
import {
    ClientCourse,
    Course,
    PublicPageDashboard,
    CourseSection,
    CourseLesson,
    CourseDetail as AdminCourseDetail,
    SupportMessage,
} from './pages/dashboard/pages/mentor-pages';
import {
    Main,
    Profile,
    CourseBanner,
    AdminUpdateDetail,
    Videos as AdminVideos,
} from './pages/dashboard/pages/admin-pages';

import AdminClient from "./pages/dashboard/pages/admin-pages/client/AdminClient";
import {Layout} from './components';
import './App.scss';
import MentorRequiredPages from './pages/mentor-required-auth/mentor-required-auth';
import {useContext, useEffect} from 'react';
import AuthContext from './context/auth-provider';
import AboutUs from "./pages/about-us/about-us";
import UserProfile from './pages/user-profile/UserProfile';
import SupportSimpleChatComponent
    from "./pages/dashboard/pages/mentor-pages/support-simple-chat/SupportSimpleChatComponent";
import SupportVideo from "./pages/dashboard/pages/mentor-pages/support-video/SupportVideo";
import AdminClientDetail from "./pages/dashboard/pages/admin-pages/client-deail/AdminClientDetail";
import AdminClientCourseDetail from "./pages/dashboard/pages/admin-pages/client-course-detail/AdminClientCourseDetail";
import ClientCoursePage from "./pages/client-course-page/clientCoursePage";
import MusePlayerTest from "./pages/video-test-page/MusePlayerTest";
import ClientLogInPage from "./pages/clint-login-page/ClientLogInPage";
import OtherVideoPlayerTest from "./pages/video-test-page/OtherVideoPlayerTest";
import AdminClientCourse from "./pages/dashboard/pages/admin-pages/client-course/AdminClientCourse";
import AdminTopic from "./pages/dashboard/pages/admin-pages/topic/AdminTopic";

function App() {
    const {register, login, admin} = useContext(AuthContext);
    useEffect(() => {
        if (login) {
            if (login.status !== 200) {
                localStorage.clear();
            }
        }
        if (register) {
            if (login.status !== 200) {
                localStorage.clear();
            }
        }
        if (admin) {
            if (login.status !== 200) {
                localStorage.clear();
            }
        }
    }, [login, register, admin]);

    return (
        <Routes>
            <Route path='/' element={<Layout/>}/>
            {/* Public Routes */}
            <Route path='login' element={<ClientLogInPage/>}/>
            <Route path='admin-login' element={<AdminLogin/>}/>
            <Route path='register' element={<Register/>}/>
            <Route path='login-verification' element={<LoginVerification/>}/>
            <Route path='register-verification' element={<RegisterVerification/>}/>
            <Route path='catalog' element={<Catalog/>}/>
            <Route path='my-courses' element={<MyCourse/>}/>
            <Route path='about-us' element={<AboutUs/>}/>
            <Route path='course-detail/:courseId' element={<CourseDetail/>}/>
            <Route path='course/:courseId/video' element={<ClientCoursePage/>}/>
            <Route path='profile' element={< UserProfile/>}/>
            <Route path='payment' element={<Payment/>}/>
            <Route path='muse-test' element={<MusePlayerTest/>}/>
            <Route path='video-player-test' element={<OtherVideoPlayerTest/>}/>

            {/* Admin pages */}
            <Route element={<AdminRequiredAuth/>}>
                <Route path='admin/main' element={<Main/>}/>
                <Route path='admin/client' element={<AdminClient/>}/>
                <Route path='admin/client-detail/:clientId' element={<AdminClientDetail/>}/>
                <Route path='admin/client-course-detail/:clientId/:courseId' element={<AdminClientCourseDetail/>}/>
                <Route path='admin/client-course' element={<AdminClientCourse/>}/>
                <Route path='admin/client' element={<ClientCourse/>}/>
                <Route path='admin/topic' element={<AdminTopic/>}/>
                <Route path='admin/course-banner' element={<CourseBanner/>}/>
                <Route path='admin/profile' element={<Profile/>}/>
                <Route path='admin/videos' element={<AdminVideos/>}/>
                <Route path='admin/update-detail' element={<AdminUpdateDetail/>}/>
            </Route>

            {/* Mentor pages */}
            <Route element={<MentorRequiredPages/>}>
                <Route path='mentor/course' element={<Course/>}/>
                <Route
                    path='mentor/course-detail/:cousreId'
                    element={<AdminCourseDetail/>}
                />
                <Route
                    path='mentor/course-section/:courseId'
                    element={<CourseSection/>}
                />
                <Route path='mentor/course-lesson/:courseSectionId/:courseLessonId' element={<CourseLesson/>}/>
                <Route path='mentor/videos' element={<SupportVideo/>}/>
                <Route path='/mentor/profile' element={<PublicPageDashboard/>}/>
                <Route path='/mentor/messages' element={<SupportMessage/>}/>
                <Route path='/mentor/simple-chat' element={<SupportSimpleChatComponent/>}/>
            </Route>

            {/* Catch all */}
            <Route path='*' element={<Courses/>}/>
        </Routes>
    );
}

export default App;
