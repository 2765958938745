import React from "react";
import { Header, Register as RegisterComponent } from "../../components";
import "./RegistrationPage.scss";

const RegistrationPage = () => {
  return (
    <>
      <Header />
      <div className="register-page">
        <RegisterComponent />
      </div>
    </>
  );
};

export default RegistrationPage;
