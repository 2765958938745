import React from 'react';
import './aboutTeacher.scss';

const AboutTeacher = ({ data }) => {
  return (
    <>
      <div className='summary-title summary-block-title d-flex align-items-center justify-content-between '>
        <h5 className='summary-block-title-text'>
          {data?.mentorList.length === 1 ? 'Mentor' : 'Mentorlar'}
        </h5>
      </div>
      {data?.mentorList.map((item, index) => {
        return (
          <div className='teacher-about bg-light' key={index}>
            <div className='teacher-image'>
              <img
                style={{ height: 80, width: 80, borderRadius: '50%' }}
                src={
                  item?.attach
                    ? item.attach.url
                    : 'https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236__340.png'
                }
                alt=''
              />
              <h4 className='teacher-name text-dark'>
                {item.name + ' ' + item.surname}
              </h4>
            </div>
            {/*<p className="text-dark">{data?.shortDescription}</p>*/}
          </div>
        );
      })}
    </>
  );
};

export default AboutTeacher;
