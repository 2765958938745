/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import {Popover} from "antd";
import "./navbar.scss";
import {useNavigate} from "react-router-dom";

const Navbar = ({setOpenSidebar, openSidebar}) => {
    const userName = JSON.parse(localStorage.getItem("fullName"));
    const navigate = useNavigate();

    const content = (
        <div>
            <p onClick={() => {
                localStorage.clear();
                navigate('/');
                window.location.reload();
            }} style={{color: "#35aed4", cursor: "pointer"}}>Log Out</p>
        </div>
    );

    return (
        <div className="navbar-wrap">
            <div className="d-flex btn-sm align-items-center gap-2">
                <span
                    onClick={() => setOpenSidebar(!openSidebar)}
                    className="btn-light on-click-hand"
                >
                    {openSidebar ? (
                        <i className="fa-solid fa-angle-left fa-2x"></i>
                    ) : (
                        <i className="fa-solid fa-angle-right fa-2x"></i>
                    )}
                </span>
                <p className="title">{localStorage.getItem("title")}</p>
            </div>
            <div className="user-data">
                <div className="icons">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <div className="notification">
                        <i className="fa-solid fa-bell"></i>
                        <span className="notificate"></span>
                    </div>
                </div>
                <Popover placement="bottomRight" content={content} trigger="click">
                    <div style={{cursor: "pointer", userSelect: "none"}} className="user-avatar">
                        <p className="mt-3">
                            {userName?.name} {userName?.surname}
                        </p>
                        <img
                            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).webp"
                            alt="photo"
                        />
                    </div>
                </Popover>
            </div>
        </div>
    );
};

export default Navbar;
