import React, { useContext } from "react";
import { Input, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./course.scss";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../../../api/base-url";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import AuthContext from "../../../../../context/auth-provider";

const Course = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);

  const getData = async () => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/course/mentor`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setData(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="course-wrapper">
        <div className="course-top-wrap d-flex align-items-center justify-content-between">
          <div className="inputs d-flex align-items-center gap-4">
            <div className="d-flex flex-column">
              <label className="mb-2" htmlFor="name">
                Name
              </label>
              <Input id="name" style={{ width: "max-content" }} />
            </div>
            <div className="d-flex flex-column">
              <label className="mb-2" htmlFor="surname">
                Surname
              </label>
              <Input id="surname" style={{ width: "max-content" }} />
            </div>
            <div className="d-flex flex-column">
              <label className="mb-2" htmlFor="level">
                Level
              </label>
              <Select
                id="level"
                defaultValue="lucy"
                style={{
                  width: 180,
                }}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <div className="d-flex flex-column">
              <label className="mb-2" htmlFor="status">
                Status
              </label>
              <Select
                id="status"
                defaultValue="lucy"
                style={{
                  width: 180,
                }}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <button className="btn btn-primary" style={{ marginTop: 30 }}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
          <Link to="/mentor/course-detail/null" state={{ nothing: "hello" }}>
            <button className="btn btn-success mt-4 d-flex align-items-center gap-2">
              Add Course <PlusCircleOutlined />
            </button>
          </Link>
        </div>
      </div>
      <div className="course-cards">
        {data &&
          data.map((item) => (
            <div className="course-card-wrap" key={item.id}>
              <div
                style={{
                  backgroundImage: `url(${
                    item?.banner?.url ||
                    "https://t4.ftcdn.net/jpg/05/06/47/83/360_F_506478367_PKcJr3mZMnZHwROfO4v70EOWiKiY9lVn.jpg"
                  })`,
                }}
                className="top"
              ></div>
              <div>
                <div className="course-name d-flex align-items-start justify-content-between p-3">
                  <div>
                    <p style={{ fontWeight: "bold" }}>{item.name}</p>
                    <p>{item.shortDescription}</p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-star text-muted"></i>
                    <span>{item.rating}</span>
                  </div>
                </div>
                <div className="teachers-name d-flex flex-column gap-4 p-3">
                  <div className="teacher d-flex align-items-center gap-3">
                    <img
                      style={{ height: 50, width: 50, borderRadius: "50%" }}
                      src={
                        item.teacherImg
                          ? item.teacherImg
                          : "https://www.w3schools.com/howto/img_avatar.png"
                      }
                      alt=""
                    />
                    <div>
                      <p style={{ margin: 0 }}>{item.teacherName} name</p>
                      <p style={{ margin: 0 }}>{item.teacherJob} job</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-price p-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <p className="mt-2">{item.price} UZS</p>
                  <div className="d-flex align-items-center gap-3">
                    <button
                      className={`btn btn btn-sm btn-${
                        item.status === "PUBLISHED" ? "success" : "danger"
                      }`}
                    >
                      {item.status}
                    </button>
                    <span className="d-flex align-items-center gap-2">
                      <i className="fa-solid fa-user"></i>
                      872
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between flex-column">
                  <div className="course-lev d-flex flex-column">
                    <p className="course-info">
                      <span>Course Level: </span>
                      {item.level}
                    </p>
                    <p className="course-info">
                      <span>Available days: </span>
                      {item.availableDay}
                    </p>
                    <p className="course-info">
                      <span>Publish Date: </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2  p-3  align-self-end bottom-blocks">
                <Link
                  style={{ color: "#009FE3" }}
                  state={{ courseId: item.id, name: item.name }}
                  to={`/mentor/course-section/${item.id}`}
                  replace
                >
                  Section
                </Link>
                <Link
                  // onMouseEnter={() => getOneItemData(item.id)}
                  style={{ color: "#009FE3" }}
                  //state={{ oneItemData }}
                  to={`/mentor/course-detail/${item.id}`}
                  replace
                >
                  Course
                </Link>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Course;
