import React, { useContext, useState } from 'react';
import { Input, Select, Divider, Table, Modal, message } from 'antd';
import './videos.scss';
import axios from 'axios';
import { BASE_URL } from '../../../../../api/base-url';
import AuthContext from '../../../../../context/auth-provider';
import { useEffect } from 'react';

const Videos = () => {
  const  token  = localStorage.getItem('token')
  const [data, setData] = useState([]);

  const [modal, setModal] = useState({
    createModal: false,
    deleteModal: false,
  });

  const [id, setId] = useState(null);

  // GET VIDEOS
  const getVideos = async () => {
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/client/filter`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );

    setData(data?.data?.clientEntities);
  };

  useEffect(() => {
    getVideos();
  }, []);

  const showModal = (id) => {
    setModal({ ...modal, createModal: true });
    setId(id);
  };

  const handleOk = () => {
    setModal({ ...modal, createModal: false });
  };

  const handleCancel = () => {
    setModal({ ...modal, createModal: false });
  };

  const showDeleteModal = (id) => {
    setModal({ ...modal, deleteModal: true });
    setId(id);
  };
  const handleDeleteOk = () => {
    setModal({ ...modal, deleteModal: false });
  };
  const handleDeleteCancel = () => {
    setModal({ ...modal, deleteModal: false });
  };

  const columns = [
    {
      title: 'Name Surname',
      dataIndex: 'name',
      render: (text, record) => (
        <a>
          {text} {record.surname}
        </a>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Last active date',
      dataIndex: 'lastActiveDate',
      render: (lastActiveDate, record) => (
        <>
          <p style={{ fontWeight: 'bold', margin: 0 }}>
            {lastActiveDate?.substring(0, 10)}
          </p>
          <span className='text-muted'>
            {record.createdDate?.substring(0, 10)}
          </span>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <button
          className={`btn text-light btn-${
            status === 'ACTIVE'
              ? 'success'
              : status === 'NOT ACTIVE'
              ? 'warning'
              : status === 'BLOCK'
              ? 'danger'
              : ''
          } btn-sm`}
        >
          {status}
        </button>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (action, record) => (
        <div className='d-flex gap-3'>
          <button
            onClick={() => showDeleteModal(record.id)}
            className='btn btn-danger'
          >
            <i
              style={{ fontSize: 20, cursor: 'pointer' }}
              className='fa-solid fa-trash'
            ></i>
          </button>
          <Modal
            title='Delete AdminClient'
            open={modal.deleteModal}
            okText='Delete'
            okButtonProps={{ style: { backgroundColor: '#dc3444' } }}
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
          >
            <div className='d-flex justify-content-center align-items-center mt-3'>
              <h4>Do you want delete client?</h4>
            </div>
          </Modal>
        </div>
      ),
    },
  ];

  return (
    <div className='client-wrapper'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='inputs d-flex align-items-center gap-4'>
          <div className='d-flex flex-column'>
            <label className='mb-2' htmlFor='name'>
              Video Name
            </label>
            <Input id='name' style={{ width: 'max-content' }} />
          </div>
          <div className='d-flex flex-column'>
            <label className='mb-2' htmlFor='status'>
              Status
            </label>
            <Select
              id='status'
              defaultValue='lucy'
              style={{
                width: 180,
              }}
              options={[
                {
                  value: 'jack',
                  label: 'Jack',
                },
                {
                  value: 'lucy',
                  label: 'Lucy',
                },
                {
                  value: 'Yiminghe',
                  label: 'yiminghe',
                },
              ]}
            />
          </div>
          <button className='btn btn-primary' style={{ marginTop: 30 }}>
            <i className='fa-solid fa-magnifying-glass'></i>
          </button>
        </div>
      </div>
      <div className='clients'>
        <Divider />
        <Table rowKey='id' columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default Videos;
