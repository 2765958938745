// src/axiosInstance.js
import axios from 'axios';
import {BASE_URL} from "./base-url";
import ShowMessageModal from "../components/modal/ShowMessageModal";

const axiosInstance = axios.create({
    baseURL: BASE_URL, // Replace with your API base URL
    timeout: 3000,
    headers: {'Content-Type': 'application/json'}
});

// Add a request interceptor
/*axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before the request is sent
        const token = localStorage.getItem('authToken'); // Retrieve auth token from localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        // Handle the error
        return Promise.reject(error);
    }
);*/


// Add a response interceptor

axiosInstance.interceptors.response.use(
    function (response) {
        // Do something with the response data
        // console.log('Interceptor Response keldi Response:', response);
        // Create a custom event with additional details if needed
        return response;
    },
    function (error) {
        // Handle the response error
        if (error.response && (error.response.status === 401 || error.response.status === 402)) {
            let message = "Iltimos qaytadan log-in qiling. Do not be mazgi.";
            if (error.response.status === 402) {
                message = "Login qilingan va murojat qilingan IP-lar mos emas. Qayta Login qiling mazgi."
            }
            // Handle unauthorized error
            // Perform any logout actions or redirect to login page
            const event = new CustomEvent('showMessageModal', {detail: {message: message}});
            // Dispatch the event on the window or document object
            window.dispatchEvent(event);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;