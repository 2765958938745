import React, { useState } from "react";
import { Checkbox, Input, Spin } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../api/base-url";
import axios from "axios";
import "./RegistrationComponent.scss";

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  surname: yup.string().required("Surname is required"),
  phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
});

const RegisterComponent = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [usingTelegramBotVal, setUsingTelegramBotVal] = useState(false);
  const onSubmit = (values, actions) => {
    const header = {
      headers: { "Content-Type": "application/json" },
    };

    axios
      .post(
        `${BASE_URL}/api/v1/auth/client/registration`,
        {
          name: values.name,
          surname: values.surname,
          phoneNumber: values.phoneNumber,
          userTelegramBot: usingTelegramBotVal,
        },
        header
      )
      .then((item) => {
        if (!item?.data.isError) {
          navigate("/register-verification");
          localStorage.setItem(
            "registerPhoneNumber",
            JSON.stringify(values.phoneNumber)
          );
          window.location.reload();
          actions.resetForm();
        } else {
          setError(item.data.message);
          actions.resetForm({
            values: {
              name: values.name,
              surname: values.surname,
              phoneNumber: values.phoneNumber,
              userTelegramBot: usingTelegramBotVal,
            },
          });
          localStorage.removeItem("registerPhoneNumber");
        }
      })
      .catch((eror) => {});
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      surname: "",
      phoneNumber: "+998",
    },
    validationSchema: schema,
    onSubmit,
  });

  return (
    <form
      onSubmit={handleSubmit}
      className="register-component-wrapper bg-light"
    >
      <div className="text-center">
        <h3>Ro'yxatdan o'tish</h3>
      </div>

      <div className="inputs">
        <div className="input w-100 mt-1">
          <label className="p-2" htmlFor="name">
            Ism
          </label>
          <Input
            value={values.name}
            onChange={handleChange}
            style={{
              padding: 12,
              borderColor: errors.name && touched.name ? "red" : "",
            }}
            id="name"
            placeholder="Ism kiriting"
            onBlur={handleBlur}
          />
          {errors.name && touched.name && (
            <span style={{ color: "red", fontSize: 13 }}>{errors.name}</span>
          )}
        </div>
        <div className="input w-100 mt-1">
          <label className="p-2" htmlFor="surname">
            Familiya
          </label>
          <Input
            style={{
              padding: 12,
              borderColor: errors.surname && touched.surname ? "red" : "",
            }}
            value={values.surname}
            onChange={handleChange}
            id="surname"
            placeholder="Familiyani kiriting"
            onBlur={handleBlur}
          />
          {errors.surname && touched.surname && (
            <span style={{ color: "red", fontSize: 13 }}>{errors.surname}</span>
          )}
        </div>
        <div className="input w-100 mt-1">
          <label className="p-2" htmlFor="phoneNumber">
            Telefon Raqam
          </label>
          <Input
            value={values.phoneNumber}
            onChange={handleChange}
            style={{
              padding: 12,
              borderColor:
                errors.phoneNumber && touched.phoneNumber ? "red" : "",
            }}
            type="text"
            id="phoneNumber"
            placeholder="Telefon raqam kiriting"
            onBlur={handleBlur}
          />
          {errors.phoneNumber && touched.phoneNumber && (
            <span style={{ color: "red", fontSize: 13 }}>
              {errors.phoneNumber}
            </span>
          )}
        </div>
        <div className="text-center mt-1">
          <p className="text-danger">{error}</p>
        </div>
      </div>
      <div className="registration-check-container">
        <Checkbox
          value={usingTelegramBotVal}
          onChange={(e) => {
            setUsingTelegramBotVal(!usingTelegramBotVal);
          }}
        >
          <span className="registration-check-container-text">
            Telegram bot orqali SMS-ni qabul qilish.
          </span>
        </Checkbox>
      </div>
      <div className="button-wrap">
        <button
          disabled={isSubmitting}
          type="submit"
          className="button text-light"
        >
          {isSubmitting ? <Spin /> : "Ro'yxatdan o'tish"}
        </button>
        <br />
        <span>
          {" "}
          <a href="https://t.me/videohubsms_bot" target="_blank">
            Videohub SMS botga o'tish
          </a>
        </span>
      </div>
    </form>
  );
};

export default RegisterComponent;
