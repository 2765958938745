import React from 'react';
import { Checkbox } from 'antd';
import './add-task.scss';

const AddTask = () => {
  return (
    <div className='add-task-wrap'>
      <div className='add-task-top'>
        <div className='card-title'>
          <h5>Tasks</h5>
          <span className='text-muted'>Today</span>
        </div>
        <p className='view-detail'>View All</p>
      </div>
      <div className='tasks'>
        <div className='task task-input'>
          <input type='text' placeholder='Create new task' />
          <i className='fa-solid fa-plus'></i>
        </div>
        <div className='task'>
          <div className='d-flex gap-2 align-items-center'>
            <Checkbox />
            <h5 className='mt-2'>Finish ticket update</h5>
          </div>
          <button className='btn btn-warning text-light btn-sm'>URGENT</button>
        </div>
        <div className='task'>
          <div className='d-flex gap-2 align-items-center'>
            <Checkbox />
            <h5 className='mt-2'>Create new ticket example</h5>
          </div>
          <button
            style={{ backgroundColor: '#29CC97' }}
            className='btn text-light btn-sm'
          >
            NEW
          </button>
        </div>
        <div className='task'>
          <div className='d-flex gap-2 align-items-center'>
            <Checkbox />
            <h5 className='mt-2'>Update ticket report</h5>
          </div>
          <button className='btn btn-secondary text-light btn-sm'>
            DEFAULT
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
