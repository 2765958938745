import React from "react";
import { Header } from "../../components";
import ClientLoginComponent from "../../components/client-login-component/clientLoginComponent";
import "./ClientLogInPage.scss";
function ClientLogInPage() {
  return (
    <>
      <Header />
      <div className="loginPage">
        <section className="login-section">
          <ClientLoginComponent />
        </section>
      </div>
    </>
  );
}

export default ClientLogInPage;
