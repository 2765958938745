import React from 'react';
import { Input, Select, Divider, Table } from 'antd';
import './client.scss';

const Client = () => {
  const columns = [
    {
      title: 'Client',
      dataIndex: 'data',
      render: (data = []) =>
        data.map((item) => (
          <div key={item.img} className='d-flex align-items-center gap-3'>
            <img
              style={{ height: 50, width: 50, borderRadius: '50%' }}
              src={item.img}
              alt=''
            />
            <div>
              <p className='mb-1'>{item.name}</p>
              <span>{item.phoneNumber}</span>
            </div>
          </div>
        )),
    },
    {
      title: 'Course',
      dataIndex: 'phone',
    },
    {
      title: 'Purchase type',
      dataIndex: 'activeDate',
      render: (activeDate) => (
        <>
          <span className='text-muted'>Cash</span>
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'activeDate',
      render: (activeDate) => (
        <>
          <p style={{ fontWeight: 'bold', margin: 0 }}>{activeDate}</p>
          <span className='text-muted'>6:30 PM</span>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <button
          className={`btn text-light btn-${
            status === 'ACTIVE'
              ? 'success'
              : status === 'NOT ACTIVE'
              ? 'warning'
              : status === 'BLOCK'
              ? 'danger'
              : ''
          } btn-sm`}
        >
          {status}
        </button>
      ),
    },
    {
      title: 'Completed',
      dataIndex: 'activeDate',
      render: (activeDate) => (
        <>
          <span className='text-muted'>True</span>
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (action) => (
        <div className='d-flex gap-3'>
          <button className='btn btn-warning'>
            <i
              style={{ fontSize: 20, cursor: 'pointer' }}
              className='fa-solid fa-pen'
            ></i>
          </button>
          <button className='btn btn-danger'>
            <i
              style={{ fontSize: 20, cursor: 'pointer' }}
              className='fa-solid fa-trash'
            ></i>
          </button>
        </div>
      ),
    },
  ];
  const data = [
    {
      data: [
        {
          img: 'https://www.w3schools.com/howto/img_avatar.png',
          name: 'John Brown',
          phoneNumber: +998916771030,
        },
      ],
      phone: 'Typescript',
      activeDate: 'London No. 1 Lake Park',
      status: 'ACTIVE',
    },
    {
      data: [
        {
          img: 'https://www.w3schools.com/howto/img_avatar.png',
          name: 'John Brown',
          phoneNumber: +998916771030,
        },
      ],
      phone: 'Typescript',
      activeDate: 'London No. 1 Lake Park',
      status: 'NOT ACTIVE',
    },
    {
      data: [
        {
          img: 'https://www.w3schools.com/howto/img_avatar.png',
          name: 'John Brown',
          phoneNumber: +998916771030,
        },
      ],
      phone: 'Typescript',
      activeDate: 'London No. 1 Lake Park',
      status: 'BLOCK',
    },
    {
      data: [
        {
          img: 'https://www.w3schools.com/howto/img_avatar.png',
          name: 'John Brown',
          phoneNumber: +998916771030,
        },
      ],
      phone: 'Typescript',
      activeDate: 'London No. 1 Lake Park',
      status: 'ACTIVE',
    },
    {
      data: [
        {
          img: 'https://www.w3schools.com/howto/img_avatar.png',
          name: 'John Brown',
          phoneNumber: +998916771030,
        },
      ],
      phone: 'Typescript',
      activeDate: 'London No. 1 Lake Park',
      status: 'ACTIVE',
    },
    {
      data: [
        {
          img: 'https://www.w3schools.com/howto/img_avatar.png',
          name: 'John Brown',
          phoneNumber: +998916771030,
        },
      ],
      phone: 'Typescript',
      activeDate: 'London No. 1 Lake Park',
      status: 'ACTIVE',
    },
    {
      data: [
        {
          img: 'https://www.w3schools.com/howto/img_avatar.png',
          name: 'John Brown',
          phoneNumber: +998916771030,
        },
      ],
      phone: 'Typescript',
      activeDate: 'London No. 1 Lake Park',
      status: 'ACTIVE',
    },
  ];

  return (
    <div className='client-wrapper'>
      <div className='inputs d-flex align-items-center gap-4'>
        <div className='d-flex flex-column'>
          <label className='mb-2' htmlFor='name'>
            Name or surname
          </label>
          <Input id='name' style={{ width: 'max-content' }} />
        </div>
        <div className='d-flex flex-column'>
          <label className='mb-2' htmlFor='phone'>
            Phone
          </label>
          <Input id='phone' style={{ width: 'max-content' }} />
        </div>
        <div className='d-flex flex-column'>
          <label className='mb-2' htmlFor='status'>
            Status
          </label>
          <Select
            id='status'
            defaultValue='lucy'
            style={{
              width: 180,
            }}
            options={[
              {
                value: 'jack',
                label: 'Jack',
              },
              {
                value: 'lucy',
                label: 'Lucy',
              },
              {
                value: 'Yiminghe',
                label: 'yiminghe',
              },
            ]}
          />
        </div>
        <button className='btn btn-primary' style={{ marginTop: 30 }}>
          <i className='fa-solid fa-magnifying-glass'></i>
        </button>
      </div>
      <div className='clients'>
        <Divider />
        <Table rowKey='id' columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default Client;
