import React, { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../../context/auth-provider";
import { Collapse } from "antd";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../api/base-url";
import "./client-course-navigation-tree-component.scss";
import "react-toastify/dist/ReactToastify.css";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { secondsToTime } from "../../utils/Utils";
import axiosInstance from "../../api/intercepter";

const { Panel } = Collapse;

const ClientCourseNavigationTreeComponent = ({
  courseId,
  courseName,
  open,
  setOpen,
  courseSectionList,
  setVideoData,
  clientCourse,
  courseLessonId,
  setCourseLessonId,
  setAllLessonsFinished,
  setShowToasterMessage,
}) => {
  const [isContentMenuActive, setIsContentMenuActive] = useState(false);
  const [activeLessonName, setActiveLessonName] = useState("");

  const percent = clientCourse?.finishedPercentage;

  const token = localStorage.getItem("token");

  const onChange = (key) => {};

  const navigate = useNavigate();

  // START COURSES LESSONS
  const courseLessonStart = async (id) => {
    const { data } = await axiosInstance.get(
      `${BASE_URL}/api/v1/client-course-lesson/start/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (data?.code !== 400) {
      setVideoData(data?.data);
      setIsContentMenuActive(false);
      setActiveLessonName(data?.data.name);
    } else {
      setShowToasterMessage(data?.message);
    }
  };

  // Ref to track if the page has already scrolled
  const hasScrolledRef = useRef(false);

  useEffect(() => { // TODO
    if (clientCourse?.progressStatus === "STARTED") {
      // start last not finished lesson automatically
      const goToCourseLessonId = getLastNotFinishedCourseLessonId();

      if (goToCourseLessonId) {
        setCourseLessonId(goToCourseLessonId);
        courseLessonStart(goToCourseLessonId);
      } else if (isAllLCourseLessonsFinished()) {
        // if === undefined may be all lessons finished
        // show FINISH course lesson button
        setAllLessonsFinished(true);
      }
    }
  }, [clientCourse]);

  function getFirstLessonId() {
    const firstSection = courseSectionList[0];
    const sectionLessonList = JSON.parse(firstSection.lessonListJson);
    return sectionLessonList[0]?.id;
  }

  function isAllLCourseLessonsFinished() {
    let allFinished = true;
    courseSectionList.forEach((section) => {
      const sectionLessonList = JSON.parse(section.lessonListJson);
      sectionLessonList.forEach((courseLesson) => {
        if (courseLesson.clientCourseLesson) {
          const clientCourseLesson = JSON.parse(
            courseLesson.clientCourseLesson
          );
          if (clientCourseLesson && clientCourseLesson.status !== "FINISH") {
            allFinished = false; // started but not closed
          }
        }
      });
    });

    return allFinished;
  }

  function getLastNotFinishedCourseLessonId() {
    let gotoLessonId = undefined;
    courseSectionList.forEach((section) => {
      if (section === null || section === undefined) {
        return;
      }
      const sectionLessonList = JSON.parse(section.lessonListJson);
      sectionLessonList.forEach((courseLesson) => {
        if (courseLesson.clientCourseLesson) {
          const clientCourseLesson = JSON.parse(
            courseLesson.clientCourseLesson
          );
          if (
            clientCourseLesson &&
            clientCourseLesson.status === "START" &&
            gotoLessonId === undefined
          ) {
            gotoLessonId = courseLesson.id; // started but not closed
          }
        } else if (gotoLessonId === undefined) {
          gotoLessonId = courseLesson.id; // not opened lesson
        }
      });
    });
    return gotoLessonId;
  }

  function getIcon(courseLesson) {
    if (!courseLesson?.clientCourseLesson) {
      return (
        <i
          style={{ fontSize: 18, color: "#267ea3" }}
          className="fa-solid fa-lock"
        ></i>
      );
    }
    const clientCourse = JSON.parse(courseLesson?.clientCourseLesson);
    if (clientCourse?.status === "START") {
      return (
        <i
          style={{ fontSize: 18, color: "#267ea3" }}
          className="fa-solid fa-circle mt-1"
        ></i>
      );
    } else {
      return (
        <i
          style={{ fontSize: 18, color: "#267ea3" }}
          className="fa-solid fa-check mt-1"
        ></i>
      );
    }
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if(courseLessonId){
      const selectedCourseLesson = document.getElementById(courseLessonId);
      if (selectedCourseLesson != null) {

          selectedCourseLesson.scrollIntoView();
      }
    }
  }, [courseLessonId]);


  // useEffect(() => {
  //   const selectedItem = document.querySelector(".item__selected");
  //   if (selectedItem) {
  //     selectedItem.scrollIntoView({ behavior: "smooth", block: "center" });
  //   }
  // }, [courseLessonId]);

  // useEffect(() => {
  //   // Scroll only on first page load
  //   if (!hasScrolledRef.current) {
  //     const selectedItem = document.querySelector(".item__selected");
  //     if (selectedItem) {
  //       selectedItem.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }
  //     hasScrolledRef.current = true; // Set the flag to true
  //   }
  // }, [courseLessonId]); // Empty dependency array ensures this runs only once when the component mounts
  // useEffect(() => {
  //   const selectedCourseLesson =
  //     document.getElementsByClassName("item__selected");
  //   if (selectedCourseLesson.length > 0) {
  //     // Check if the collection is not empty
  //     selectedCourseLesson[0].scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //   }
  // }, []);

  return (
    <div style={{ width: open ? 300 : 0 }} className="video-filter-wrapper">
      <div className="contents-menu p-2">
        <div
          className="d-flex align-items-center gap-3"
          onClick={() => setIsContentMenuActive(true)}
        >
          <i className="bi bi-list-ul fs-1"></i>
          <span>Mundarija</span>
        </div>
        <div
          className={`p-1 ${isContentMenuActive ? "d-block" : "d-none"}`}
          onClick={() => setIsContentMenuActive(false)}
        >
          <i className="bi bi-x-circle fs-2"></i>
        </div>
      </div>
      <div
        className={`m_course-title text-white px-2 ${
          isContentMenuActive ? "" : "m_course-title_active"
        }`}
      >
        <p>
          {courseName} {activeLessonName ? ` : ${activeLessonName}` : ""}
        </p>
      </div>
      <div
        className={`range-wrap ${
          isContentMenuActive ? "contents-menu_active" : ""
        }`}
      >
        <div className="inner-range-wrap">
          <p>{courseName}</p>
          <div className="range">
            <div
              style={{ width: `${percent}%` }}
              className="position-range"
            ></div>
          </div>
          <p>{percent}% yakunlandi</p>
        </div>
        <button className="caret" onClick={() => setOpen(!open)}>
          <i className="fa-solid fa-caret-left"></i>
        </button>
      </div>
      <div
        className={`video-filter__list ${
          isContentMenuActive ? "contents-menu_active" : ""
        }`}
      >
        {courseSectionList.map((item) => {
          const lessons =
            item?.lessonListJson && JSON.parse(item?.lessonListJson);
          return (
            <Collapse
              defaultActiveKey={[item.id]}
              onChange={onChange}
              bordered={false}
              expandIconPosition={"end"}
              key={item?.id}
            >
              <Panel
                className="filter-collapse"
                style={{ maxWidth: "100%" }}
                header={item?.name}
                key={item?.id}
              >
                {lessons?.map((item) => (
                  <div
                    key={item?.id}
                    id={item?.id}
                    onClick={() => {
                      courseLessonStart(item?.id);
                      setCourseLessonId(item?.id);
                      scrollToTop();
                      // navigate(`/${item?.id}`);
                    }}
                    className={`item ${
                      item?.id === courseLessonId && "item__selected"
                    }  w-100 me-3`}
                  >
                    <div className="d-flex align-items-start gap-4">
                      {getIcon(item)}
                      <div>
                        <p style={{ fontWeight: "bold" }}>{item?.name}</p>
                        <span style={{ color: "#ddd" }}>
                          {secondsToTime(item?.videoDuration)}
                        </span>
                      </div>
                    </div>
                    <i
                      style={{ fontSize: 20 }}
                      className="fa-regular fa-bookmark mt-1"
                    ></i>
                  </div>
                ))}
              </Panel>
            </Collapse>
          );
        })}
      </div>
    </div>
  );
};

export default ClientCourseNavigationTreeComponent;
