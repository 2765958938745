import {createContext, useEffect, useState} from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({children}) => { // TODO
    // JSON Items
    const registerPhoneNumber = JSON.parse(localStorage.getItem('registerPhoneNumber'));

    const userData = JSON.parse(localStorage.getItem('userData'));
    // const roleList = JSON.parse(localStorage.getItem('roleList'));
    const jwtToken = localStorage.getItem('token');

    // delete all then
    const loginPhoneNumber = localStorage.getItem('loginPhoneNumber');
    const loginUser = JSON.parse(localStorage.getItem('loginUser'));
    const registerUser = JSON.parse(localStorage.getItem('registerUserData'));
    const adminUser = JSON.parse(localStorage.getItem('adminUser'));
    //const userToken = localStorage.getItem('token');

    // Phone Numbers
    let [registerPhone, setRegisterPhone] = useState(registerPhoneNumber || '');
    let [loginPhone, setLoginPhone] = useState(loginPhoneNumber || '');

    // Users
    let [login, setLogin] = useState(loginUser || '');
    let [register, setRegister] = useState(registerUser || '');
    let [admin, setAdmin] = useState(adminUser || '');

    // TOKEN
    const [token, setToken] = useState();

    useEffect(() => {

        // JSON Items
        const registerPhoneNumber = JSON.parse(
            localStorage.getItem('registerPhoneNumber')
        );
        if (!registerPhoneNumber) {
            setRegisterPhone(registerPhoneNumber)
        }

        const loginPhoneNumber = localStorage.getItem('loginPhoneNumber');
        if (!loginPhoneNumber) {
            setLoginPhone(loginPhoneNumber)
        }

        const loginUser = JSON.parse(localStorage.getItem('loginUser'));
        if (!loginUser) {
            setLogin(loginUser)
        }

        const registerUser = JSON.parse(localStorage.getItem('registerUserData'));
        if (!registerUser) {
            setRegister(registerUser)
        }
        //  const adminUser = JSON.parse(localStorage.getItem('adminUser'));

        const userToken = localStorage.getItem('token');
        if (!userToken) {
            setToken(userToken)
        }
    }, [])
    return (
        <AuthContext.Provider
            value={{
                registerPhone,
                setRegisterPhone,
                loginPhone,
                setLoginPhone,
                login,
                setLogin,
                register,
                setRegister,
                admin,
                setAdmin,
                token,
                adminUser,
                setToken,
                userData
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
