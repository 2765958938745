import React, { useContext, useState } from 'react';
import { Input, Select, Divider, Table, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import ReactSelect from 'react-select';
import './profile.scss';
import axios from 'axios';
import { ROLES } from '../../../../../roles';
import { BASE_URL } from '../../../../../api/base-url';
import { useEffect } from 'react';
import AuthContext from '../../../../../context/auth-provider';

const Profile = () => {
  const  token  = localStorage.getItem('token')
  const [data, setData] = useState([]);

  const [modal, setModal] = useState({
    updateModal: false,
    deleteModal: false,
  });
  const [inputValues, setInputValues] = useState({
    name: '',
    surname: '',
    contact: '',
    password: '',
    login: '',
    roles: [],
  });

  const [id, setId] = useState(null);

  // GET PROFILE
  const getProfile = async () => {
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/profile/filter`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setData(data?.content);
  };

  // CREATE PROFILE
  const createProfile = async () => {
    const { name, surname, contact, login, roles, password } = inputValues;
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/profile`,
      {
        name: name,
        surname: surname,
        password: password,
        contact: contact,
        login: login,
        roleList: roles,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    window.location.reload();
  };

  // UPDATE PROFILE
  const updateProfile = async () => {
    const { name, surname, contact, login, roles } = inputValues;
    const { data } = await axios.put(
      `${BASE_URL}/api/v1/profile/${id}`,
      {
        name: name,
        surname: surname,
        contact: contact,
        login: login,
        roleList: roles,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    window.location.reload();
  };

  // DELETE PROFILE
  const deleteProfile = async () => {
    const { data } = await axios.delete(`${BASE_URL}/api/v1/profile/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    window.location.reload();
  };

  useEffect(() => {
    getProfile();
  }, []);

  const showModal = (id) => {
    setModal({ ...modal, updateModal: true });
    setId(id);
  };
  const handleOk = () => {
    setModal({ ...modal, updateModal: false });
    if (id) {
      updateProfile();
    } else {
      createProfile();
    }
  };
  const handleCancel = () => {
    setModal({ ...modal, updateModal: false });
  };

  const showDeleteModal = (id) => {
    setModal({ ...modal, deleteModal: true });
    setId(id);
  };
  const handleDeleteOk = () => {
    setModal({ ...modal, deleteModal: false });
    deleteProfile();
  };
  const handleDeleteCancel = () => {
    setModal({ ...modal, deleteModal: false });
  };

  const columns = [
    {
      title: 'Name Surname',
      dataIndex: 'name',
      render: (text, record) => (
        <a>
          {text} {record.surname}
        </a>
      ),
    },
    {
      title: 'Login',
      dataIndex: 'login',
    },
    {
      title: 'Last active date',
      dataIndex: 'lastActiveDate',
      render: (lastActiveDate, record) => (
        <>
          <p style={{ fontWeight: 'bold', margin: 0 }}>
            {lastActiveDate?.substring(0, 10)}
          </p>
        </>
      ),
    },
    {
      title: 'Roles',
      dataIndex: 'roleList',
      render: (text, record) => <a>{text}</a>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (action, record) => (
        <div className='d-flex gap-3'>
          <button
            onClick={() => {
              showModal(record.id);
              setInputValues({
                ...inputValues,
                name: record.name,
                surname: record.surname,
                contact: record.contact,
                login: record.login,
                roles: record.roleList,
              });
            }}
            className='btn btn-warning'
          >
            <i
              style={{ fontSize: 20, cursor: 'pointer' }}
              className='fa-solid fa-pen'
            ></i>
          </button>
          <Modal
            className='d-flex flex-column gap-2'
            title={id ? 'Update Profile' : 'Create Profile'}
            okText={id ? 'Update' : 'Create'}
            okButtonProps={{
              style: { backgroundColor: id ? '#FEC400' : '#198753' },
            }}
            open={modal.updateModal}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div>
              <label htmlFor='name'>Name</label>
              <Input
                id='name'
                value={inputValues.name}
                onChange={(e) =>
                  setInputValues({ ...inputValues, name: e.target.value })
                }
              />
            </div>
            <div className='mt-4'>
              <label htmlFor='surname'>Surname</label>
              <Input
                id='surname'
                value={inputValues.surname}
                onChange={(e) =>
                  setInputValues({ ...inputValues, surname: e.target.value })
                }
              />
            </div>
            <div className='mt-4'>
              <label htmlFor='contact'>Contact</label>
              <Input
                type='text'
                id='contact'
                value={inputValues.contact}
                onChange={(e) =>
                  setInputValues({ ...inputValues, contact: e.target.value })
                }
              />
            </div>
            <div className='mt-4'>
              <label htmlFor='login'>Login</label>
              <Input
                id='login'
                value={inputValues.login}
                onChange={(e) =>
                  setInputValues({ ...inputValues, login: e.target.value })
                }
              />
            </div>
            {!id && (
              <div className='mt-4'>
                <label htmlFor='password'>Password</label>
                <Input
                  id='password'
                  type='password'
                  value={inputValues.password}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, password: e.target.value })
                  }
                />
              </div>
            )}

            <div className='mt-4'>
              <label htmlFor='login'>ROLES</label>
              <ReactSelect
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    roles: e.map((tag) => tag.label),
                  });
                }}
                options={ROLES.map((tag) => {
                  return { label: tag, value: tag };
                })}
                isMulti
              />
            </div>
          </Modal>
          <button
            onClick={() => showDeleteModal(record.id)}
            className='btn btn-danger'
          >
            <i
              style={{ fontSize: 20, cursor: 'pointer' }}
              className='fa-solid fa-trash'
            ></i>
          </button>
          <Modal
            title='Delete Profile'
            open={modal.deleteModal}
            okText='Delete'
            okButtonProps={{ style: { backgroundColor: '#dc3444' } }}
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
          >
            <div className='d-flex justify-content-center align-items-center mt-3'>
              <h4>Do you want delete profile?</h4>
            </div>
          </Modal>
        </div>
      ),
    },
  ];

  return (
    <div className='client-wrapper'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='inputs d-flex align-items-center gap-4'>
          <div className='d-flex flex-column'>
            <label className='mb-2' htmlFor='name'>
              Name or surname
            </label>
            <Input id='name' style={{ width: 'max-content' }} />
          </div>
          <div className='d-flex flex-column'>
            <label className='mb-2' htmlFor='phone'>
              Phone
            </label>
            <Input id='phone' style={{ width: 'max-content' }} />
          </div>
          <div className='d-flex flex-column'>
            <label className='mb-2' htmlFor='status'>
              Status
            </label>
            <Select
              id='status'
              defaultValue='lucy'
              style={{
                width: 180,
              }}
              options={[
                {
                  value: 'jack',
                  label: 'Jack',
                },
                {
                  value: 'lucy',
                  label: 'Lucy',
                },
                {
                  value: 'Yiminghe',
                  label: 'yiminghe',
                },
              ]}
            />
          </div>
          <button className='btn btn-primary' style={{ marginTop: 30 }}>
            <i className='fa-solid fa-magnifying-glass'></i>
          </button>
        </div>
        <button
          onClick={() => {
            showModal();
            setInputValues({
              ...inputValues,
              name: '',
              surname: '',
              contact: '',
              login: '',
              password: '',
              roles: '',
            });
          }}
          className='btn btn-success d-flex align-items-center gap-2'
        >
          Create Profile <PlusCircleOutlined />
        </button>
      </div>
      <div className='clients'>
        <Divider />
        <Table rowKey='id' columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default Profile;
