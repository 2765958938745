import React, { useContext, useEffect, useState } from "react";
import { Divider, Table, Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "../../../../../api/base-url";
import "./course-banner.scss";
import AuthContext from "../../../../../context/auth-provider";

const CourseBanner = () => {
  const [data, setData] = useState([]);
  const  token  = localStorage.getItem('token')

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showImgModal, setShowImgModal] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [file, setFile] = useState(null);

  // BANNERS
  const getBanners = async () => {
    const { data } = await axios.get(`${BASE_URL}/api/v1/course-banner`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setData(data.data);
  };

  useEffect(() => {
    getBanners();
  }, []);

  const deleteBanner = async () => {
    const { data } = await axios.delete(
      `${BASE_URL}/api/v1/course-banner/${deleteId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    window.location.reload();
  };

  // DELETE MODAL FUNCTIONS
  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    deleteBanner();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // ATTACH
  const handleChange = (e) => {
    let file = e.target.files[0];
    setFile(file);
  };

  // CREATE MODAL FUNCTIONS
  const showCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const handleCreateOk = async () => {
    let formData = new FormData();
    formData.append("file", file);

    const { data } = await axios.post(
      `${BASE_URL}/api/v1/attach/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    postBannerId(data.data.id);
    setIsCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setIsCreateModalOpen(false);
  };
  const postBannerId = async (id) => {
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/course-banner`,
      {
        bannerId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    window.location.reload();
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "banner",
      render: (banner) => (
        <>
          <img
            onClick={() => setShowImgModal(true)}
            style={{ height: 100, width: 150, cursor: "pointer" }}
            src={banner.url}
            alt=""
          />
          <Modal
            onCancel={() => setShowImgModal(false)}
            onOk={() => setShowImgModal(false)}
            open={showImgModal}>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <img style={{ width: "100%" }} src={banner.url} alt="" />
            </div>
          </Modal>
        </>
      ),
    },
    {
      title: "Id",
      dataIndex: "banner",
      render: (banner) => <span>{banner.id}</span>,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (date) => <span>{date.substring(0, 10)}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (action, record) => {
        return (
          <>
            <button
              onClick={() => showModal(record.id)}
              className="btn btn-danger">
              <i
                style={{ fontSize: 20, cursor: "pointer" }}
                className="fa-solid fa-trash"></i>
            </button>
            <Modal
              title="Delete Banner"
              open={isModalOpen}
              okText="Delete"
              okButtonProps={{ style: { backgroundColor: "#dc3444" } }}
              onOk={handleOk}
              onCancel={handleCancel}>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <h4>Do you want delete banner?</h4>
              </div>
            </Modal>
          </>
        );
      },
    },
  ];

  return (
    <div className="client-wrapper">
      <div className="w-100 d-flex justify-content-end">
        <button
          onClick={showCreateModal}
          className="btn btn-success mt-4 d-flex align-items-center gap-2">
          Add Course Banner <PlusCircleOutlined />
        </button>
      </div>
      <div className="clients">
        <Divider />
        <Table rowKey="id" columns={columns} dataSource={data} />
        <Modal
          onOk={handleCreateOk}
          okText="Create"
          okButtonProps={{ style: { backgroundColor: "#198754" } }}
          open={isCreateModalOpen}
          onCancel={handleCreateCancel}>
          <input
            type="file"
            style={{ width: "100%" }}
            name="file"
            onChange={handleChange}
          />
        </Modal>
      </div>
    </div>
  );
};

export default CourseBanner;
