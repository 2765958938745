import React from "react";
import "./task-card.scss";

const TaskCard = () => {
  return (
    <div className="task-card-wrap">
      <div className="task-card-top">
        <div className="card-title">
          <h5>Unresolved tickets</h5>
          <span className="text-muted">
            Group: <span className="text-dark">Support</span>
          </span>
        </div>
        <p className="view-detail">View Detail</p>
      </div>
      <div className="task-names">
        <div className="task">
          <h5>Waiting on Feature Request</h5>
          <span className="text-muted">4238</span>
        </div>
        <div className="task">
          <h5>Awaiting Customer Response</h5>
          <span className="text-muted">1005</span>
        </div>
        <div className="task">
          <h5>Waiting on Feature Request</h5>
          <span className="text-muted">4238</span>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
