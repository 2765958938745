import React, { useRef, useEffect, useState } from "react";
import JoLPlayer, { callBackType, JoLPlayerRef, qualityKey } from "jol-player";
import ReactPlayer from "react-player";
import "./jolVideoComponent.scss";

const JolVideoComponent = ({ videoType, videoId, videoFormatTypeList }) => {
  const ref = useRef(null);

  const videoHubUrl = "https://api.videohab.uz/attach/stream/";
  // const videoHubUrl = 'https://178.218.207.75:9093/attach/stream/';

  const videoRef = useRef(null);
  const [defaultVideoUrl, setDefaultVideoUrl] = useState(null);
  const [qualityList, setQualityList] = useState([]);

  const initData = () => {
    if (videoType !== "VIDEO_LINK") {
      const itemList = [];
      let dvUrl = undefined;
      videoFormatTypeList?.forEach((item) => {
        if (item === "FHD") {
          itemList.push({ name: "FHD", url: videoHubUrl + videoId + "-1080" });
          // itemList.push({name: "FHD", url: videoHubUrl + videoId})
          if (dvUrl === undefined) {
            dvUrl = videoHubUrl + videoId + "-1080";
          }
        } else if (item === "HD") {
          itemList.push({ name: "HD", url: videoHubUrl + videoId + "-720" });
          if (dvUrl === undefined) {
            dvUrl = videoHubUrl + videoId + "-720";
          }
        } else if (item === "SD") {
          itemList.push({ name: "SD", url: videoHubUrl + videoId + "-360" });
          dvUrl = videoHubUrl + videoId + "-360";
        }
      });
      setQualityList(itemList);
      setDefaultVideoUrl(dvUrl);
    }
  };

  useEffect(() => {
    // setDefaultVideoUrl('https://api.videohab.uz/attach/stream/ffed5d7c-a250-4819-90be-3433d2c020c5-720')
  }, [videoRef.current]);

  useEffect(() => {
    initData();
    init();
  }, [videoId]);

  const onProgressMouseUp = (val) => {
    //  console.log(`onProgressMouseUp`, val);
  };

  const actionMethod = (val) => {
    //   console.log(`onEndEd`, val);
  };

  const init = () => {
    var parent = document.getElementsByClassName("JoL-multifunction");
    if (parent && parent[0]) {
      var childrenList = parent[0].querySelectorAll(".tooltip");
      if (childrenList.length >= 4) {
        childrenList[2].style = "padding: 0px 8px; opacity:1;";
        childrenList[3].style = "padding: 0px 8px; opacity:1;";
        parent[0].removeChild(childrenList[1]);
        parent[0].removeChild(childrenList[0]);
      }
    }
    // var videoContainer = document.getElementsByClassName('JoL-controller-container')
    // if(videoContainer){
    //     videoContainer[0].addEventListener('dblclick', () =>{
    //         console.log('Mazgi')
    //     })
    // }
  };
  // return(<div className='videoPlayerWrapper'>dasd asdasd asdas</div>)
  // There are many properties and methods, please refer to the documentation ...
  return videoType === "VIDEO_LINK" ? (
    <ReactPlayer
      autoPlay={false}
      controls={true}
      style={{ margin: "0 auto" }}
      config={{ file: { attributes: { controlsList: "nodownload" } } }}
      width="98%"
      controlsList="nodownload"
      selectedVideoTrack={{
        type: "resolution",
        value: 480,
      }}
      // height={700}
      // height={"85vh"}
      url={videoId}
    />
  ) : (
    <div className="videoPlayerWrapper" ref={ref}>
      <JoLPlayer
        className="custom-player"
        ref={videoRef}
        onProgressMouseUp={onProgressMouseUp}
        onEndEd={actionMethod}
        onPause={actionMethod}
        onProgressMouseDown={actionMethod}
        onPlay={actionMethod}
        onTimeChange={actionMethod}
        onvolumechange={actionMethod}
        onError={actionMethod}
        onQualityChange={actionMethod}
        option={{
          videoSrc: defaultVideoUrl,
          poster:
            "https://api.videohab.uz/api/v1/attach/open/dfe2baad-624d-4c23-8e8b-3f821fd1dd59",
          language: "en",
          isShowMultiple: true,
          pausePlacement: "center",
          quality: qualityList,
          isShowSet: false,
        }}
      />
    </div>
  );
};

// SD — 720×480, 704×480, 352×480, 352×240 720×576, 704×576, 352×576, 352×288
// HD — 1280×720, 1440×1080
// Full HD — 1920×1080
// UHD (4K) — 3840×2160
// 8K — 7680×4320
// https://codesandbox.io/s/muddy-sun-fzrcft?file=/src/App.tsx:3366-3368
// https://reactjsexample.com/a-simple-beautiful-and-powerful-react-player/
// https://dev.to/lgf196/simple-and-beautiful-powerful-react-player-26i2
export default JolVideoComponent;
