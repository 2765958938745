import React from "react";
import CourseDetailHero from "./components/hero";
import { Tabs } from "antd";
import { BASE_URL } from "../../api/base-url";
import { Header, Footer } from "../../components";
import { InDetail, TableOfContent } from "./components";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./courseDetail.scss";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../api/intercepter";

const CourseDetail = () => {
  // const location = useLocation();
  const { courseId } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  // const { id, checkId } = location?.state;

  const getCourseDetailAsPublic = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get(
        `${BASE_URL}/api/v1/course/public/tree/${courseId}`
      );
      setData(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getCourseDetailAsClient = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get(
        `${BASE_URL}/api/v1/course/client/tree/${courseId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setData(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      getCourseDetailAsClient();
    } else {
      getCourseDetailAsPublic();
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const items = [
    {
      key: "1",
      label: `Kurs`,
      children: <TableOfContent data={data} />,
    },
    {
      key: "2",
      label: `Batafsil`,
      children: <InDetail data={data} />,
    },
  ];

  return (
    <div className="course-detail-wrapper">
      <Header />
      <CourseDetailHero />
      <div className="courses-tabs">
        <div>
          {isLoading ? (
            <div
              style={{
                height: "80vh",
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "10px",
                fontSize: "18px",
              }}
            >
              Yuklanmoqda..
            </div>
          ) : (
            <Tabs
              className="tabs"
              defaultActiveKey="1"
              type="card"
              items={items}
              size={"large"}
              tabBarGutter={10}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CourseDetail;
