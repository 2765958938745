import React, {useEffect, useState} from 'react';
import './AdminClientCourseDetail.scss';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {BASE_URL} from "../../../../../api/base-url";
import {getDateFormat, getDateInSimpleFormat} from "../../../../../utils/Utils";
import {Select} from "antd";

const AdminClientCourseDetail = () => {
    const {clientId, courseId} = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')

    const [clientData, setClientData] = useState({});
    const [courseData, setCourseData] = useState({});
    const [clientCourseData, setClientCourseData] = useState({});
    const [clientCourseSectionData, setClientCourseSectionData] = useState([]);

    // Get client
    const getClient = async () => {
        const {data} = await axios.get(
            `${BASE_URL}/api/v1/client/${clientId}`,
            {headers: {Authorization: `Bearer ${token}`}}
        );
        // console.log(data);
        setClientData(data?.data);
    };
    //
    const getCourse = async () => {
        const {data} = await axios.get(
            `${BASE_URL}/api/v1/course/public/${courseId}/simple`,
            {headers: {Authorization: `Bearer ${token}`}}
        );
        // console.log(data?.data);
        setCourseData(data?.data);
    };

    const getClientCourse = async () => {
        const {data} = await axios.get(
            `${BASE_URL}/api/v1/client-curse/client/${clientId}/course/${courseId}`,
            {headers: {Authorization: `Bearer ${token}`}}
        );
        // console.log(data?.data.status);
        setClientCourseData(data?.data);
    };

    const getClientCourseSection = async () => {
        const {data} = await axios.get(
            `${BASE_URL}/api/v1/course-section/ccl/${clientId}/${courseId}`,
            {headers: {Authorization: `Bearer ${token}`}}
        );
        console.log(data);
        setClientCourseSectionData(data?.data);
    };

    function jsonParse(value) {
        // console.log(JSON.parse(value));
        return value ? JSON.parse(value) : [];
    }

    function getCourseLessonBgColorClassName(status) {
        if (status === 'PUBLISHED') {
            return 'bg-success-first'
        } else if (status === 'PUBLISHED') {
            return 'bg-grey'
        } else {
            return ''
        }
    }

    function getClientCourseLessonBgColorClassName(status) {
        if (status === 'FINISH') {
            return 'bg-success-second'
        } else if (status === 'START') {
            return 'bg-red'
        } else {
            return ''
        }
    }

    async function updateClientCourseStatus() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Update ClientCourseStatus ?")) {
            const {data} = await axios.get(
                `${BASE_URL}/api/v1/client-curse/admin/change/${clientCourseData.id}/status/${clientCourseData.status}`,
                {headers: {Authorization: `Bearer ${token}`}}
            );
            if (data?.code !== 400) {
                alert("Success!")
            } else {
                alert(data?.message);
            }
        }

    }

    async function updateClientCoursePurchaseType() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Update ClientCourseStatus ?")) {
            const {data} = await axios.get(
                `${BASE_URL}/api/v1/client-curse/admin/change/${clientCourseData.id}/type/${clientCourseData.purchaseType}`,
                {headers: {Authorization: `Bearer ${token}`}}
            );
            if (data?.code !== 400) {
                alert("Success!")
            } else {
                alert(data?.message);
            }
        }
    }

    useEffect(() => {
        getClient();
        getCourse();
        getClientCourse();
        getClientCourseSection();
    }, []);

    useEffect(() => {

    }, []);


    return (
        <div className='admin-client-course-detail-wrapper'>
            <div className=''>
                <h3>Profile</h3>
                {clientData ? <div className="row">
                    <div className="col-6">
                        <p><b>ID</b>: {clientData.id}</p>
                        <p><b>PhoneNumber</b>: {clientData.phoneNumber}</p>
                    </div>
                    <div className="col-6">
                        <p><b>Name</b>: {clientData.name}</p>
                        <p><b>Surname</b>: {clientData.surname}</p>
                    </div>
                </div> : ''}
            </div>
            <hr/>
            <div>
                <h3>Course</h3>
                {courseData ? <div>
                    <p><b>Name</b>: {courseData.name}</p>
                    <p><b>Id</b>: {courseData.id}</p>
                </div> : ''}
            </div>
            <hr/>
            <div>
                <h3>Client Course</h3>
                {clientCourseData ?
                    <table className="admin-client-course-detail-table  table table-bordered table-striped">
                        <tr key={1}>
                            <th>ClientCourseId</th>
                            <td>{clientCourseData.id}</td>
                        </tr>
                        <tr key={2}>
                            <th>CreatedDate</th>
                            <td>{getDateFormat(clientCourseData.createdDate)}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>
                                <Select
                                    id='clientCourseDataStatus'
                                    value={clientCourseData.status}
                                    onChange={(e) => setClientCourseData({...clientCourseData, status: e})}
                                    style={{
                                        width: 180,
                                    }}
                                    options={[
                                        {
                                            value: 'CREATED',
                                            label: 'CREATED',
                                        },
                                        {
                                            value: 'JOINED',
                                            label: 'JOINED',
                                        },
                                    ]}
                                />
                                <span className="p-2"></span>
                                <i onClick={() => updateClientCourseStatus(clientCourseData.status)}
                                   className="fa-solid fa-pen-to-square on-click-hand"></i>
                            </td>
                        </tr>
                        <tr>
                            <th>CourseStartedDate</th>
                            <td>{getDateInSimpleFormat(clientCourseData.courseStartedDate)}</td>
                        </tr>
                        <tr>
                            <th>CourseEndDate</th>
                            <td>{getDateFormat(clientCourseData.courseEndDate)}</td>
                        </tr>
                        <tr>
                            <th>CourseFinishedDate</th>
                            <td>{getDateFormat(clientCourseData.courseFinishedDate)}</td>
                        </tr>
                        <tr>
                            <th>CourseFinished</th>
                            <td>{clientCourseData.courseFinished}</td>
                        </tr>
                        <tr>
                            <th>PurchaseType</th>
                            <td>
                                <Select
                                    id='purchaseTypeSelectId'
                                    onChange={(e) => setClientCourseData({...clientCourseData, purchaseType: e})}
                                    value={clientCourseData.purchaseType}
                                    style={{
                                        width: 180,
                                    }}
                                    options={[
                                        {
                                            value: 'FREE',
                                            label: 'FREE',
                                        },
                                        {
                                            value: 'BOUGHT',
                                            label: 'BOUGHT',
                                        },
                                        {
                                            value: 'BOUGHT_IN_DISCOUNT',
                                            label: 'BOUGHT_IN_DISCOUNT',
                                        },
                                    ]}
                                />
                                <span className="p-2"></span>
                                <span className="p-2"></span>
                                <i onClick={() => updateClientCoursePurchaseType()}
                                   className="fa-solid fa-pen-to-square on-click-hand"></i>
                            </td>
                        </tr>
                        <tr>
                            <th>ProgressStatus</th>
                            <td>{clientCourseData.progressStatus}</td>
                        </tr>
                        <tr>
                            <th>FinishedPercentage</th>
                            <td>{clientCourseData.finishedPercentage}</td>
                        </tr>
                        <tr>
                            <th>TransactionId</th>
                            <td>---------</td>
                        </tr>
                    </table>
                    : ''}

            </div>
            <hr/>
            <div>
                <h3>Client Course Tree</h3>
                <ul className="accd-course-tree">
                    {clientCourseSectionData?.map((item, index) => {
                        return (<li key={item?.id}>
                                <p className={`tree-course-section ${getCourseLessonBgColorClassName(item.status)}`}>{item.name} -> {getDateFormat(item.createdDate)}</p>
                                {jsonParse(item?.clientCourseLessonDetail).map((clItem, index) => {
                                    const jsonObj = jsonParse(clItem);
                                    return (
                                        <ul className='course-lesson-ul-wrapper' key={jsonObj.cclId}>
                                            <div className='course-lesson-wrapper'>
                                                <div
                                                    className={` course-lesson-block ${getCourseLessonBgColorClassName(jsonObj.clStatus)}`}>
                                                    <div><b>{jsonObj.clOrderNumber}</b> - {jsonObj.clName} </div>
                                                    <div className='course-lesson-block-bottom'>{jsonObj.clType}</div>
                                                </div>
                                                <div
                                                    className={`course-lesson-block  ${getClientCourseLessonBgColorClassName(jsonObj.cclStatus)} `}>
                                                    <div><b>Start</b>: {getDateFormat(jsonObj.cclStartDate)}</div>
                                                    <div><b>End</b>: {getDateFormat(jsonObj.cclFinishDate)}</div>
                                                </div>
                                            </div>
                                        </ul>)
                                })}
                            </li>
                        )
                    })}
                </ul>

            </div>

        </div>
    );
};

export default AdminClientCourseDetail;
