import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../../../api/base-url";
import "./summary.scss";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useState } from "react";
import {
  currencyFormat,
  formatDate,
  secondsToTime,
} from "../../../../utils/Utils";

const Summary = ({ data }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [clientCourseDetail, setCourseClientDetail] = useState(
    data?.clientCourse
  );

  const notify = () => toast("Tasdiqlandi. Vachach!!!");
  useEffect(() => {
    setCourseClientDetail(data?.clientCourse);
  }, [data]);

  const purchaseCourseAndGoToCourse = async (id) => {
    try {
      const dataSimple = await axios.get(
        `${BASE_URL}/api/v1/client-curse/purchase/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setCourseClientDetail(dataSimple.data);

      notify();
      setTimeout(() => {
        navigate(`/course/${id}/video`, {
          state: { courseDetail: data, course: data, id: id },
        });
      }, 2000);
    } catch (err) {
      navigate("/login");
    }
  };

  const purchaseAndGoToPayment = async (id) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/client-curse/purchase/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCourseClientDetail(data.data); // TODO change status
      notify();
      setTimeout(() => {
        navigate("/payment", { state: { id: id } }); // to go payment
      }, 2000);
    } catch (err) {
      navigate("/login");
    }
  };

  function getButton() {
    const token = localStorage.getItem("token");
    // if (!token) {
    //   navigate('/login');
    //   return;
    // }
    if (clientCourseDetail?.status === "JOINED") {
      // client already bought the course
      return (
        <button
          onClick={() =>
            navigate(`/course/${data.id}/video`, {
              state: { courseDetail: data, course: data, id: data.id },
            })
          }
          className="btn btn-info text-light"
        >
          Kursni davom ettirish
        </button>
      );
    } else if (clientCourseDetail?.status === "CREATED" || data?.price !== 0) {
      // client should buy course
      return (
        <button
          onClick={() => purchaseAndGoToPayment(data?.id)}
          className="btn btn-info text-light"
        >
          Sotib Olish
        </button>
      );
    } else if (data?.price === 0) {
      // free course
      return (
        <button
          onClick={() => {
            purchaseCourseAndGoToCourse(data?.id);
          }}
          className="btn btn-info text-light"
        >
          Kursni Boshlash
        </button>
      );
    }
  }

  return (
    <div className="summary-wrapper w-100">
      {clientCourseDetail?.status === "JOINED" && (
        <div className="languages-wrapper d-flex align-items-end  bg-light mb-4 border-radius-5">
          <ul className="w-100 m-0">
            <li style={{ listStyle: "none" }}>
              <div className="language d-flex align-items-start">
                <div>
                  <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                    Kurs boshlangan sana:
                  </p>
                  <h6>{formatDate(clientCourseDetail.createdDate)}</h6>
                </div>
              </div>
            </li>
            <li style={{ listStyle: "none" }}>
              <div className="language d-flex align-items-start">
                <div>
                  <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                    Kursni tugatish foizi:
                  </p>
                  <h6>{clientCourseDetail.finishedPercentage} %</h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
      <div className="summary-title summary-block-title d-flex align-items-center justify-content-between">
        <h5 className="summary-block-title-text">Kurs Haqida ma'lumotlar</h5>
        <div className="summary-icons d-flex gap-4">
          <i
            style={{ fontSize: 18, cursor: "pointer" }}
            className="fa-solid fa-share-nodes"
          ></i>
          <i
            style={{ fontSize: 18, cursor: "pointer" }}
            className="fa-regular fa-bookmark"
          ></i>
        </div>
      </div>
      <div className="languages-wrapper d-flex align-items-end  bg-light">
        <ul className="w-100">
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>Kurs nomi</p>
                <h6>{data?.name}</h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                  Videolar soni
                </p>
                <h6>{data?.videoCount}</h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                  Video darslar davomiligi
                </p>
                <h6>{secondsToTime(data?.videoDuration)}</h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                  Amaliy mashqlar soni
                </p>
                <h6>{data?.practiceCount}</h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>Sertifikat</p>
                <h6>
                  {data?.hasCertificate
                    ? "Sertifikat beriladi."
                    : "Sertifikat berilmaydi"}
                </h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                  Qo'llab quvvatlash bo'limi
                </p>
                <h6>{data?.hasCertificate ? "Bor" : "Yo'q"}</h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>Kurs narxi</p>
                <h6>
                  {data?.price ? `${currencyFormat(data?.price)} UZS` : "Bepul"}
                </h6>
              </div>
            </div>
          </li>
          <li style={{ listStyle: "none" }}>
            <div className="language d-flex align-items-start">
              <div>
                <p style={{ color: "#b3b7be", marginBottom: 3 }}>
                  Kurs muddati
                </p>
                <h6>{data?.availableDay} kun</h6>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="study-btn-wrap bg-light">
        {getButton()}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Summary;
