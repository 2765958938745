import React, { useContext, useState } from "react";
import axios from "axios";
import { Input } from "antd";
import { BASE_URL } from "../../../../../api/base-url";
import { useNavigate } from "react-router-dom";
import "./public-page.scss";
import AuthContext from "../../../../../context/auth-provider";
import { Header } from "../../../../../components";


const PublicPageDashboard = () => {
  const { register, login } = useContext(AuthContext);
  const  token  = localStorage.getItem('token')
  const navigate = useNavigate();
  const userName = JSON.parse(localStorage.getItem("fullName"));

  const [isChanged, setIsChanges] = useState(false)

  const [image, setImage] = useState({})

  const [inputValue, setInputValue] = useState({
    name: userName?.name || "",
    surname: userName?.surname || "",
    photo: userName?.photo || '',
  });

  const onImageChange = (e) => {
    setImage({
      url: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    });
    setIsChanges(true)
  }

  const updateClientDetails = async () => {
    if (!isChanged) {
      return
    }
    const { name, surname } = inputValue;
    const image = await sendFile();

    const { data } = await axios.put(
      `${BASE_URL}/api/v1/client/detail`,
      {
        name: name,
        surname: surname,
        photoId: image.id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    );
    if(!data.isError){
      const fullName = {
        name: name,
        surname: surname,
        photo: {id: image.id, url: image.webContentLink}
      };
      localStorage.setItem("fullName", JSON.stringify(fullName));
      navigate("/");
    }else{
      alert(data.data ? data.data : data.message)
    }
  };

  const handleResetChanges = () => {
    setImage({})
    setInputValue({
      name: userName?.name || "",
      surname: userName?.surname || "",
      photo: userName?.photo || '',
    })
    setIsChanges(false)
  }
  async function sendFile() {
    let formData = new FormData();
    formData.append('file', image?.data);
    const { data } = await axios.post(
      `${BASE_URL}/api/v1/attach/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return data.data
  }
  return (
    <>
    <div className="d-flex user-page">
      <div className="public-page-wrapper w-100">
        <div className="d-flex align-items-center gap-5">
          <div className="user-avatar">
            {image.url ? <img
                  src={image.url}
              className="client-image"
              alt=""
            />  : inputValue.photo ? <img
              src={inputValue.photo.url}
              className="client-image"
              alt=""
            /> : <img
              src='https://www.w3schools.com/howto/img_avatar.png'
              className="client-image"
              alt=""
            />}
        
          <div>
            <label className="file-input-label" htmlFor="file-input">Change image</label>
            <input type="file" id="file-input" onChange={onImageChange} className="file-input"/>
          </div>
          </div>
          <div className="client-details">
            <div>
              <label htmlFor="name">Name</label>
              <Input
                onChange={(e) =>
                {
                  setInputValue({ ...inputValue, name: e.target.value })
                  setIsChanges(true)
                }
                }
                value={inputValue.name}
                id="name"
              />
            </div>
            <div>
              <label htmlFor="surname">Surname</label>
              <Input
                onChange={(e) =>
                {
                  setInputValue({ ...inputValue, surname: e.target.value })
                  setIsChanges(true)
                }
                }
                value={inputValue.surname}
                id="surname"
              />
            </div>
          </div>
        </div>

        <div className="mt-5 d-flex justify-content-end w-50">
          <button onClick={updateClientDetails} className="btn btn-warning me-3">
            Update Detail
          </button>
          <button className="btn btn-light" onClick={handleResetChanges}>
            Reset Changes
          </button>
        </div>
      </div>
    </div></>
    
  );
};

export default PublicPageDashboard;
