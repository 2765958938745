import React, {useEffect, useRef, useState} from 'react';
import {Divider, Input, Modal, Select, Space, Spin, Table} from 'antd';
import './support-video.scss';
import axios from 'axios';

const SupportVideo = () => {
    const token = localStorage.getItem('token')
    const [isLoad, setIsLoad] = useState(false);
    const [modal, setModal] = useState({
        createModal: false,
    });
    const [id, setId] = useState(null);

    const textRef = useRef(null);

    const copyText = () => {
        const text = textRef.current.textContent;
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert('Copied video id');
            })
            .catch((err) => {
                console.error('Nusxalashda xatolik yuz berdi:', err);
            });
    };

    // UPLOAD VIDEO
    const [file, setFile] = useState(null);
    const [videoData, setVideoData] = useState([]);

    // GET VIDEOS
    const getVideos = async () => {
        const {data} = await axios.post(
            `https://api.videohab.uz/attach/filter/mentor`,
            {},
            {headers: {Authorization: `Bearer ${token}`}}
        );
        setVideoData(data);
    };

    useEffect(() => {
        getVideos();
    }, []);

    const showModal = (id) => {
        setModal({...modal, createModal: true});
        setId(id);
    };

    // ATTACH VIDEO
    const handleChange = (e) => {
        let file = e.target.files[0];
        setFile(file);
    };

    const handleOk = async () => {
        let formData = new FormData();
        formData.append('file', file);
        setIsLoad(true);
        const {data} = await axios.post(
            `https://api.videohab.uz/attach/save`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        setIsLoad(false);
        setModal({...modal, createModal: false});
    };
    // ATTACH VIDEO

    const handleCancel = () => {
        setModal({...modal, createModal: false});
    };

    const columns = [
        {
            title: 'Attach',
            dataIndex: 'id',
            render: (_, record) => (
                <video
                    src={`https://api.videohab.uz/attach/stream/${record.id}`}
                    width='220px'
                    height='100px'
                    controls
                    style={{cursor: 'pointer'}}
                    preload='none'
                ></video>
            ),
            width: 30,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (_, record) => (
                <>
                    <p>{record?.name}</p>
                </>
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            render: (_, record) =>
                <p>{record.createdDate}</p>
        },
        {
            title: 'Copy Id',
            dataIndex: 'id',
            render: (_, record) => (
                <>
                    <button onClick={copyText} className='d-flex gap-2 flex-row copy-id'>
                        Copy Id
                    </button>
                    <p
                        style={{
                            position: 'absolute',
                            color: 'transparent',
                            userSelect: 'none',
                        }}
                        ref={textRef}
                    >
                        {record?.id}
                    </p>
                </>
            ),
        },
    ];

    if (isLoad) {
        return (
            <Space
                style={{height: '80vh', display: 'grid', placeItems: 'center'}}
                size='middle'
            >
                <Spin size='large'/>
            </Space>
        );
    } else {
        return (
            <div className='client-wrapper'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='inputs d-flex align-items-center gap-4'>
                        <div className='d-flex flex-column'>
                            <label className='mb-2' htmlFor='name'>
                                Video Name
                            </label>
                            <Input id='name' style={{width: 'max-content'}}/>
                        </div>
                        <div className='d-flex flex-column'>
                            <label className='mb-2' htmlFor='status'>
                                Status
                            </label>
                            <Select
                                id='status'
                                defaultValue='lucy'
                                style={{
                                    width: 180,
                                }}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'jack',
                                        label: 'Jack',
                                    },
                                    {
                                        value: 'lucy',
                                        label: 'Lucy',
                                    },
                                    {
                                        value: 'Yiminghe',
                                        label: 'yiminghe',
                                    },
                                ]}
                            />
                        </div>
                        {/* <p onClick={copyText} ref={textRef}>
              Bu matni nusxalab olsangiz bo'ladi
            </p> */}
                        <button className='btn btn-primary' style={{marginTop: 30}}>
                            <i className='fa-solid fa-magnifying-glass'></i>
                        </button>
                    </div>
                    <button onClick={showModal} className='btn btn-success'>
                        Create Video
                    </button>
                    <Modal
                        title='Create Video'
                        open={modal.createModal}
                        okText='Create Video'
                        okButtonProps={{style: {backgroundColor: '#198753'}}}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <div className='d-flex mt-3 gap-2 align-items-center'>
                            <input
                                type='file'
                                style={{width: '100%'}}
                                name='file'
                                onChange={handleChange}
                            />
                        </div>
                    </Modal>
                </div>
                <div className='clients'>
                    <Divider/>
                    <Table rowKey='id' columns={columns} dataSource={videoData}/>
                </div>
            </div>
        );
    }
};

export default SupportVideo;
